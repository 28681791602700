import {
  TableIcon,
  ViewGridIcon,
  ViewListIcon,
} from '@heroicons/react/outline';
import { classNames } from '../../utils/functions';
import Pagination from '../../components/layout/pagination';
import PerPage from '../../components/layout/perPage';
import { useSearchParams } from 'react-router-dom';
import { useProduct } from './product.hook';
import { useApp } from '../../app.hook';

interface CardTypeProps {
  qtdeReg: number;
  // setCardType: (type: 'card' | 'list' | 'table') => void;
  perPage?: number;
  curPage?: number;
  // cardType: 'card' | 'list' | 'table';
  handlerPage?: (page: number) => void;
}

const _classNames = (active: boolean) =>
  classNames(
    active && 'text-gray-500 shadow border-[1px] border-gray-200 border-solid',
    'hover:text-gray-500 hover:bg-gray-50 rounded-md p-1 mx-1'
  );

export default function CardType(props: CardTypeProps) {
  const { orderType, setOrderType, cardType, setCardType } = useProduct();
  const { isXs } = useApp();
  // const [pages, setPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   if (props.perPage) {
  //     setPages(Math.ceil(props.qtdeReg / props.perPage));
  //   }
  // }, [props.perPage, props.qtdeReg]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const params = searchParams;
    params.set('skip', String(selectedItem.selected));
    setSearchParams(params);
  };

  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center py-1 md:px-5 bg-gray-50 text-gray-400">
      {/* <ReactTooltip className="opacity-30" /> */}
      <div className="flex flex-col p-2 md:p-0 md:flex-row space-y-2 md:space-x-3 md:items-center">
        <span className="text-xs">Qtde Itens: {props.qtdeReg}</span>

        {/* {props.perPage ? (
          <span className="text-xs">
            Pag: {(props.curPage || 0) + 1}/{pages}
          </span>
        ) : null} */}

        {props.perPage ? (
          <Pagination
            curPage={props.curPage || 0}
            qtdeReg={props.qtdeReg}
            perPage={Number(searchParams.get('take'))}
            handlePageClick={handlePageClick}
          />
        ) : (
          <Pagination
            curPage={props.curPage || 0}
            qtdeReg={props.qtdeReg}
            perPage={100}
            handlePageClick={handlePageClick}
          />
        )}

        {props.perPage ? (
          <PerPage storageLocalName="page-take" take={props.perPage} />
        ) : (
          <PerPage storageLocalName="page-take" take={100} />
        )}
      </div>

      <span className="p-1 rounded-md hidden md:inline">
        <button
          className={_classNames(orderType === 'alfa')}
          type="button"
          onClick={() => setOrderType && setOrderType('alfa')}
          title="Ordem Alfabética"
        >
          <img
            className="h-6 w-6 opacity-50"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA8UlEQVRIid2UMQ4BURCGv2XPQCki0W5F4Q4aBRINJ6BT2mtxABfYqLiAREuDXcVO8Wze27fvhYI/mWJm/5l/Znby4J+xADJgbuFlYs7YSeL2GwIN4C6JD6DpK1AzxCdACCRAHRi7dmjDHkiBHvAU3wTnFbWkeKZYCrR9BHQrmgFBIRYAU5cuy5BIR5H4kfgHA9/7TKvC64o+hq8LhJpY2T6LP98KlwmOrsWrYEU+0QXoGjjeVzQkf4duwKCE5yXQB67kz8TIwnUW6ABnSVoaOGvenxLVNjaBU0myiljzPa4ygam4bg3qJNbOfRFTsfPfxAuUolP9esbLDwAAAABJRU5ErkJggg=="
          />
        </button>
        <button
          className={_classNames(orderType === 'num')}
          type="button"
          onClick={() => setOrderType && setOrderType('num')}
          title="Ordem Numérica"
        >
          <img
            className="h-6 w-6 opacity-60"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABNklEQVRIie3Wu0rEUBDG8Z+XysZCDF6wstM3UNBOEK0EGxEfQXwU8RV8DMFiG1G0UUGwUbTQykIQrxiLnEB2ybrJ6qbZ/cMQMjkz30xykgk9upUh7OEp2FzJ+GMclRWdxDXijC2UzJHGNaU/xzeFEWziq6TgnxgPBp861PFgju+hpEhb5N3qSug+4bxnnOUGA3iroJZ/oa33uBJ6wimLqOEZj9jHRKeLmcErvnGIO8lGKTNtWm6uPHYko3A3nE9nEg13UriRrZDkFn1N1tRwiihHOArXSs3nFbxLptTSL+tOgtBFEEqFo+CLw5pCrEm+WB9Yb7E2K5Ae4wZf1DQ6w4akyxcsFyx0FOfq/1xiXGGsSIJVyY6OcY+DjM22iI1wqb7jQp3Cdk7Vqc0XiI9wpn6z9ehCfgCYCVwSivT9bQAAAABJRU5ErkJggg=="
          />
        </button>
        <button
          className={_classNames(cardType === 'card')}
          type="button"
          onClick={() => setCardType('card')}
          title="Visão Cartão"
        >
          <ViewGridIcon className="h-6 w-6" />
        </button>
        <button
          className={_classNames(cardType === 'list')}
          type="button"
          onClick={() => setCardType('list')}
          title="Visão Lista"
        >
          <ViewListIcon className="h-6 w-6" />
        </button>
        <button
          className={_classNames(cardType === 'table')}
          type="button"
          onClick={() => setCardType('table')}
          title="Visão Tabela"
        >
          <TableIcon className="h-6 w-6" />
        </button>
      </span>
    </div>
  );
}
