/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// import { toast } from 'react-toastify';
import { useApp } from '../../app.hook';
import {
  Marca,
  Modelo,
  VeiculoTipos,
  Search,
  VeiculoTiposFilter,
} from '../../app.models';
import {
  productSearchAll,
  productSearchCateg,
  productSearchFor,
  productSearchPorModelo,
} from '../../app.service';
import { LOCAL_STORAGE_PREFIX } from '../../app.settings';
import { arrayGroupBy } from '../../utils/functions';
import { EnumOrigens } from '../../utils/origens';

interface IContext {
  clear: () => void;
  clearTipo: () => void;
  clearModelo: () => void;
  marcas: Marca[];
  marcasFilterTipo: VeiculoTiposFilter[];
  selectedMarca: Marca | undefined;
  selectedTabMarca: number | undefined;
  setSelectedTabMarca: (tipo: number | undefined) => void;
  selectedTabMarcaPosition: number | undefined;
  setSelectedTabMarcaPosition: (position: number) => void;
  selectedLastMarca: Marca | undefined;
  setSelectedMarca: (data: Marca | undefined) => void;
  filterTipo: VeiculoTipos[];
  filterModelo: (veiculoTipoId: number) => Modelo[];
  filterModeloMarca: (marcaId: number) => Modelo[];
  selectedTipo: VeiculoTipos | undefined;
  setSelectedTipo: (tipo: VeiculoTipos) => void;
  selectedTabTipo: number | undefined;
  setSelectedTabTipo: (tipo: number | undefined) => void;
  selectedTabTipoPosition: number | undefined;
  setSelectedTabTipoPosition: (position: number | undefined) => void;
  selectedModelo: Modelo | undefined;
  selectedLastModelo: Modelo | undefined;
  setSelectedModelo: (modelo: Modelo | undefined) => void;

  // case filterType igual a promocao
  searchModelo: string | undefined;
  setSearchModelo: (modelo: string | undefined) => void;
  selectedSearchedModelo: number | undefined;
  setSelectedSearchedModelo: (modelo: number | undefined) => void;

  listProdutos: Search;
  filterEixos: any[];
  loading: boolean;
  filterType: 'text' | 'marcamodelo' | 'categorias' | 'promocoes' | 'todos';
  setFilterType: (
    filter: 'text' | 'marcamodelo' | 'categorias' | 'promocoes' | 'todos'
  ) => void;
  cardType: 'card' | 'list' | 'table';
  setCardType: (type: 'card' | 'list' | 'table') => void;
  orderType: 'alfa' | 'num';
  setOrderType: (type: 'alfa' | 'num') => void;
  // texto filtro
  // searchText: (text: string) => void;
  setSearchedText: (text: string) => void;
  searchedText: string;
  take: number;
  setTake: (take: number) => void;
  skip: number;
  setSkip: (skip: number) => void;
  setSelectedCategoria: (categId: number | undefined | null) => void;
  selectedCategoria: number | undefined | null;
  selectedLastCategoria: number | undefined | null;
  setFilterEixos: (eixos: any[]) => void;
  filterText: string;
  setFilterText: (filter: string) => void;
  tagsText: string;
  setTagsText: (filter: string) => void;
  setOrigem: (origem: string | undefined) => void;
  getOrigem: () => string | undefined;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const ProductProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const { modelos, veiculoTipos, marcas, marcasComTipo, tabelaUpdateCount } =
    useApp();

  const [filterType, setFilterType] = useState<
    'text' | 'marcamodelo' | 'categorias' | 'promocoes' | 'todos'
  >('todos');

  // card type ***************************************************
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, reload] = useState(0);
  const cardTypeLocalstorage = LOCAL_STORAGE_PREFIX + 'card-type';
  const orderTypeLocalstorage = LOCAL_STORAGE_PREFIX + 'order-type';

  const cardType: 'card' | 'list' | 'table' =
    (localStorage.getItem(cardTypeLocalstorage) as 'card' | 'list' | 'table') ||
    'card';
  const setCardType = (type: 'card' | 'list' | 'table') => {
    localStorage.setItem(cardTypeLocalstorage, type);
    // força renderização
    reload(_ + 1);
  };

  const orderType: 'alfa' | 'num' =
    (localStorage.getItem(orderTypeLocalstorage) as 'alfa' | 'num') || 'alfa';
  const setOrderType = (type: 'alfa' | 'num') => {
    localStorage.setItem(orderTypeLocalstorage, type);
    // força renderização
    reload(_ + 1);
  };

  const [selectedMarca, setSelectedMarca] = useState<Marca>();
  const [selectedTabMarca, setSelectedTabMarca] = useState<number>();
  const [selectedLastMarca, setSelectedLastMarca] = useState<Marca>();
  const [selectedModelo, setSelectedModelo] = useState<Modelo>();
  const [selectedLastModelo, setSelectedLastModelo] = useState<Modelo>();
  const [selectedTipo, setSelectedTipo] = useState<VeiculoTipos>();
  const [selectedTabTipo, setSelectedTabTipo] = useState<number>();
  const [selectedTabTipoPosition, setSelectedTabTipoPosition] =
    useState<number>();
  const [selectedTabMarcaPosition, setSelectedTabMarcaPosition] =
    useState<number>();
  const [filterTipo, setFilterTipo] = useState<VeiculoTipos[]>([]);
  const [marcasFilterTipo, setMarcasFilterTipo] = useState<
    VeiculoTiposFilter[]
  >([]);
  const [listProdutos, setListProdutos] = useState<Search>({} as Search);

  const [searchModelo, setSearchModelo] = useState<string>();
  const [selectedSearchedModelo, setSelectedSearchedModelo] =
    useState<number>();

  const [filterEixos, setFilterEixos] = useState<any[]>([]);

  const [filterText, setFilterText] = useState<string>('');

  const [tagsText, setTagsText] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const clear = () => {
    setSelectedLastMarca(selectedMarca);
    setSelectedMarca(undefined);
    setSelectedTipo(undefined);
    setSelectedTabTipo(0);
    setSelectedTabTipoPosition(0);
    setSelectedModelo(undefined);
    setListProdutos({} as Search);
    setFilterType('marcamodelo');
  };

  const clearTipo = () => {
    setSelectedTipo(undefined);
  };

  const clearModelo = () => {
    setSelectedLastModelo(selectedModelo);
    // setFilterType('marcamodelo');
    setSelectedModelo(undefined);
  };

  const [take, setTake] = useState<number>(100);
  const [skip, setSkip] = useState<number>(0);

  const _origem = useRef<string | undefined>();
  const setOrigem = (origem: string | undefined) => {
    _origem.current = origem;
  };

  const getOrigem = () => {
    return _origem.current;
  };

  //filtro marca por tipo
  useEffect(() => {
    const group = arrayGroupBy(marcasComTipo, 'veiculoTipoId');
    const entries = Object.entries(group);
    const array: VeiculoTiposFilter[] = [];
    entries.map((entry) => {
      // console.log(entry);
      array.push({
        id: parseInt(entry[0]),
        tipoVeiculoDesc:
          veiculoTipos.find((e) => e.id === parseInt(entry[0]))
            ?.tipoVeiculoDesc || 'N/D',
        marcas: entry[1].map((e: any) => marcas.find((v) => v.id === e.id)),
      });
    });
    // console.log({ marcasFilterTipo: array });
    setMarcasFilterTipo(array);
  }, [marcas, marcasComTipo, veiculoTipos]);

  // filtro tipo
  useEffect(() => {
    const _modelos = modelos.filter(
      (mod: any) => mod.marcaId === selectedMarca?.id
    );

    const tpIds: number[] = [];
    _modelos.forEach((el: { veiculoTipoId: number }) => {
      if (!tpIds.includes(el.veiculoTipoId)) tpIds.push(el.veiculoTipoId);
    });

    const state: VeiculoTipos[] = [];
    tpIds.map((el) => {
      const tp = veiculoTipos.find((t) => t.id === el);
      if (tp) state.push(tp);
    });
    setFilterTipo(state);
  }, [modelos, selectedMarca?.id, veiculoTipos]);

  // filtro modelo
  const filterModelo = (veiculoTipoId: number): Modelo[] =>
    modelos.filter(
      (mod: Modelo) =>
        mod.marcaId === selectedMarca?.id && mod.veiculoTipoId === veiculoTipoId
    );

  // filtro modelo por marca
  const filterModeloMarca = (marcaId: number): Modelo[] =>
    modelos.filter((mod: Modelo) => mod.marcaId === marcaId);

  useEffect(() => {
    if (filterType === 'marcamodelo') {
      setListProdutos({} as Search);
      // makeFilters(0);
      setLoading(true);

      let origem = _origem.current;
      if (!origem) origem = EnumOrigens.MARCA_MODELO;

      // if (selectedMarca?.id && selectedModelo?.id) {
      selectedModelo?.id &&
        filterType === 'marcamodelo' &&
        productSearchPorModelo(
          selectedModelo.id,
          take,
          skip,
          filterText,
          tagsText,
          origem
        )
          .then((data) => {
            setListProdutos(data);
          })
          .finally(() => setLoading(false));
      // makeFilters(selectedModelo?.id);
    }

    if (filterType === 'todos') {
      setLoading(true);
      let newSkip = skip;
      let origem = _origem.current;
      if (!origem) origem = EnumOrigens.PRODUTO;
      if (Number.isNaN(newSkip)) {
        newSkip = 0;
      }

      productSearchAll(take, newSkip, filterText, tagsText).then((data) => {
        setListProdutos(data);
        setLoading(false);
      });
    }
  }, [
    filterText,
    filterType,
    selectedMarca,
    selectedModelo,
    skip,
    tagsText,
    take,
  ]);

  // busca de texto
  const [searchedText, _setSearchedText] = useState<string>('');

  const setSearchedText = (text: string) => {
    if (searchedText === text) _setSearchedText('');
    _setSearchedText(text);
  };

  const execPesquisaText = useCallback(
    (txt: string | undefined) => {
      if (!txt || !filterType) {
        return;
      }

      if (txt && filterType === 'text') {
        setLoading(true);
        let origem = _origem.current;
        if (!origem) origem = EnumOrigens.SEARCH_FOR;

        // txt.replace(/[^0-9a-zA-Z\:\-\.\&]+/gi, ' '),
        productSearchFor(txt, take, skip, filterText, tagsText, origem)
          .then((data) => {
            // console.log({ data });
            setListProdutos(data);
            setOrigem(undefined);
          })
          .finally(() => setLoading(false));
      }
    },
    [filterText, filterType, skip, tagsText, take]
  );
  // atualizar quando mudar a tabela de preços
  useEffect(() => {
    if (searchedText) {
      execPesquisaText(searchedText);
    }
  }, [
    filterText,
    filterType,
    searchedText,
    skip,
    tagsText,
    take,
    tabelaUpdateCount,
    execPesquisaText,
  ]);

  // busca por modelo somente ########################
  useEffect(() => {
    if (filterType === 'promocoes' && searchModelo && selectedSearchedModelo) {
      setLoading(true);
      let origem = _origem.current;
      if (!origem) origem = EnumOrigens.MARCA_MODELO;
      // makeFilters(selectedSearchedModelo);
      productSearchPorModelo(
        selectedSearchedModelo,
        take,
        skip,
        filterText,
        tagsText,
        origem
      )
        .then((data) => {
          setListProdutos(data);
          // console.log({ data });
          // if (data.produtos.length > 0) {
          //   setListProdutos(data);
          // } else {
          //   setListProdutos({} as Search);
          // }
          setOrigem(undefined);
          // setSelectedSearchedModelo(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // setSelectedSearchedModelo(undefined);
      setListProdutos({} as Search);
    }
  }, [
    filterText,
    filterType,
    searchModelo,
    selectedSearchedModelo,
    skip,
    tagsText,
    take,
    tabelaUpdateCount,
  ]);

  // busca de categorias
  const [selectedCategoria, setSelectedCateg] = useState<
    number | undefined | null
  >();

  const [selectedLastCategoria, setSelectedLastCateg] = useState<
    number | undefined | null
  >();

  // busca categoria
  const setSelectedCategoria = (categId: number | undefined | null) => {
    setSelectedCateg(categId);
  };
  useEffect(() => {
    if (selectedCategoria && filterType === 'categorias') {
      setLoading(true);
      let origem = _origem.current;
      if (!origem) origem = EnumOrigens.CATEGORIA;

      productSearchCateg(
        selectedCategoria,
        take,
        skip,
        filterText,
        tagsText,
        origem
      )
        .then((data) => {
          if (data.produtos.length > 0) {
            setListProdutos(data);
          } else {
            setListProdutos({} as Search);
          }
          setSelectedLastCateg(selectedCategoria);
          setOrigem(undefined);
        })
        .finally(() => setLoading(false));
    }
  }, [
    filterText,
    filterType,
    selectedCategoria,
    skip,
    tagsText,
    take,
    tabelaUpdateCount,
  ]);

  // console.log({ selectedTabMarcaPosition, selectedTabTipoPosition });
  return (
    <Context.Provider
      value={{
        clear,
        clearTipo,
        clearModelo,
        marcas,
        marcasFilterTipo,
        selectedTabMarca,
        setSelectedTabMarca,
        selectedTabMarcaPosition,
        setSelectedTabMarcaPosition,
        filterTipo,
        filterModelo,
        filterModeloMarca,
        selectedTipo,
        setSelectedTipo,
        selectedTabTipo,
        setSelectedTabTipo,
        selectedTabTipoPosition,
        setSelectedTabTipoPosition,
        selectedMarca,
        selectedLastMarca,
        setSelectedMarca,
        selectedModelo,
        selectedLastModelo,
        setSelectedModelo,
        listProdutos,
        filterEixos,
        filterType,
        setFilterType,
        cardType,
        setCardType,
        orderType,
        setOrderType,
        loading,
        // searchText,
        setSearchedText,
        searchedText,
        selectedCategoria,
        selectedLastCategoria,
        setSelectedCategoria,
        take,
        setTake,
        skip,
        setSkip,
        searchModelo,
        setSearchModelo,
        selectedSearchedModelo,
        setSelectedSearchedModelo,
        setFilterEixos,
        filterText,
        setFilterText,
        tagsText,
        setTagsText,
        setOrigem,
        getOrigem,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useProduct = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { ProductProvider, useProduct };
