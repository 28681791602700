import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { useProduct } from '../pages/product/product.hook';
import { classNames, getTake } from '../utils/functions';
import { EnumOrigens } from '../utils/origens';
import { useApp } from '../app.hook';

export default function MainSearch({
  fullWidth = false,
  afterAction,
}: {
  fullWidth?: boolean;
  afterAction?: () => void;
}) {
  const location = useLocation();
  const { isXs } = useApp();
  const { searchedText, setFilterType, setSearchedText, setOrigem } =
    useProduct();
  const [simpleText, setSimpleText] = useState('');
  const navigate = useNavigate();

  // const text = (text: string) => {
  //   setFilterType('text');
  //   const _take = getTake();
  //   navigate(
  //     ROUTES_MAP.product +
  //       '/?buscaProdutos=' +
  //       text +
  //       '&take=' +
  //       _take +
  //       '&skip=0',
  //     { replace: true, state: { text } }
  //   );
  // };

  useEffect(() => {
    setSimpleText(searchedText as any);
  }, [searchedText]);

  const search = () => {
    setOrigem(EnumOrigens.SEARCH_FOR);
    // navigator(ROUTES_MAP.product);
    // text(simpleText);
    const _take = getTake();

    const route =
      ROUTES_MAP.product +
      '/?buscaProdutos=' +
      encodeURIComponent(simpleText) +
      '&take=' +
      _take.toString() +
      '&skip=0&tags=&filtros=';

    setFilterType('text');
    // setSearchedText('');
    new Promise((t) => setTimeout(t, 100)).then(() => {
      setSearchedText(simpleText);
    });
    if (route.replace(' ', '%20') !== location.pathname + location.search) {
      navigate(route);
    }
    if (afterAction) afterAction();
  };
  // teste
  return (
    <div
      className={classNames(
        'flex flex-col ',
        fullWidth ? 'w-full' : 'w-[30vw]'
      )}
    >
      {/* <span className="text-[0.8em] ml-4 text-white">Pesquisar produtos</span> */}
      <div
        className={classNames(
          'flex items-center bg-white rounded-md text-gray-600 w-full',
          isXs ? 'pr-0' : 'pr-1'
        )}
      >
        <input
          className="bg-white w-full focus:outline-none focus:shadow-none ml-2 pl-2 ring-0 outline-none border-0 right-0 border-white focus:border-white"
          type="search"
          placeholder="Pesquise por produto"
          value={simpleText}
          onChange={(e) => setSimpleText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              search();
            }
          }}
        />
        <span className="w-1" />
        {isXs ? (
          <button
            onClick={search}
            className="hover:text-gray-400 h-12 px-2 bg-gray-500 rounded-r-md"
          >
            <span className="text-white">Buscar</span>
          </button>
        ) : (
          <button onClick={search} className="hover:text-gray-400 h-9">
            <SearchIcon className="w-6 h-6 p-1" />
          </button>
        )}
      </div>
    </div>
  );
}
