/* eslint-disable prettier/prettier */
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Cart } from '../../app.models';
import accounting from '../../utils/accounting';
import { parseCurrencyBRL } from '../../utils/numberHandler';
import { logoFullBase64 } from './logoFullBase64';
import { tableWidths } from './getTableProdutosImageDD';
import { cartStatus } from '../../pages/myCarts/carts.hook';
import GetTableProdutosImageDD from './getTableProdutosImageDD';


interface Props {
  cart: Cart;
}

function getCartStatus(status: number): string {
  const list = cartStatus.find((s) => s.id === status);
  return list?.situacao ?? 'N/D';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sumField({ data, field }: { data: any[]; field: string }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reduce = data.reduce((acc: number, cur: any) => {
    acc += accounting.unformat(cur[field]);
    return acc;
  }, 0);

  return accounting.toFixed(reduce).replace('.', ',');
}

function defaultHeader(
  currentPage: number,
  pageSize: { width: number },
  cart: Cart
): Content {
  return [
    {
      columns: [
        {
          image: logoFullBase64,
          width: 90,
          height: 40,

        },
        {
          stack: [
            {
              text: `Orçamento: ${cart.id}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Vendedor: ${cart.vendedor.nome ?? 'N/D'}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Data: ${new Date(cart.created_at).toLocaleDateString('pt-BR')}`,
              alignment: 'right',
              fontSize: 8,
            },
            {
              text: `Situação: ${getCartStatus(cart.situacaoCarrinho)}`,
              alignment: 'right',
              fontSize: 8,
              // color: cart.situacaoCarrinho === 0 ? 'red' : 'green',
            },
          ]
        }
      ],
      margin: [40, 30, 40, 0]
    },
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
      margin: [0, 5, 0, 1]
    },
  ];
}

function defaultFooter(
  currentPage: number,
  pageCount: number,
  pageSize: { width: number },
): Content {
  return [
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
    },
    {
      stack: [
        {
          text: 'Pag. '.concat(
            currentPage.toString(),
            ' de ',
            pageCount.toString(),
            // eslint-disable-next-line prettier/prettier
            ` - ${new Date().getDate()}/${new Date().getMonth() + 1
            }/${new Date().getFullYear()}`
          ),
          margin: [30, 0, 30, 0],
          fontSize: 8,
        },
      ],
    },
  ];
}

export default async function PrintCartImageDD({
  cart
}: Props): Promise<TDocumentDefinitions> {
  const tableBody = await GetTableProdutosImageDD({ items: cart.produtos as any });

  const dd: TDocumentDefinitions = {
    defaultStyle: {
      font: 'Roboto',
    },

    pageSize: 'A4',

    pageOrientation: 'portrait',

    pageMargins: [40, 90, 40, 60],

    header: (currentPage, pageCount, pageSize) =>
      defaultHeader(currentPage, pageSize, cart),



    content: [
      {
        text: 'Orçamento',
        fontSize: 12,
        alignment: 'center',
        margin: [0, 0, 0, 10],
      },
      {
        stack: [
          {
            columns: [
              { text: `Nome/Razão: ${cart.cliente.nome}`, fontSize: 10, alignment: 'left' },
              { text: `CPF/CNPJ: ${cart.cliente.cpf_cnpj}`, fontSize: 10, alignment: 'right' },
            ]
          },
          { text: `Contato: ${cart.cliente.foneNumero}`, fontSize: 10 },
        ]
      },
      {
        stack: [
          { text: `Produtos`, fontSize: 10, alignment: 'left' },
          // LineFull
        ],
        margin: [0, 10, 0, 0]
      },
      {
        table: {
          //   layout: 'lightHorizontalLines',
          widths: tableWidths,
          headerRows: 1,
          body: tableBody,
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0 ? '#CCCCCC' : null;
          },
        },
        style: {
          fontSize: 10,
        },
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          { text: `Quantidade de Itens: ${cart.qtdeTotal}` },
          { text: `Total do Pedido: ${parseCurrencyBRL(cart.total)}`, alignment: 'right' },
        ]
      }
    ],

    footer: defaultFooter,

    // styles: {
    //   header: {
    //     fontSize: 10,
    //     bold: true,
    //     margin: [40, 30, 30, 30]
    //   },
    // },
  };

  return dd;
}
