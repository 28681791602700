import { Produto } from '../../app.models';
import Button from '../../components/layout/button';
import { getTake } from '../../utils/functions';
import { parseCurrency } from '../../utils/numberHandler';
import ofertaSvg from '../../assets/svg/oferta.svg';
import { ReactSVG } from 'react-svg';
import ModalProduct from './modalProduct';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { useCart } from '../cart/cart.hook';
import { getCart } from '../cart/cart.service';
import ModalSelectCard from '../myCarts/modalSelectCard';
import { Progress } from '../../components/progress';
import { useApp } from '../../app.hook';
import { useProduct } from './product.hook';
import { EnumOrigens } from '../../utils/origens';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';

interface DisplayProductCardRelacionadosProps {
  product: Produto;
}

const DisplayProductCardRelacionados: React.FC<
  DisplayProductCardRelacionadosProps
> = ({ product }: DisplayProductCardRelacionadosProps) => {
  const { isPerfilManager } = useApp();
  const { setOrigem } = useProduct();
  const [modalOpen, setOpenModal] = useState<boolean>(false);

  const { currentCart, newCart } = useCart();
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const buy = async () => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      setOpenModal(true);
    } else {
      // setOpenModalSelectCart(true);
      newCart();
      setOpenModal(true);
    }
  };

  const navigate = useNavigate();
  const detail = (productId: number) => {
    setOrigem(EnumOrigens.RELACIONADOS);
    navigate(ROUTES_MAP.productById(productId));
  };
  return (
    <div
      key={product.id}
      className={`bg-white py-2 px-3 h-[440px] border-[1px] border-gray-200 border-solid shadow hover:shadow-2xl rounded-lg w-full xl:w-[90%] 2xl:w-[70%] mx-auto`}
    >
      {product.isPromocao ? (
        <ReactSVG
          src={ofertaSvg}
          className="relative -right-[60%] -top-[2.4%] z-10 fill-amber-300 w-24  animate-pulse"
        />
      ) : (
        <span className="relative z-10 h-[10%] before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-transparent flex flex-col justify-center items-center p-1 w-20"></span>
      )}

      <LazyLoadingImage
        classNames={['flex justify-center mb-3 md:mb-4 relative -top-1']}
        alt={product.descricaoSite}
        height={120}
        width={130}
        src={product.images[0].medium}
        efect="blur"
      />
      <div className="relative -top-9 text-sm text-black h-[15%] overflow-hidden">
        {product.descricaoSite}
      </div>
      <div className="relative -top-11 text-xs h-[7%]">
        Categoria:{' '}
        {product.categoria && product.categoria.id ? (
          <Link
            className="text-blue-400 underline"
            to={
              '/produtos/?categoria=' +
              product.categoria.id +
              '&take=' +
              getTake() +
              '&skip=0'
            }
          >
            {product.categoria.descricao}
          </Link>
        ) : (
          'N/D'
        )}
      </div>
      <div className="relative -top-9 text-xs h-[7%]">
        Ref: <span className="">{product.referencia || 'N/D'}</span>
      </div>
      <div className="relative -top-9 text-xs flex justify-between">
        <span>
          Código: <span className="">{product.codigoSgi}</span>
        </span>
        {isPerfilManager && (
          <span>
            Saldo: <span className="">{product?.saldo?.saldo}</span>
          </span>
        )}
      </div>

      {product.isPromocao ? (
        <div className="relative -top-10 flex flex-col my-1">
          <div className="flex flex-col items-end">
            <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
              {/* verificar se é preco ou precoPromo  */}
              {/* <span>{parseCurrency(product.preco)}</span> */}
              <ExibirPrecoConsumidorFinal
                item={product}
                field="preco"
                fieldConsumidorFinal="precoConsumidorFinal"
              />
            </span>
            <span className="flex space-x-2 text-md md:text-md lg:text-2xl text-amber-500 font-bold">
              {/* <span>{parseCurrency(product.precoPromo)}</span> */}
              <ExibirPrecoConsumidorFinal
                item={product}
                field="precoPromo"
                fieldConsumidorFinal="precoPromoConsumidorFinal"
              />
            </span>
          </div>
          {product.promocao && (
            <div className="flex justify-center">
              <Progress
                qtdeTotal={product.promocao.qtdeEmPromocao}
                qtdeMovimentada={product.promocao.qtdeMovimentada}
                qtdeDisponivel={product.promocao.qtdeDisponivel}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="relative -top-9 flex flex-col justify-center my-0 py-2 h-[19%]">
          <div className="flex my-3 justify-end">
            <span className="flex space-x-2 font-bold text-xl">
              <ExibirPrecoConsumidorFinal
                item={product}
                field="precoPromo"
                fieldConsumidorFinal="precoPromoConsumidorFinal"
              />
              {/* <span>{parseCurrency(product.precoPromo)}</span> */}
            </span>
          </div>
        </div>
      )}
      <div
        className={`relative ${
          product.isPromocao ? 'bottom-[2rem]' : 'bottom-[2.3rem]'
        } flex flex-col items-center`}
      >
        <div className="my-2 flex justify-center">
          {/* <Button title="Comprar" onClick={buy} /> */}
          <ButtonComprar produto={product} action={buy} />
        </div>
        <div className="text-center">
          <button
            className="text-primary1 mx-auto hover:underline"
            onClick={() => detail(product.id)}
          >
            Mais Detalhes
          </button>
        </div>
      </div>
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={product}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </div>
  );
};

export default DisplayProductCardRelacionados;
