import clsx from 'clsx';
import { Produto } from '../../app.models';
import { ReactComponent as OfertaSvg } from '../../assets/svg/oferta.svg';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { Link } from 'react-router-dom';
import { useProduct } from './product.hook';
import { getTake } from '../../utils/functions';
import { EnumOrigens } from '../../utils/origens';
import { useApp } from '../../app.hook';
import { Progress } from '../../components/progress';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';

interface Props {
  produto: Produto;
  buy?: () => void;
  detail?: (produtoId: number) => void;
}

export default function CardProduct({ produto, buy, detail }: Props) {
  const { isPerfilManager } = useApp();
  const { setOrigem } = useProduct();

  return (
    <div
      className={clsx(
        'flex flex-col justify-between items-center',
        'bg-white py-1 px-3 rounded-md',
        'border border-gray-200 border-solid',
        'shadow hover:shadow-2xl',
        'space-y-1',
        'h-[27em]'
      )}
    >
      <div className="w-full relative -top-3 h-12">
        {produto.isPromocao ? (
          <OfertaSvg className="z-10 h-12 fill-amber-300 w-[40%] animate-pulse" />
        ) : (
          <div className="h-12"></div>
        )}
        <div className="">
          <LazyLoadingImage
            classNames={['flex justify-center mb-3 md:mb-4 relative -top-1']}
            alt={produto.descricaoSite}
            height={120}
            width={130}
            src={produto.images[0].medium}
            efect="blur"
          />
        </div>
        {/* descricao produto */}
        <div className="text-sm text-black overflow-hidden line-clamp-2 h-10 text-ellipsis">
          {produto?.descricaoSite}
        </div>
        {/* descricao categoria */}
        <div className="text-xs h-8 text-right">
          {produto.categoria && produto.categoria.id ? (
            <Link
              onClick={() => setOrigem(EnumOrigens.CATEGORIA)}
              className="text-blue-400 underline"
              to={
                '/produtos/?categoria=' +
                produto.categoria.id +
                '&take=' +
                getTake() +
                '&skip=0'
              }
            >
              {produto?.categoria?.descricao}
            </Link>
          ) : (
            'N/D'
          )}
        </div>
        {/* outros dados */}
        <div>
          <div className="text-xs">
            Ref: <span className="">{produto.referencia || 'N/D'}</span>
          </div>
          <div className="text-xs flex justify-between">
            <span>
              Código: <span className="">{produto.codigoSgi}</span>
            </span>
            {isPerfilManager && (
              <span>
                Saldo: <span className="">{produto?.saldo?.saldo}</span>
              </span>
            )}
          </div>
        </div>
        {/* valor produto */}
        <div className="my-0">
          {produto.isPromocao ? (
            <div className="flex flex-col h-[4.5em]">
              <div className="flex flex-col items-end">
                <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
                  {/* <span>{parseCurrency(produto.preco)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={produto}
                    field="preco"
                    fieldConsumidorFinal="precoConsumidorFinal"
                  />
                </span>
                <span className="flex space-x-2 text-md md:text-md lg:text-2xl text-amber-500 font-bold">
                  {/* <span>{parseCurrency(produto.precoPromo)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={produto}
                    field="precoPromo"
                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                  />
                </span>
              </div>
              {produto.promocao && (
                <div className="flex justify-center">
                  <Progress
                    qtdeTotal={produto.promocao.qtdeEmPromocao}
                    qtdeMovimentada={produto.promocao.qtdeMovimentada}
                    qtdeDisponivel={produto.promocao.qtdeDisponivel}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center my-0 py-2 h-[19%]">
              <div className="flex my-3 justify-end">
                <span className="flex space-x-2 font-bold text-xl">
                  {/* <span>{parseCurrency(produto.precoPromo)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={produto}
                    field="precoPromo"
                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        {/* botao comprar */}
        {buy && (
          <div className="flex justify-center">
            {/* <Button title="Comprar" onClick={buy} /> */}
            <ButtonComprar produto={produto} action={buy} />
          </div>
        )}

        {/* mais detalhes */}
        {detail && (
          <div className="text-center my-1">
            <button
              className="text-primary1 mx-auto hover:underline"
              onClick={() => detail(produto.id)}
            >
              Mais Detalhes
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
