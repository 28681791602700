import { Fragment, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { XIcon, SearchCircleIcon, MenuIcon } from '@heroicons/react/outline';
import MainSearch from '../mainSearch';
import { useApp } from '../../app.hook';
import MenuMarcaModelo from '../menuMarcaModelo';
import SearchMarcaCaroucel from '../SearchMarcaCaroucel';
import { useProduct } from '../../pages/product/product.hook';
import { useNavigate } from 'react-router-dom';
import { getTake } from '../../utils/functions';
import clsx from 'clsx';
import { ROUTES_MAP } from '../../app.map.routes';

export default function MenuMultibusca() {
  const navigate = useNavigate();
  const { empresa } = useApp();
  const { setOrigem } = useProduct();
  const _take = getTake();

  const showPesquisaVeiculo = empresa?.exibirPesquisaVeiculos || false;
  const showPesquisaCategoria = empresa?.exibirPesquisaCategorias || false;
  const pesquisarSomenteMarca = empresa?.pesquisarSomenteMarca || false;

  const buttomMenu = useMemo(
    () => (
      <div className="flex items-center justify-center">
        <Popover.Button className="flex items-center justify-center gap-2 px-3 text-white hover:text-gray-100 bg-primary1 rounded-lg ring-0">
          <SearchCircleIcon className="h-10 w-10" aria-hidden="true" />
          <span className="">Multi-busca</span>
        </Popover.Button>
      </div>
    ),
    []
  );
  return (
    <Popover className="w-full absolute top-28 shadow z-40 bg-white my-1">
      <div className="h-[5rem]">
        <div className="md:hidden p-2">
          <div className="pt-5 px-5">{buttomMenu}</div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-primary1 ring-opacity-4 bg-primary1 divide-y-2 divide-gray-50">
              <div className="pt-5 px-5 border-b border-white border-solid pb-2">
                <div className="w-[90%] h-full flex justify-center gap-1 items-center">
                  {/* <PopoverCategoria position="left" /> */}
                  <MainSearch fullWidth afterAction={() => close()} />
                </div>
                <Popover.Button className="bg-white absolute top-5 right-4 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary1">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              {/* content */}
              <div className="h-full py-4 space-y-4">
                <div className="flex-grow-0 flex flex-col md:flex-row space-x-2 md:space-y-0 items-center md:font-bold px-1">
                  <button
                    className={`flex flex-grow-0 items-center space-x-2 px-2 hover:bg-default-white py-1 rounded-full group`}
                    onClick={() => {
                      navigate(ROUTES_MAP.allCategorias);
                    }}
                  >
                    <MenuIcon className="w-10 h-10 text-gray-400 bg-white p-[1px] rounded-full lg:flex" />
                    <span
                      className={clsx(
                        'font-bold text-white md:text-default-white group-hover:text-primary1'
                      )}
                    >
                      <div>
                        {String('Todos os produtos').toLocaleUpperCase()}
                      </div>
                    </span>
                  </button>
                </div>
                {pesquisarSomenteMarca ? (
                  <div>
                    {/* <SearchCategoria afterAction={() => close()} /> */}
                    <SearchMarcaCaroucel afterAction={() => close()} />
                  </div>
                ) : null}
                {showPesquisaVeiculo ? (
                  <div className={clsx('flex gap-2 justify-between')}>
                    <MenuMarcaModelo reverseColors={false} transparent />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
