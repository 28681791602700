import { Empresa } from '../app.models';
interface Props {
  className?: string;
  wrapperClassName?: string;
  empresa: Empresa;
}

export default function EmpresaImagemLogin({
  className,
  empresa,
  wrapperClassName,
}: Props) {
  if (!empresa?.empresaImagemLogin) return <></>;
  const image = empresa.empresaImagemLogin;

  return (
    <div className={wrapperClassName}>
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
        alt="logo"
        className={className}
      />
    </div>
  );
}
