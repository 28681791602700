import { useEffect, useState } from 'react';
import { useApp } from '../../app.hook';
import { Categoria } from '../../app.models';
import LazyLoadingImage from '../../components/lazyLadingImage';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import { getTake } from '../../utils/functions';

export default function AllCategorias() {
  const navigate = useNavigate();
  const { isXs } = useApp();
  const { categoriasRaw } = useApp();
  const [loadingCategorias, setLoadingCategorias] = useState<boolean>(false);
  const [filterCategorias, setFilterCategorias] = useState<Categoria[]>([]);
  const [selectedCategoria, setSelectedCategoria] = useState<Categoria>();
  const ITEM_CLASS = clsx(
    'aspect-w-16 aspect-h-9',
    'flex flex-col transition ease-in-out delay-150 hover:scale-[1.1] rounded-md overflow-hidden min-h-full group',
    'border-[2px] border-slate-400 border-solid'
  );

  const ITEM_DESC_CLASS = clsx(
    'text-[0.55em] w-full p-1 mt-[70%] flex justify-center items-center group-hover:font-bold group-hover:text-white',
    'flex flex-col justify-center items-center',
    'h-12',
    'lg:text-[0.6em] xl:text-[0.7em] 2xl:text-[0.8em] p-1'
  );

  const _take = getTake();

  useEffect(() => {
    setLoadingCategorias(true);
    const res = categoriasRaw;
    // ordena por descricao
    const list = res.filter(
      (item) =>
        item.temSubCategoria === 0 && item.ativo === 1 && item.isVisible === 1
    );
    const sort = list.sort((a, b) => a.descricao.localeCompare(b.descricao));
    setFilterCategorias(sort);
    setLoadingCategorias(false);
  }, [categoriasRaw]);

  return (
    <div
      className={clsx(
        isXs && 'mt-36',
        'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-1 gap-y-4 py-3 px-6 h-[78vh] overflow-hidden overflow-y-auto'
      )}
    >
      {filterCategorias.map((categ) => (
        <button
          key={categ.id}
          onClick={() => {
            setSelectedCategoria(categ);
            navigate(
              encodeURI(
                `${
                  ROUTES_MAP.product
                }?take=${_take.toString()}&skip=0&tags={"categorias":[${
                  categ.id
                }]}`
              )
            );
          }}
          style={{ width: `150px`, height: `150px` }}
          className={clsx(ITEM_CLASS)}
          data-selected={selectedCategoria?.id === categ.id}
        >
          <LazyLoadingImage
            classNames={['mx-auto rounded-full']}
            alt={categ.descricao}
            height={'100%'}
            width={'100%'}
            src={categ.images[0].img}
            efect="blur"
          />
          <div
            className={clsx(
              ITEM_DESC_CLASS,
              selectedCategoria?.id === categ.id
                ? 'bg-primary2 text-white'
                : 'bg-slate-400 text-white'
            )}
          >
            <span>{categ.descricao}</span>
          </div>
        </button>
      ))}
    </div>
  );
}
