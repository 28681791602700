/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useProduct } from '../pages/product/product.hook';
import clsx from 'clsx';
import ModeloSearch from './modeloSearch';
import SelectMarcaModelo from './selectMarcaModelo';
import { useApp } from '../app.hook';

interface Props {
  reverseColors?: boolean;
  transparent?: boolean;
}
export default function MenuMarcaModelo({
  reverseColors = false,
  transparent = false,
}: Props) {
  const { isXs } = useApp();
  return (
    <div
      className={clsx(
        'mx-1 flex-1 overflow-hidden px-2 py-1',
        transparent
          ? 'bg-transparent'
          : 'bg-gradient-to-b from-[#281e5d]/90 to-[#281e5d]'
      )}
    >
      <div
        className={clsx(
          'flex justify-between items-center h-full',
          isXs ? 'flex-col gap-4' : ''
        )}
      >
        <div className="mx-1 my-1">
          <ModeloSearch reverseColors={reverseColors} action={close} />
        </div>
        <div className="my-1">
          <SelectMarcaModelo reverseColors={reverseColors} action={close} />
        </div>
      </div>
    </div>
  );
}
