import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMedia from 'use-media';
// import { LOCAL_STORAGE_PREFIX } from '../../app.settings';
import Pagination from '../../components/layout/pagination';
import CardMyOrders from './cardMyOrders';
import { useOrder } from './order.hook';
import ParamsOrder from './paramsOrders';
import TableMyOrders from './tableMyOrders';
import 'react-sliding-side-panel/lib/index.css';
import { Pedido } from '../../app.models';
import { getPedido } from './pedido.service';
import { PrintOrderBase64, PrintOrderPDF } from '../../components/pdf';
import ModalConfirmationNumberWhatsapp from '../../components/modalConfirmationNumberWhatsapp';
import { toast } from 'react-toastify';
import { sendZapiDocument } from '../../zapi.service';
import { useApp } from '../../app.hook';
import { svgToBase64 } from '../../utils/functions';

export default function Orders() {
  const isSmall = useMedia({ maxWidth: 480 });
  const { currentUser, empresa } = useApp();

  // const [isOpenPanel, setIsOpenPanel] = useState(false);

  // const closePanel = () => setIsOpenPanel(false);

  const {
    pedidos,
    info,
    loading,
    setSkip,
    dtIni,
    setDtIni,
    dtEnd,
    setDtEnd,
    filters,
    setFilters,
  } = useOrder();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const currentParams = Object.fromEntries(searchParams);

    // DATE INI +++++++++++++++++++++++++++
    if (searchParams.has('dataI')) {
      const dataI = currentParams.dataI;
      dataI ? setDtIni(new Date(dataI)) : setDtIni(undefined);
    }

    // DATE END +++++++++++++++++++++++++++
    if (searchParams.has('dataF')) {
      const dataF = currentParams.dataF;
      dataF ? setDtEnd(new Date(dataF)) : setDtEnd(undefined);
    }

    // SEARCH_FOR +++++++++++++++++++++++++++
    // if (searchParams.has('searchFor')) {
    //   const searchFor = currentParams.searchFor;
    //   searchFor !== searchOnPageOrders && setSearchOnPageOrders(searchFor);
    // } else {
    //   const params = searchParams;
    //   params.set('searchFor', searchOnPageOrders);
    //   setSearchParams(params);
    // }

    // FILTERS +++++++++++++++++++++++++++
    if (searchParams.has('situacao')) {
      const situacao = currentParams.situacao;
      setFilters(situacao);
    } else {
      const params = searchParams;
      params.set('situacao', filters || '');
      setSearchParams(params);
    }

    // TAKE +++++++++++++++++++++++++++
    // if (searchParams.has('take')) {
    //   const take = parseInt(currentParams.take);
    //   setTake(take);
    // } else {
    //   setTake(
    //     parseInt(
    //       localStorage.getItem(LOCAL_STORAGE_PREFIX + 'page-take-pedidos') ||
    //         '0'
    //     )
    //   );
    // }
    const params = searchParams;
    params.set('take', '12');
    setSearchParams(params);

    // SKIP +++++++++++++++++++++++++++
    if (searchParams.has('skip')) {
      const skip = parseInt(currentParams.skip);
      setSkip(skip);
    } else {
      setSkip(0);
      const params = searchParams;
      params.set('skip', '0');
      setSearchParams(params);
    }
  }, [
    filters,
    searchParams,
    setDtEnd,
    setDtIni,
    setFilters,
    setSearchParams,
    setSkip,
  ]);

  // whatsapp e modal
  const openPanelData = useRef<Pedido>();
  const openPanel = async (pedidoId: number) => {
    const pedido = await getPedido(pedidoId);
    console.log('🚀 ~ openPanel ~ pedido:', pedido);
    const logo = await svgToBase64(empresa?.empresaLogoCompleto ?? null);
    // openPanelData.current = pedido;
    // setIsOpenPanel(true);
    PrintOrderPDF(pedido, logo, undefined);
  };
  const [modalIsOpenWhatsapp, setModalIsOpenWhatsapp] =
    useState<boolean>(false);

  async function openModalWhatsapp(pedidoId: number) {
    const pedido = await getPedido(pedidoId);
    openPanelData.current = pedido;
    setModalIsOpenWhatsapp(true);
  }

  const handleWhatsapp = async (pedidoNum: number, number: string) => {
    if (!pedidoNum) return;
    const pedido = await getPedido(pedidoNum);
    const base64 = await PrintOrderBase64(pedido, empresa?.empresaLogoCompleto);
    const result = await sendZapiDocument({
      document: `data:application/pdf;base64,${base64}`,
      phone: number,
      extension: 'pdf',
      fileName: `Pedido-${pedido.xRef}`,
    });
    if (result) {
      toast.success('Pedido enviado com sucesso!');
    } else {
      toast.error('Erro ao enviar pedido!');
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const params = searchParams;
    params.set('skip', String(selectedItem.selected));
    setSearchParams(params);
  };

  // const showPdfOrder = () => {
  //   if (!openPanelData.current) return;
  //   PrintOrderPDF(openPanelData.current);
  // };

  if (isSmall)
    return (
      <div className="mt-24 p-2">
        <ParamsOrder
          startDate={dtIni}
          endDate={dtEnd}
          qtdeRegs={info?.qtdeTotal || 0}
          showingRegs={pedidos && pedidos.length}
          dateRange={(startDate, endDate) => {
            const params = searchParams;
            if (startDate && endDate) {
              params.set('dataI', moment(startDate).format('YYYY-MM-DD'));
              params.set('dataF', moment(endDate).format('YYYY-MM-DD'));
            } else {
              params.set('dataI', '');
              params.set('dataF', '');
            }
            setSearchParams(params);
          }}
          info={info?.qtdePorPagina ? info : undefined}
        />

        <div className="">
          {loading ? (
            <div className="flex flex-col justify-center items-center animate-pulse">
              <span className="mx-auto"></span>Aguarde ...
            </div>
          ) : (
            <CardMyOrders
              pedidos={pedidos}
              afterAction={() => {
                // load();
                return;
              }}
            />
          )}
        </div>
        <div className="">
          <Pagination
            curPage={Number(searchParams.get('skip'))}
            qtdeReg={info?.qtdeTotal || 0}
            perPage={Number(searchParams.get('take'))}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    );
  else
    return (
      <div className="mt-24 md:mt-0 p-2 h-[88vh]">
        <ParamsOrder
          startDate={dtIni}
          endDate={dtEnd}
          qtdeRegs={info?.qtdeTotal || 0}
          showingRegs={pedidos && pedidos.length}
          dateRange={(startDate, endDate) => {
            const params = searchParams;
            if (startDate && endDate) {
              params.set('dataI', moment(startDate).format('YYYY-MM-DD'));
              params.set('dataF', moment(endDate).format('YYYY-MM-DD'));
            } else {
              params.set('dataI', '');
              params.set('dataF', '');
            }
            setSearchParams(params);
          }}
          info={info?.qtdePorPagina ? info : undefined}
        />

        <div className="h-[75vh] overflow-hidden overflow-y-auto">
          {loading ? (
            <div className="flex flex-col justify-center items-center h-[88vh] animate-pulse">
              <span className="mx-auto"></span>Aguarde ...
            </div>
          ) : (
            <TableMyOrders
              pedidos={pedidos}
              rowClickAction={(pedido: Pedido) => {
                openPanel(pedido.id);
              }}
              rowClickWhatsapp={(pedido: Pedido) => {
                openModalWhatsapp(pedido.id);
              }}
            />
          )}
        </div>
        <Pagination
          curPage={Number(searchParams.get('skip'))}
          qtdeReg={info?.qtdeTotal || 0}
          perPage={Number(searchParams.get('take'))}
          handlePageClick={handlePageClick}
        />
        <ModalConfirmationNumberWhatsapp
          isOpen={modalIsOpenWhatsapp}
          setIsOpen={setModalIsOpenWhatsapp}
          action={(number) =>
            openPanelData.current?.id &&
            handleWhatsapp(openPanelData.current?.id, number)
          }
          currentUser={currentUser}
        />
        {/* <SlidingPanel type={'right'} isOpen={isOpenPanel} size={30}>
          <div className="bg-white h-full">
            <div className="p-2 flex justify-end space-x-2">
              <Button onClick={showPdfOrder} title={'Imprimir'} />
              <Button onClick={closePanel} title={'Fechar'} />
            </div>
            <OrderView pedido={openPanelData.current ?? ({} as Pedido)} />
            {/* <div className="bg-white h-full">
            <div className="p-2 bg-primary1 text-default-white">
              Pedido: {openPanelData.current?.id}
            </div>

            <div className="h-[85vh] overflow-hidden overflow-y-auto">
              <TableShowListProducts
                products={
                  openPanelData.current?.produtos
                    ? (openPanelData.current
                        ?.produtos as unknown as ProdutoCart[])
                    : []
                }
              />
            </div>

            <div className="p-2">
              <Button onClick={closePanel} title={'Fechar'} />
            </div>
          </div> */}
        {/* </div> */}
        {/* </SlidingPanel> */}
      </div>
    );
}
