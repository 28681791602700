import {
  DotsCircleHorizontalIcon,
  EyeIcon,
  ShoppingCartIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SlidingPanel from 'react-sliding-side-panel';
import { toast } from 'react-toastify';
import { ROUTES_MAP } from '../../../app.map.routes';
import { Cart, ProdutoCart } from '../../../app.models';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import TableShowListProducts from '../../../components/tableShowListProducts';
import { getFormatedDateTimeSemTimeZone } from '../../../utils/datetimeHandler';
import {
  classNames,
  truncateStringWithEllipsis,
} from '../../../utils/functions';
import { parseCurrency } from '../../../utils/numberHandler';
import { aprovarReprovarCart, getCart } from '../../cart/cart.service';
import { useCart } from '../../cart/cart.hook';
import clsx from 'clsx';
import { Switch } from '@headlessui/react';
import AppPerPage from '../../../components/layout/appPerPage';
import AppPaginator from '../../../components/layout/pagination';

const INITIAL_MODAL_CONFIRMATION = {
  question: '',
  title: '',
  clienteId: 0,
  id: 0,
  situacaoCarrinho: 0,
  updated_at: new Date(),
  usuarioId: '',
};

interface Props {
  carts: Cart[];
  afterAction: () => void;
  limit?: number;
  expandido?: boolean;
  verTudo?: () => void;
  close?: () => void;
}

interface CartStatusProps {
  situacaoCarrinho: number;
}
const CartStatus = ({ situacaoCarrinho }: CartStatusProps) => {
  const { cartStatus } = useCart();
  const status = cartStatus.find((c) => c.id === situacaoCarrinho);
  const class_name = cartStatus.find(
    (c) => c.id === situacaoCarrinho
  )?.className;
  if (status)
    return (
      <div className={classNames('flex carts-center', class_name)}>
        <span>{status.icone}</span>
        <span>{status.situacao}</span>
      </div>
    );
  else
    return (
      <div className="flex carts-center">
        <span>
          <DotsCircleHorizontalIcon className="w-5 h-5" />
        </span>
        <span>Não Definida</span>
      </div>
    );
};

export default function InnerGridMyCarts(props: Props) {
  const expandido = props.expandido ?? false;
  const limit = props.limit ?? false;
  const navigate = useNavigate();
  const { setCurrentCart } = useCart();
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const modalConfirmationRef = useRef(INITIAL_MODAL_CONFIRMATION);

  const loadCart = (cartId: number) => {
    setCurrentCart(cartId);
    navigate(ROUTES_MAP.cart);
  };
  const aprovar = (cart: Cart) => {
    if (cart.qtdeTotal === 0) {
      toast('Carrinho vazio !!!');
      return;
    }
    modalConfirmationRef.current = {
      title: 'Confirmação!',
      question: 'Deseja aprovar o carrinho #' + cart.id,
      situacaoCarrinho: 1,
      id: cart.id,
      clienteId: cart.cliente.id,
      updated_at: cart.updated_at,
      usuarioId: cart.usuario.id,
    };
    setModalConfirm(true);
  };

  const desAprovar = (cart: Cart) => {
    modalConfirmationRef.current = {
      title: 'Confirmação!',
      question: 'Deseja desaprovar o carrinho #' + cart.id,
      situacaoCarrinho: 0,
      id: cart.id,
      clienteId: cart.cliente.id,
      updated_at: cart.updated_at,
      usuarioId: cart.usuario.id,
    };
    setModalConfirm(true);
  };

  const cancelar = (cart: Cart) => {
    modalConfirmationRef.current = {
      title: 'Confirmação!',
      question:
        'Deseja cancelar o carrinho #' +
        cart.id +
        ', essa ação não pode ser revertida.',
      situacaoCarrinho: 70,
      id: cart.id,
      clienteId: cart.cliente.id,
      updated_at: cart.updated_at,
      usuarioId: cart.usuario.id,
    };
    setModalConfirm(true);
  };

  const answer = async () => {
    await aprovarReprovarCart({
      clienteId: modalConfirmationRef.current.clienteId,
      id: modalConfirmationRef.current.id,
      situacaoCarrinho: modalConfirmationRef.current.situacaoCarrinho,
      updated_at: modalConfirmationRef.current.updated_at,
      usuarioId: modalConfirmationRef.current.usuarioId,
    });
    props.afterAction();
  };

  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const openSidePanelData = useRef({} as any);
  const openSidePanel = async (cartId: number) => {
    const cart = await getCart(cartId);
    openSidePanelData.current = cart;
    setIsOpenSidePanel(true);
  };
  const closeSidePanel = () => setIsOpenSidePanel(false);

  const [rows, setRows] = useState<Cart[]>([]);
  const [curPage, setCurPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(15);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [exibirZerados, setExibirZerados] = useState<boolean>(false);
  useEffect(() => {
    const filter = (cart: Cart): boolean => {
      return exibirZerados ? true : cart.qtdeTotal > 0 && cart.total > 0;
    };

    const filtrado: Cart[] = props.carts.filter(filter);
    setTotalRows(filtrado.length);

    if (props.limit) setRows(filtrado.slice(0, props.limit));
    else setRows(filtrado);
  }, [props.carts, props.limit, exibirZerados]);

  const rowsToDisplay = rows.slice(
    curPage * perPage,
    curPage * perPage + perPage
  );

  return (
    <div className={clsx(props.expandido === true ? 'h-[80vh]' : 'h-[17rem]')}>
      {/* content */}
      <div
        className={clsx(
          props.expandido === true ? 'h-[93.2%]' : 'h-[91%]',
          'overflow-hidden overflow-y-auto'
        )}
      >
        <table className="divide-y divide-gray-200 text-xs w-full">
          <thead className="bg-gray-100 sticky top-0">
            <tr className="">
              <th
                scope="col"
                className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                #
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Situação
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Data
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Vendedor
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Qtde
              </th>
              <th
                scope="col"
                className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
              >
                Valor
              </th>
              <th
                scope="col"
                className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
              >
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-400">
            {rowsToDisplay.map((cart) => (
              <tr
                key={cart.id}
                className="even:bg-gray-50 py-[1] hover:bg-gray-300"
              >
                <td className="font-bold text-md align-middle">{cart.id}</td>
                <td className="whitespace-nowrap align-middle">
                  <CartStatus situacaoCarrinho={cart.situacaoCarrinho} />
                </td>
                <td className="whitespace-nowrap align-middle">
                  {getFormatedDateTimeSemTimeZone(cart.created_at.toString())}
                </td>
                <td className="whitespace-nowrap align-middle">
                  {truncateStringWithEllipsis(
                    cart.usuario.nome.toLocaleUpperCase(),
                    20
                  )}
                </td>

                <td className="whitespace-nowrap text-center align-middle">
                  {cart.qtdeTotal}
                </td>
                <td className="whitespace-nowrap text-right align-middle">
                  {parseCurrency(cart.total)}
                </td>

                <td className="text-center">
                  {cart.situacaoCarrinho > 1 ? (
                    <div className="grid grid-cols-3 col-auto gap-6 w-36 mx-auto">
                      <button
                        type="button"
                        title="Visualizar Itens"
                        className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                        onClick={() => {
                          openSidePanel(cart.id);
                        }}
                      >
                        <EyeIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ) : (
                    <div className="grid grid-cols-4 col-auto gap-1 w-36 mx-auto">
                      <button
                        type="button"
                        title="Visualizar Itens"
                        className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                        onClick={() => {
                          openSidePanel(cart.id);
                        }}
                      >
                        <EyeIcon className="h-4 w-4" />
                      </button>
                      {cart.situacaoCarrinho === 0 && (
                        <button
                          type="button"
                          title="Deixar como principal"
                          className="bg-red-900 p-1 m-1 text-default-white rounded-full"
                          onClick={() => loadCart(cart.id)}
                        >
                          <ShoppingCartIcon className="h-4 w-4" />
                        </button>
                      )}
                      {cart.situacaoCarrinho === 0 ? (
                        <button
                          type="button"
                          title="Aprovar"
                          className="bg-green-500 p-1 m-1 text-default-white rounded-full"
                          onClick={() => aprovar(cart)}
                        >
                          <ThumbUpIcon className="h-4 w-4" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          title="Desaprovar"
                          className="bg-orange-500 p-1 m-1 text-default-white rounded-full"
                          onClick={() => desAprovar(cart)}
                        >
                          <ThumbDownIcon className="h-4 w-4" />
                        </button>
                      )}
                      <button
                        type="button"
                        title="Cancelar"
                        className="bg-primary1 p-1 m-1 text-default-white rounded-full"
                        onClick={() => cancelar(cart)}
                      >
                        <XIcon className="h-4 w-4" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* footer */}
      <div className="grid grid-cols-[1fr_6fr_1fr] content-center gap-2 bg-slate-100 h-10 px-1">
        {/* ver todos ou fechar */}
        <div>
          {expandido === false && limit && totalRows > limit && (
            <button
              className="hover:underline text-xs text-red-700 ml-auto h-full"
              onClick={() => props.verTudo?.()}
            >
              Ver todos
            </button>
          )}

          {expandido === true && (
            <button
              className="hover:underline text-xs text-red-700 ml-auto h-full"
              onClick={props?.close}
            >
              Fechar
            </button>
          )}
        </div>
        {/* paginacao */}
        {expandido && totalRows > perPage ? (
          <div className="flex gap-1">
            <AppPerPage
              take={perPage}
              setTake={(perPage) => {
                setCurPage(0);
                setPerPage(perPage);
              }}
              listPerPage={[15, 25, 50, 100]}
            />
            <AppPaginator
              qtdeReg={totalRows}
              perPage={perPage}
              curPage={curPage}
              handlePageClick={(page) => setCurPage(page.selected)}
            />
            <div className="ml-auto h-full">
              <span className="text-xs">Qtde: {totalRows}</span>
            </div>
          </div>
        ) : (
          <div className="ml-auto h-full">
            <span className="text-xs">Qtde: {totalRows}</span>
          </div>
        )}
        <div className="flex gap-1 h-full">
          <Switch
            checked={exibirZerados}
            onChange={setExibirZerados}
            className={`${exibirZerados ? 'bg-blue-600' : 'bg-gray-200'} ${
              exibirZerados ? 'text-white' : 'text-gray-400'
            } relative inline-flex h-6 w-[8.5rem] items-center rounded-full ml-auto`}
          >
            <span className="text-xs px-[3px]">Exibir zerados</span>
            <span className=""> | </span>
            <span
              className={`${
                exibirZerados ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      </div>
      <SlidingPanel type={'right'} isOpen={isOpenSidePanel} size={50}>
        <div className="bg-white h-full">
          <div className="p-2 bg-primary1 text-default-white">
            Carrinho: {openSidePanelData.current?.id}
          </div>

          <div className="h-[85vh] overflow-hidden overflow-y-auto">
            <TableShowListProducts
              products={
                openSidePanelData.current?.produtos
                  ? (openSidePanelData.current
                      ?.produtos as unknown as ProdutoCart[])
                  : []
              }
            />
          </div>

          <div className="p-2">
            <Button onClick={closeSidePanel} title={'Fechar'} />
          </div>
        </div>
      </SlidingPanel>
      <ModalConfirmation
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        title={modalConfirmationRef.current.title}
        question={modalConfirmationRef.current.question}
        answer={answer}
      />
    </div>
  );
}
