import { Produto } from '../../app.models';
import Button from '../../components/layout/button';
import { getTake } from '../../utils/functions';
import { parseCurrency } from '../../utils/numberHandler';
// import ReactStars from 'react-rating-stars-component';
import { TrendingDownIcon } from '@heroicons/react/outline';
import ModalProduct from './modalProduct';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import LazyLoadingImage from '../../components/lazyLadingImage';
import ModalSelectCard from '../myCarts/modalSelectCard';
import { useCart } from '../cart/cart.hook';
import { getCart } from '../cart/cart.service';
import { Progress } from '../../components/progress';
import ofertaSvg from '../../assets/svg/oferta.svg';
import { ReactSVG } from 'react-svg';
import { useApp } from '../../app.hook';
import { EnumOrigens } from '../../utils/origens';
import { useProduct } from './product.hook';
import ExibirPrecoConsumidorFinal from '../../components/ExibirPrecoConsumidorFinal';
import ButtonComprar from '../../components/buttonComprar';

interface DisplayProductListProps {
  product: Produto;
  origem?: string;
}

const DisplayProductList: React.FC<DisplayProductListProps> = ({
  product,
  origem,
}: DisplayProductListProps) => {
  const { setOrigem } = useProduct();
  const { isPerfilManager } = useApp();
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const detail = (productId: number) => {
    setOrigem(EnumOrigens.PRODUTO);
    navigate(ROUTES_MAP.productById(productId));
  };

  const { currentCart, newCart } = useCart();
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  // const buy = () => {
  //   setOpenModal(true);
  // };

  const buy = async () => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      setOpenModal(true);
    } else {
      // setOpenModalSelectCart(true);
      newCart();
      setOpenModal(true);
    }
  };

  return (
    <>
      <div
        key={product.id}
        className={`grid grid-cols-[1fr_3fr_1fr_1fr_1fr_0.1fr] p-2 border-[1px] border-gray-200 border-solid shadow hover:shadow-lg rounded-lg group`}
      >
        {/* imagem */}
        <div className="">
          {product.isPromocao ? (
            <ReactSVG
              src={ofertaSvg}
              className="relative -right-[60%] -top-[8%] z-10 fill-amber-300 w-[40%] animate-pulse"
            />
          ) : (
            <span className="relative z-10 h-8 before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-transparent flex flex-col justify-center items-center p-1 w-20"></span>
          )}
          <span className="relative -top-[28%]">
            <LazyLoadingImage
              alt={product.descricaoSite}
              height={50}
              width={75}
              src={product.images[0].medium}
              efect="blur"
            />
          </span>
        </div>

        {/* descricao */}
        <div className="flex justify-between">
          <div className="flex flex-col ">
            <p className="w-full line-clamp-1 text-sm font-bold text-black overflow-hidden group-hover:line-clamp-4">
              {product.descricaoSite}
            </p>
            <span className=" text-xs">
              Ref:{' '}
              <span className="font-bold">{product.referencia || 'N/D'}</span>
            </span>
            <span className="text-xs flex flex-col">
              <span>
                Código: <span className="font-bold">{product.codigoSgi}</span>
              </span>
              {isPerfilManager && (
                <span>
                  Saldo:{' '}
                  <span className="font-bold">{product?.saldo?.saldo}</span>
                </span>
              )}
            </span>
          </div>
          {product.desconto > 0 && (
            <span className="w-10 inset-1 skew-y-3 bg-primary1 flex flex-col justify-center items-center py-0">
              <span className="text-default-white text-xs  font-bold">
                {product.preco ?? 0} %
              </span>
              <TrendingDownIcon className="w-4 h-4 text-default-white " />
            </span>
          )}
        </div>

        {/* categoria */}
        <div className="flex flex-col">
          <span className="text-xs">Categoria: </span>
          {product.categoria && product?.categoria?.id ? (
            <Link
              onClick={() => setOrigem(origem ?? EnumOrigens.PRODUTO)}
              className="underline text-xs"
              to={
                '/produtos/?categoria=' +
                product.categoria.id +
                '&take=' +
                getTake() +
                '&skip=0'
              }
            >
              {product?.categoria?.descricao}
            </Link>
          ) : (
            'N/D'
          )}
        </div>

        {/* preco */}
        <div className="flex justify-center">
          {product.isPromocao ? (
            <div className="relative top-0 flex flex-col my-1 min-w-[9vw]">
              <div className="flex flex-col items-end">
                <span className="flex space-x-2 md:text-sm lg:text-md line-through text-gray-400">
                  {/* <span>{parseCurrency(product.preco)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={product}
                    field="preco"
                    fieldConsumidorFinal="precoConsumidorFinal"
                  />
                </span>
                <span className="flex space-x-2 text-md md:text-md lg:text-2xl text-amber-500 font-bold">
                  {/* <span>{parseCurrency(product.precoPromo)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={product}
                    field="precoPromo"
                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                  />
                </span>
              </div>
              {product.promocao && (
                <div className="flex justify-center">
                  <Progress
                    qtdeTotal={product.promocao.qtdeEmPromocao}
                    qtdeMovimentada={product.promocao.qtdeMovimentada}
                    qtdeDisponivel={product.promocao.qtdeDisponivel}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center my-0 py-2">
              <div className="flex my-3 justify-end">
                <span className="flex space-x-2 font-bold text-xl">
                  {/* <span>{parseCurrency(product.precoPromo)}</span> */}
                  <ExibirPrecoConsumidorFinal
                    item={product}
                    field="precoPromo"
                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                  />
                </span>
              </div>
            </div>
          )}
        </div>

        {/* botao */}
        <div className="flex justify-end w-full">
          <div className="flex flex-col items-center justify-center w-full">
            <div className=" my-2">
              {/* <Button title="Comprar" onClick={buy} full="" /> */}
              <ButtonComprar produto={product} action={buy} />
            </div>
            <div className=" text-center">
              <button
                className="text-primary1 text-xs mx-auto hover:underline"
                onClick={() => detail(product.id)}
              >
                Mais Detalhes
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={product}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </>
  );
};

export default DisplayProductList;
