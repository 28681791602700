/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Auth,
  Categoria,
  ComprimentosUnicos,
  Marca,
  Modelo,
  ReferenciaPorModelo,
  Usuario,
  VeiculoTipos,
  Search,
  SearchReferenciaRelacionadosModelo,
  Produto,
  MarcaComTipo,
  CondicoesPgto,
  FormasPgto,
  Transportadora,
  TabelaPreco,
  IBanner,
  VeiculosByProduct,
  IFabricante,
  Eixos,
  Vendedor,
  Empresa,
} from './app.models';
import {
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_RJWT,
  LOCAL_STORAGE_PREFIX,
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_DEFAUT_CLI,
} from './app.settings';

import api from './axios';
import history from './history';
import { EncrDecrService } from './utils/enc-dec';
import {
  convertImages,
  convertOneImage,
  filterImageSize,
} from './utils/functions';

const crypto = new EncrDecrService();

export async function getApiVersion(): Promise<Record<string, string>> {
  const url = '/version';
  const data = (await api.get(url)).data;
  return data;
}

export async function login(email: string, senha: string): Promise<void> {
  const url = '/auth/login';
  try {
    const auth = (
      await api.post<Auth>(url, {
        email,
        senha,
      })
    ).data;
    if (!auth.usuario.cliente.id) {
      throw new Error('Error ao carregar o usuário');
    }
    const cliente = auth.usuario.cliente;

    if (auth.usuario.isAtivo) {
      const defaultCli = {
        clienteId: cliente.id,
        tabelaId: cliente.tabelaId,
      };
      localStorage.setItem(LOCAL_STORAGE_JWT, auth.accessToken);
      localStorage.setItem(LOCAL_STORAGE_RJWT, auth.refreshToken);
      setCryptoLocalStorage(LOCAL_STORAGE_USER, auth.usuario);
      setCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI, defaultCli);
    } else {
      throw new Error('Usuário inativo');
    }
  } catch (e: any) {
    localStorage.removeItem(LOCAL_STORAGE_JWT);
    localStorage.removeItem(LOCAL_STORAGE_RJWT);
    localStorage.removeItem(LOCAL_STORAGE_USER);
    localStorage.removeItem(LOCAL_STORAGE_DEFAUT_CLI);
    if (e.response?.data?.message) throw new Error(e.response.data.message);
    throw new Error('Erro ao fazer login');
  }
}

export function logoff(): void {
  localStorage.removeItem(LOCAL_STORAGE_JWT);
  localStorage.removeItem(LOCAL_STORAGE_RJWT);
  localStorage.removeItem(LOCAL_STORAGE_USER);
  localStorage.removeItem(LOCAL_STORAGE_DEFAUT_CLI);
  history.push('/');
  window.location.reload();
}

export function getAuthUser(): Usuario | null {
  const dataLocalStorage = getCryptoLocalStorage(LOCAL_STORAGE_USER);
  if (dataLocalStorage) {
    return dataLocalStorage as unknown as Usuario;
  } else return null;
}

export function validateToken(): void {
  const url = '/auth/validateToken';
  api.get(url);
}

export async function getEmpresa(): Promise<Empresa> {
  const url = '/empresa';
  const empresa = (await api.get(url)).data;
  return empresa[0];
}

export async function getEmpresaSemToken(): Promise<Empresa> {
  const url = '/empresa/1';
  const empresa = (await api.get(url)).data;
  return empresa;
}

export async function getCategorias(): Promise<Categoria[]> {
  const url = '/categorias';
  return (await api.get(url)).data.map((el: any) => ({
    ...el,
    images:
      el.images.length > 0
        ? convertOneImage(el.images, el.id, 'categorias')
        : [
          {
            img: '/no-image-180x135.png',
          },
        ],
  }));
}

export async function getModelos(): Promise<Modelo[]> {
  const url = '/modelos';
  return (await api.get(url)).data.map((el: any) => ({
    ...el,
    images:
      el.images.length > 0
        ? convertOneImage(el.images, el.id, 'modelos')
        : [
          {
            // img: 'https://via.placeholder.com/100?text=Sem Imagem',
            img: '/no-image-180x135.png',
          },
        ],
  }));
}

export async function getVeiculoTipos(): Promise<VeiculoTipos[]> {
  const url = '/veiculotipos';
  return (await api.get(url)).data.map((el: any) => ({
    ...el,
    images:
      el.images.length > 0
        ? convertOneImage(el.images, el.id, 'veiculotipos')
        : [
          {
            img: 'https://via.placeholder.com/100?text=Sem Imagem',
          },
        ],
  }));
}

export async function getComprimentosUnicos(): Promise<ComprimentosUnicos[]> {
  const url = '/comprimentosunicos';
  return (await api.get(url)).data;
}

export async function getTabelaPrecos(): Promise<TabelaPreco[]> {
  const url = '/tabelaprecos';
  return (await api.get<TabelaPreco[]>(url)).data;
}

export async function getMarcas(): Promise<Marca[]> {
  const url = '/marcas';
  return (await api.get(url)).data.map((el: any) => ({
    ...el,
    images:
      el.images.length > 0
        ? convertOneImage(el.images, el.id, 'marcas')
        : [
          {
            img: 'https://via.placeholder.com/100?text=Sem Imagem',
          },
        ],
  }));
}

export async function getEixos(): Promise<Eixos[]> {
  const url = '/eixos';
  return (await api.get(url)).data;
}

export async function getMarcasComTipo(): Promise<MarcaComTipo[]> {
  const url = '/marcas/comtipoveiculo';
  return (await api.get(url)).data;
}

export async function getReferenciasPorModelo(
  modelo: number
): Promise<ReferenciaPorModelo[]> {
  const url = '/marcasmodelos/referenciaspormodelo/' + modelo;
  return (await api.get(url)).data;
}

export const productSearchReferencia = async (
  ref: string,
  take = 12,
  skip = 0
): Promise<Search> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = `/produtos/referencia/?clienteId=${clienteId}&tabelaId=${tabelaId}&ref=${ref}&take=${take}&skip=${skip}&sortBy=0&ord=0&viewType=grid&viewCol=25`;
  const data = (await api.get(url)).data;
  return {
    info: data.info,
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const productSearchPorModelo = async (
  modeloId: number,
  take = 999,
  skip = 0,
  filtros = '',
  tags = '',
  origem = ''
): Promise<Search> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = `/produtos/veiculo/${modeloId}/??clienteId=${clienteId}&tabelaId=${tabelaId}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&origem=${origem}`;
  const urlTags = `/produtos/tags/filtros/?veic=${modeloId}&filtros=${filtros}&tags=${tags}`;
  const data = (await api.get(url)).data;
  const filterTags = (await api.get(urlTags)).data;
  return {
    info: data.info,
    tags: filterTags.tags || [],
    filtros: filterTags.filtros || [],
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const productSearchReferenciaRelacionadosModelo = async (
  modeloId: number
): Promise<SearchReferenciaRelacionadosModelo[]> => {
  const url =
    `/marcasmodelos/referenciasrelacionadospormodelo/` +
    modeloId +
    '/?take=999&skip=0';
  return (await api.get<SearchReferenciaRelacionadosModelo[]>(url)).data;
};

export const productSearchFor = async (
  text: string,
  take: number,
  skip: number,
  filtros = '',
  tags = '',
  origem = ''
  // categoria: string,
): Promise<Search> => {
  const newText = encodeURIComponent(text);
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  // const url = `/produtos/?tabelaId=${tabelaId}&searchFor=${text}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&sortBy=0&ord=0&viewType=grid&viewCol=25`;
  const url = `/produtos/?clienteId=${clienteId}&tabelaId=${tabelaId}&searchFor=${newText}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&origem=${origem}`;
  const data = (await api.get(url)).data;
  const urlTags = `/produtos/tags/filtros/?searchFor=${newText}&filtros=${filtros}&tags=${tags}`;
  const filterTags = (await api.get(urlTags)).data;
  return {
    info: data.info,
    tags: filterTags.tags || [],
    filtros: filterTags.filtros || [],
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const productSearchAll = async (
  take: number,
  skip: number,
  filtros = '',
  tags = '',
  origem = ''
  // categoria: string,
): Promise<Search> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  // const url = `/produtos/?tabelaId=${tabelaId}&searchFor=${text}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&sortBy=0&ord=0&viewType=grid&viewCol=25`;
  const url = `/produtos/?clienteId=${clienteId}&tabelaId=${tabelaId}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&origem=${origem}`;
  const data = (await api.get(url)).data;
  const urlTags = `/produtos/tags/filtros/?filtros=${filtros}&tags=${tags}`;
  const filterTags = (await api.get(urlTags)).data;

  return {
    info: data.info,
    tags: filterTags.tags || [],
    filtros: filterTags.filtros || [],
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const productPedidoSearchFor = async (
  text: string,
  // categoria: string,
): Promise<Search> => {
  const newText = encodeURIComponent(text);
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  // const url = `/produtos/?tabelaId=${tabelaId}&searchFor=${text}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&sortBy=0&ord=0&viewType=grid&viewCol=25`;
  const url = `/produtos/?clienteId=${clienteId}&tabelaId=${tabelaId}&searchFor=${newText}&take=9999&skip=0&filtros=&tags=&origem=search_for`;
  const data = (await api.get(url)).data;

  return {
    info: data.info,
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export async function getProduct(
  productId: number | string,
  origem?: string | undefined,
): Promise<{ produto: Produto; relacionados: Produto[] }> {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clineteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = '/produtos/' + productId;
  const prod = (await api.get(url + '/?clienteId=' + clineteId + '&tabelaId=' + tabelaId + '&origem=' + (origem ?? ''))).data.produto;
  const rel = (await api.get(url + '/relacionados' + '/?clienteId=' + clineteId + '&tabelaId=' + tabelaId + '&origem=' + origem))
    .data.relacionados;
  return {
    produto: {
      ...prod,
      images:
        prod.images.length > 0
          ? convertImages(prod.images, prod.id, 'produtos')
          : [
            {
              small: 'no-image-100x100.png',
              medium: 'no-image-180x135.png',
              big: 'no-image-180x135.png',
            },
            // {
            //   small: 'https://via.placeholder.com/100?text=Sem Imagem',
            //   medium: 'https://via.placeholder.com/300?text=Sem Imagem',
            //   big: 'https://via.placeholder.com/600?text=Sem Imagem',
            // },
          ],
    },
    relacionados: rel.map((r: any) => ({
      ...r,
      images:
        r.images.length > 0
          ? convertImages(r.images, r.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
}

export async function getProductIdFabricante(
  produtoId: number
): Promise<IFabricante[]> {
  const url = '/produto_idfabricante/produto/' + produtoId;
  return (await api.get(url)).data;
}

export async function getVeiculosByProduct(
  productId: number
): Promise<VeiculosByProduct[]> {
  const url = '/produtos/' + productId + '/veiculos';
  return (await api.get(url)).data;
}

export const productSearchCateg = async (
  categId: number,
  take: number,
  skip: number,
  filtros = '',
  tags = '',
  origem = ''
): Promise<Search> => {
  const tabelaId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId;
  const clienteId = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId;
  const url = `/produtos/categoria/${categId}/?clienteId=${clienteId}&tabelaId=${tabelaId}&take=${take}&skip=${skip}&filtros=${filtros}&tags=${tags}&origem=${origem}`;
  const data = (await api.get(url)).data;
  const urlTags = `/produtos/tags/filtros/?categ=${categId}&filtros=${filtros}&tags=${tags}`;
  const filterTags = (await api.get(urlTags)).data;
  return {
    info: data.info,
    tags: filterTags.tags || [],
    filtros: filterTags.filtros || [],
    produtos: data.produtos.map((p: any) => ({
      ...p,
      images:
        p.images.length > 0
          ? convertImages(p.images, p.id, 'produtos')
          : [
            {
              small: 'https://via.placeholder.com/100?text=Sem Imagem',
              medium: 'https://via.placeholder.com/300?text=Sem Imagem',
              big: 'https://via.placeholder.com/600?text=Sem Imagem',
            },
          ],
    })),
  };
};

export const condicoesPgto = async (): Promise<CondicoesPgto[]> => {
  return (await api.get<CondicoesPgto[]>('/cpagto')).data;
};

export const formasPgto = async (): Promise<FormasPgto[]> => {
  return (await api.get<FormasPgto[]>('/fpagto')).data;
};

export const getVendedores = async (): Promise<Vendedor[]> => {
  return (await api.get<Vendedor[]>('/vendedores')).data;
};

export const transportadorasFrete = async (): Promise<Transportadora[]> => {
  return (await api.get<Transportadora[]>('/transportadoras')).data;
};

export const genericGet = async (path: string): Promise<any[]> => {
  return (await api.get(path)).data.map((el: any) => ({
    ...el,
    images:
      el.images.length > 0
        ? convertOneImage(el.images, el.id, path)
        : [
          {
            img: '/no-image-180x135.png',
          },
        ],
  }));
};

export const genericDel = async (path: string): Promise<void> => {
  await api.delete(path);
};

// upload *****************

export async function getBanners(): Promise<IBanner[]> {
  const res = await api.get<IBanner[]>('/banners');
  const formated = res.data.map((b: any) => ({
    ...b,
    images: {
      small: filterImageSize(b.images, 'small'),
      medium: filterImageSize(b.images, 'medium'),
    },
  }));
  return formated;
}

export async function delBanner(id: string | number): Promise<void> {
  await api.delete<IBanner>('/banners/' + id);
}

export async function saveBanner(data: IBanner): Promise<IBanner> {
  // ts-ignore
  delete (data as any)?.images;
  if (data.id) {
    return (await api.put<IBanner>('/banners/' + data.id, data)).data;
  } else {
    delete data.id;
    return (await api.post<IBanner>('/banners', data)).data;
  }
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export function upload(
  url: string,
  image: Blob,
  desc: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> {
  const formData = new FormData();
  const imgUp = blobToFile(image, 'upload.jpg');
  formData.append('file', imgUp);
  desc && formData.append('desc', desc);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

export function setFormLocalStorage(
  formIdentification: string,
  value: string
): void {
  localStorage.setItem(LOCAL_STORAGE_PREFIX + formIdentification, value);
}

export function getFormLocalStorage(formIdentification: string): string | null {
  return localStorage.getItem(LOCAL_STORAGE_PREFIX + formIdentification);
}

export function setCryptoLocalStorage(
  key: string,
  value: Record<string, any>
): void {
  if (!value) return;
  const cryptoValue = crypto.set(JSON.stringify(value));
  localStorage.setItem(key, cryptoValue);
}

export function getCryptoLocalStorage(key: string): Record<string, any> | null {
  try {
    const cryptoValue = localStorage.getItem(key);
    if (cryptoValue) {
      const jsonValue = JSON.parse(crypto.get(cryptoValue));
      return jsonValue;
    } else {
      return null;
    }
  } catch (error) {
    localStorage.removeItem(key);
    // self.location.reload();
    return null;
  }
}
