import { BanIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/layout/button';
import { ROUTES_MAP } from '../../app.map.routes';
import { ReactComponent as WhatsappLogo } from '../../assets/svg/whatsapp.svg';
import { PrintOrderBase64 } from '../../components/pdf';
import { useApp } from '../../app.hook';
import ModalConfirmationNumberWhatsapp from '../../components/modalConfirmationNumberWhatsapp';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { getPedido } from '../myOrders/pedido.service';
import { sendZapiDocument } from '../../zapi.service';
import { toast } from 'react-toastify';

export default function OrderSuccess() {
  const location = useLocation();
  const navigate = useNavigate();

  const { currentUser, empresa } = useApp();

  const [modalIsOpenWhatsapp, setModalIsOpenWhatsapp] =
    useState<boolean>(false);

  const [data, setData] = useState<any>({});

  const [icon, setIcon] = useState<JSX.Element | undefined>();

  useEffect(() => {
    const state = location.state as any;
    if (!state) {
      setIcon(<BanIcon className="w-52 h-52 text-red-500" />);
      setData({ ok: false, movimento: 'Nenhum dado recebido!' });
    } else {
      setIcon(<CheckCircleIcon className="w-52 h-52 text-green-500" />);
      setData({ ...state.newOrder });
    }
  }, [location, navigate]);

  const handleWhatsapp = async (number: string) => {
    if (!data.pedido) return;
    const pedido = await getPedido(data.pedido);
    const base64 = await PrintOrderBase64(pedido, empresa.empresaLogoCompleto);
    const result = await sendZapiDocument({
      document: `data:application/pdf;base64,${base64}`,
      phone: number,
      extension: 'pdf',
      fileName: `Pedido-${data.movimento}`,
    });
    if (result) {
      toast.success('Pedido enviado com sucesso!');
    } else {
      toast.error('Erro ao enviar pedido!');
    }
  };

  if (data.ok === false) {
    return (
      <div className="mt-32 md:mt-0 md:pt-20 mx-2 flex flex-col space-y-3 items-center ">
        {icon}
        <div className="text-gray-700 text-3xl text-center">
          {data.movimento}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-32 md:mt-0 md:pt-20 mx-2 flex flex-col space-y-3 items-center ">
      {icon}
      <div className="text-gray-700 text-3xl text-center">
        Seu pedido foi enviado com sucesso!
      </div>
      <div className="text-gray-700 text-3xl text-center">
        Nº do pedido: {data.movimento}
      </div>
      <div className="text-gray-700 text-center grid grid-cols-2 gap-3">
        <Button
          title="Continuar comprando"
          icon={
            <ShoppingCartIcon className="h-9 w-9 text-white hover:text-gray-100" />
          }
          color="bg-primary1 text-white hover:bg-gray-400 hover:text-gray-100"
          onClick={() => navigate(ROUTES_MAP.promotions)}
        />

        {/* <Button
          title="Ir para meus pedidos"
          icon={
            <ClipboardListIcon className="h-9 w-9 text-white hover:text-gray-100" />
          }
          color="bg-gray-500 text-white hover:bg-gray-400 hover:text-gray-100"
          onClick={() => navigate(ROUTES_MAP.myOrders)}
        /> */}

        <Button
          title="Enviar para meu Whatsapp"
          color="bg-green-400 text-white hover:bg-gray-100 hover:text-green-400"
          full="true"
          icon={<WhatsappLogo />}
          onClick={() => setModalIsOpenWhatsapp(true)}
        />
      </div>
      <ModalConfirmationNumberWhatsapp
        isOpen={modalIsOpenWhatsapp}
        setIsOpen={setModalIsOpenWhatsapp}
        action={handleWhatsapp}
        currentUser={currentUser}
      />
    </div>
  );
}
