import React from 'react';
import { useApp } from '../app.hook';
import clsx from 'clsx';
import { ChevronUpIcon } from '@heroicons/react/outline';
import MultiSearch from './MultiSearch';
import MultiSearchSimple from './MultiSearchSimple';
import SearchCategoria from './SearchCategoria';

interface Props {
  children: React.ReactNode;
  className?: string | string[];
  classNameWraper?: string | string[];
  hideSearch?: boolean;
}

export default function TabSearchWraper({
  children,
  className = '',
  classNameWraper = '',
  hideSearch = false,
}: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { empresa, isXs } = useApp();
  const [position, setPosition] = React.useState<number>(0);
  const [showCaption, setShowCaption] = React.useState<boolean>(false);

  const showPesquisaVeiculo = empresa?.exibirPesquisaVeiculos || false;
  const showPesquisaCategoria = empresa?.exibirPesquisaCategorias || false;

  // scrool position to top
  function currentPosition() {
    const p = ref.current?.scrollTop;
    setPosition(p || 0);
  }

  function goToTop() {
    ref.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  return (
    <div
      ref={ref}
      onScroll={currentPosition}
      className={
        className &&
        clsx(
          'space-y-4 w-full',
          ...(Array.isArray(className) ? className : [className])
        )
      }
    >
      {/* {(!pesquisasTopBar || hideSearch) && <MultiSearch />} */}
      {!showPesquisaCategoria && !showPesquisaVeiculo ? (
        <SearchCategoria />
      ) : hideSearch ? null : (
        // <MultiSearch />
        <MultiSearchSimple />
      )}
      {/* {(!pesquisasTopBar || hideSearch) && <TabSerach />} */}
      <div
        className={
          classNameWraper &&
          clsx(
            ...(Array.isArray(classNameWraper)
              ? classNameWraper
              : [classNameWraper])
          )
        }
      >
        {children}
      </div>
      {/* go to top */}

      {position > 180 && (
        <div
          onClick={goToTop}
          onMouseEnter={() => setShowCaption(true)}
          onMouseLeave={() => setShowCaption(false)}
          className="fixed bottom-4 right-4 py-3 flex justify-end items-center gap-2 cursor-pointer group"
        >
          {showCaption && (
            <span className="text-xs font-bold opacity-90">Subir</span>
          )}
          <button
            className={clsx(
              'bg-primary1 text-white rounded-full w-8 h-8 flex items-center justify-center',
              'group-hover:bg-primary3 group-hover:scale-110 transition-all duration-500'
            )}
          >
            <ChevronUpIcon className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
}
