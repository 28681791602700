/* eslint-disable prettier/prettier */
import api from '../../axios';
import { Cart, Cliente, Comprador, Info, NewCartProduto, ProdutoCart } from '../../app.models';
import { toast } from 'react-toastify';
import { convertImages } from '../../utils/functions';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import { getCryptoLocalStorage } from '../../app.service';

type GetCardsParams = {
  searchFor?: string;
  situacao?: string;
  compradorId?: number;
  dataI?: Date;
  dataF?: Date;
  take?: number;
  skip?: number;
  sortBy?: string;
  ord?: number;
};

type SearchCarts = {
  carts: Cart[];
  info: {
    pesquisaPor: string;
    qtdeTotal: number;
  };
};

type AprovarReprovar = {
  clienteId: number;
  tabelaPrecosId?: number;
  id: number;
  situacaoCarrinho: number;
  updated_at: Date;
  usuarioId: string;
};

type SearchCliente = {
  info: Info;
  clientes: Cliente[];
};

export async function getCarts(
  params: GetCardsParams,
  apenasAprovados = false
): Promise<SearchCarts> {
  const urlBase = '/carrinhos/?';
  const urlWithParams = [urlBase];

  urlWithParams[0] = urlWithParams[0].concat(`searchFor=${params.searchFor}`);

  if (params.dataI)
    urlWithParams[0] = urlWithParams[0].concat(`&dataI=${params.dataI}`);

  if (params.dataF)
    urlWithParams[0] = urlWithParams[0].concat(`&dataF=${params.dataF}`);

  if (apenasAprovados === false) {
    if (params.situacao)
      urlWithParams[0] = urlWithParams[0].concat(
        `&situacao=${params.situacao}`
      );
    if (params.compradorId)
      urlWithParams[0] = urlWithParams[0].concat(
        `&comprador=${params.compradorId}`
      );
    if (params.take)
      urlWithParams[0] = urlWithParams[0].concat(`&take=${params.take}`);
  } else {
    urlWithParams[0] = urlWithParams[0].concat(`&situacao=1`);
    urlWithParams[0].concat(`&take=${-1}`);
  }

  if (params.skip)
    urlWithParams[0] = urlWithParams[0].concat(`&skip=${params.skip}`);

  if (params.sortBy)
    urlWithParams[0] = urlWithParams[0].concat(`&sortBy=${params.sortBy}`);

  if (params.ord)
    urlWithParams[0] = urlWithParams[0].concat(`&ord=${params.ord}`);

  const { info, carts } = (await api.get(urlWithParams[0])).data;
  return { info, carts };
  // return {
  //   info: data.info,
  //   carts: data.carts.map((c: Cart) => ({
  //     ...c,
  //     produtos: c.produtos.map((p) => ({
  //       ...p,
  //       produto: {
  //         ...p.produto,
  //         images:
  //           p.produto.images.length > 0
  //             ? convertImages(p.produto.images, p.produto.id, 'produtos')
  //             : [
  //                 {
  //                   small: 'https://via.placeholder.com/100?text=Sem Imagem',
  //                   medium: 'https://via.placeholder.com/300?text=Sem Imagem',
  //                   big: 'https://via.placeholder.com/600?text=Sem Imagem',
  //                 },
  //               ],
  //       },
  //     })),
  //   })),
  // };
}

export async function getCart(cartId: number): Promise<Cart> {
  const url = '/carrinhos/' + cartId;
  const data = (await api.get(url)).data;
  // console.log('card data', data);
  return {
    ...data,
    produtos: data.produtos.map((pc: ProdutoCart) => ({
      ...pc,
      produto: {
        ...pc.produto,
        images:
          pc.produto.images.length > 0
            ? convertImages(pc.produto.images, pc.produto.id, 'produtos')
            : [
              {
                small: 'https://via.placeholder.com/100?text=Sem Imagem',
                medium: 'https://via.placeholder.com/300?text=Sem Imagem',
                big: 'https://via.placeholder.com/600?text=Sem Imagem',
              },
            ],
      },
    })),
  };
}

export async function clearCart(
  cartId: number,
  updated_at: Date
): Promise<Cart> {
  const carrinho = {
    id: cartId,
    updated_at: updated_at,
  };
  const url = '/carrinhos/' + cartId + '/limpar';
  return (await api.post(url, { carrinho })).data;
}

export async function newCart(produto?: NewCartProduto): Promise<Cart> {
  if (!produto) {
    const url = '/carrinhos';
    return api
      .post(url)
      .then((data) => data.data as unknown as Cart)
      .catch(() => {
        toast.error('Erro ao inserir carrinho');
        return {} as Cart;
      });
  }

  const url = '/carrinhos/add';
  return api
    .post(url, { produto })
    .then((data) => data.data as unknown as Cart)
    .catch(() => {
      toast.error('Erro ao inserir carrinho');
      return {} as Cart;
    });
}

export async function updateCart(cartId: number, data: Cart): Promise<Cart> {
  const url = '/carrinhos/' + cartId;
  return api
    .put(url, data)
    .then((dt) => {
      const data = dt.data;
      return {
        ...data,
        produtos: data.produtos.map((pc: ProdutoCart) => ({
          ...pc,
          produto: {
            ...pc.produto,
            images:
              pc.produto.images.length > 0
                ? convertImages(pc.produto.images, pc.produto.id, 'produtos')
                : [
                  {
                    small: 'https://via.placeholder.com/100?text=Sem Imagem',
                    medium: 'https://via.placeholder.com/300?text=Sem Imagem',
                    big: 'https://via.placeholder.com/600?text=Sem Imagem',
                  },
                ],
          },
        })),
      };
    })
    .catch((e) => {
      if (e.response) {
        throw new Error(
          'Error ao alterar carrinho, tente novamente, se o problema persistir entre em contato com o suporte.'
        );
      }
    });
}

export async function addItemCart(
  carrinhoId: number,
  produtoId: number,
  qtde: number,
  updated_at: Date
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Cart> {
  const url = `/carrinhos/${carrinhoId}/produto`;
  const carrinhoItem = {
    carrinhoId,
    produtoId,
    qtde,
    updated_at,
  };
  return api
    .post(url, { carrinhoItem })
    .then((dt) => {
      const data = dt.data;
      return {
        ...data,
        produtos: data.produtos.map((pc: ProdutoCart) => ({
          ...pc,
          produto: {
            ...pc.produto,
            images:
              pc.produto.images.length > 0
                ? convertImages(pc.produto.images, pc.produto.id, 'produtos')
                : [
                  {
                    small: 'https://via.placeholder.com/100?text=Sem Imagem',
                    medium: 'https://via.placeholder.com/300?text=Sem Imagem',
                    big: 'https://via.placeholder.com/600?text=Sem Imagem',
                  },
                ],
          },
        })),
      };
    })
    .catch((e) => {
      if (e.response) {
        toast.error(
          'Seu carrinho foi alterado por outro usuário, tente novamente, se o problema persistir entre em contato com o suporte.'
        );
      }
    });
}

export async function delItemCart(
  carrinhoId: number,
  carrinhoItemId: number,
  updated_at: Date
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Cart> {
  const url = `/carrinhos/${carrinhoId}/produto/${carrinhoItemId}`;
  const carrinhoItem = {
    carrinhoId,
    carrinhoItemId,
    qtde: 1,
    updated_at,
  };
  return api
    .post(url, { carrinhoItem })
    .then((dt) => {
      const data = dt.data;
      return {
        ...data,
        produtos: data.produtos.map((pc: ProdutoCart) => ({
          ...pc,
          produto: {
            ...pc.produto,
            images:
              pc.produto.images.length > 0
                ? convertImages(pc.produto.images, pc.produto.id, 'produtos')
                : [
                  {
                    small: 'https://via.placeholder.com/100?text=Sem Imagem',
                    medium: 'https://via.placeholder.com/300?text=Sem Imagem',
                    big: 'https://via.placeholder.com/600?text=Sem Imagem',
                  },
                ],
          },
        })),
      };
    })
    .catch((e) => {
      if (e.response) {
        toast.error(
          'Seu carrinho foi alterado por outro usuário, tente novamente, se o problema persistir entre em contato com o suporte.'
        );
      }
    });
}

export async function aprovarReprovarCart(
  carrinho: AprovarReprovar
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<boolean> {
  const url = `/carrinhos/aprovar/${carrinho.id}`;
  return api
    .post(url, {
      carrinho: { ...carrinho },
    })
    .then((data) => data.data)
    .catch((e) => {
      console.log(e);
      toast.error('Erro ao atualizar carrinho');
    });
}

// comprador ***************************************
export const getCompradores = async (
  searchFor: string
): Promise<Comprador[]> => {
  const url = `/compradores/?searchFor=${searchFor}`;
  return (await api.get(url)).data;
};

export const addComprador = async (
  comprador: Comprador
): Promise<Comprador> => {
  const url = `/compradores`;
  return (await api.post(url, { comprador })).data;
};

export const updComprador = async (
  comprador: Comprador
): Promise<Comprador> => {
  const url = `/compradores/${comprador.id}`;
  return (await api.put(url, { comprador })).data;
};

// cliente ***************************************
export const getClientes = async (
  searchFor: string,
  take = 10,
  skip = 0
): Promise<SearchCliente> => {
  const url = `/clientes/?searchFor=${searchFor}&take=${take}&skip=${skip}`;
  return (await api.get(url)).data;
};

export const getClienteById = async (id: number | string): Promise<Cliente> => {
  const url = `/clientes/${id}`;
  return (await api.get(url)).data;
};

export const updCarrinhoCliente = async (carrinho: {
  id: number;
  usuarioId: string;
  updated_at: Date;
}): Promise<Cart> => {
  if (!carrinho.id) {
    return {} as Cart;
  }

  const url = `/carrinhos/${carrinho.id}`;
  const res = await api.put(url, {
    carrinho: {
      id: carrinho.id,
      cliente: getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.clienteId,
      tabelaPrecos: getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI)?.tabelaId,
      updated_at: carrinho.updated_at,
    },
  })
    .catch((e) => {
      if (e.response) {
        toast.error(
          'Seu carrinho foi alterado por outro usuário, tente novamente, se o problema persistir entre em contato com o suporte.'
        );
      }
    });

  return res?.data as Cart;

};

// export const updCarrinhoTabela = async (carrinho: {
//   id: number;
//   tabelaPrecosId: number;
//   usuarioId: string;
//   updated_at: Date;
// }): Promise<void> => {
//   const url = `/carrinhos/${carrinho.id}`;
//   await api.put(url, {
//     carrinho: {
//       id: carrinho.id,
//       tabelaPrecos: carrinho.tabelaPrecosId,
//       updated_at: carrinho.updated_at,
//       usuario: carrinho.usuarioId,
//     },
//   });
// };
