/* eslint-disable prettier/prettier */
import { TableCell } from 'pdfmake/interfaces';
import { ProdutoPedido } from '../../app.models';
import { parseCurrency } from '../../utils/numberHandler';
import { getBase64ImageFromURL } from '../../utils/functions';

interface Props {
  items: ProdutoPedido[];
}

interface PDFImage {
  id: number;
  image: string;
  width: number;
  height: number;
}

async function getImages(items: any): Promise<PDFImage[]> {
  const images: PDFImage[] = [];
  for await (const item of items) {
    if (item.produto?.images?.length > 0) {
      images.push({
        id: item.produto.id,
        image: await getBase64ImageFromURL(item.produto.images[0].small) as string,
        width: 50,
        height: 50,
      });
    }
  }
  return images;
}

export const tableWidths = [
  20, // numero
  50, // image
  '*', // produto e descricao
  50, // quantidade
  70, // preço unitário
  70, // valor total
];

export default async function GetTableProdutosOrderImageDD({ items = [] }: Props): Promise<TableCell[][]> {
  const body: TableCell[][] = [];
  const images = await getImages(items);
  console.log("🚀 ~ GetTableProdutosOrderImageDD ~ images:", images)

  body.push([
    [{ text: 'Item', alignment: 'center', fontSize: 8 }], // numero
    [{ text: 'Image', alignment: 'center', fontSize: 8 }], // image
    [{ text: 'Descrição', alignment: 'left', fontSize: 8 }], // produto e descricao
    [{ text: 'Quantidade', alignment: 'center', fontSize: 8 }], // quantidade
    [{ text: 'Preço Unitário', alignment: 'right', fontSize: 8 }], // preço unitário
    [{ text: 'Total', alignment: 'right', fontSize: 8 }], // valor total
  ]);

  items.forEach((item, index: number) => {
    body.push([
      // numero
      [{ text: index + 1, alignment: 'center' }],
      // image
      // [{ text: 'Image', alignment: 'center', fontSize: 8 }],
      [{ image: images.find((i) => i.id === item.produto.id)?.image, width: 50, height: 40 }],
      // [{ image: logoFullBase64 }],
      // produto e descricao
      [
        {
          stack: [
            { text: item?.produto?.descricaoSite, bold: true, fontSize: 10 },
            {
              text: `Código: ${item?.produto?.codigoSgi ?? ''}`,
              fontSize: 8,
            },
          ],
        },
      ],
      // quantidade
      [{ text: item.qtde, alignment: 'center' }],
      // preço unitário
      item.isPromocao
        ? item.produto?.promocao
          ? [
            {
              stack: [
                {
                  text: `de ${parseCurrency(item?.produto?.precoPromo)}`,
                  alignment: 'right',
                },
                {
                  text: `por ${parseCurrency(item?.unitarioLiquido)}`,
                  alignment: 'right',
                },
              ],
            },
          ]
          : [{ text: ':( Saiu de oferta', alignment: 'right' }]
        : [
          {
            text: parseCurrency(item?.unitarioLiquido ?? 0),
            alignment: 'right',
          },
        ],
      // valor total
      [{ text: parseCurrency(item.totalItem ?? 0), alignment: 'right' }],
    ]);
  });
  return body;
}
