import { ReactNode, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProduct } from './product.hook';
import ProductsCategoriasFilter from './productsCategoriasFilter';
import ProductsMarcaModeloFilter from './productsMarcaModeloFilter';
import ProductsPromocoesFilter from './productsPromocoesFilter';
import ProductsTextFilter from './productsTextFilter';
import ProductsAll from './productsAll';
import { useApp } from '../../app.hook';
import MenuMultibusca from '../../components/layout/menuMultibusca';

interface WraperProductProps {
  children: ReactNode;
}
const WraperProduct = (props: WraperProductProps) => {
  return (
    <div className="flex flex-col">
      <div className="block md:hidden bg-gray-100 py-4 text-gray-700 mt-40 md:mt-0">
        <MenuMultibusca />
      </div>
      {props.children}
    </div>
  );
};

export default function Product() {
  const { empresa } = useApp();
  // const isSmall = useMedia({ maxWidth: 480 });
  const {
    filterType,
    setFilterType,
    setSelectedMarca,
    marcas,
    filterModeloMarca,
    setSelectedModelo,
    setSelectedCategoria,
    setSearchedText,
    setSearchModelo,
    setSelectedSearchedModelo,
    setTake,
    setSkip,
    setFilterText,
    setTagsText,
    take,
    skip,
  } = useProduct();
  const [searchParams, setSearchParams] = useSearchParams();

  const setDefaultTakeSkip = useCallback(() => {
    const params = searchParams;
    if (!searchParams.has('take')) params.set('take', String(take));
    if (!searchParams.has('skip')) params.set('skip', String(skip ?? 0));
    setSearchParams(params);
  }, [searchParams, setSearchParams, skip, take]);

  const currentParams = Object.fromEntries(searchParams);
  useEffect(() => {
    // const keys = Object.keys(currentParams);
    // if (keys.length === 0) {
    //   // pagina default de produtos
    //   if (empresa.exibirPesquisaVeiculos) {
    //     setFilterType('marcamodelo');
    //   } else {
    //     setFilterType('todos');
    //   }
    // }

    if (searchParams.has('take')) {
      const take = parseInt(currentParams.take);
      setTake(take);
    } else {
      setDefaultTakeSkip();
    }

    if (searchParams.has('skip')) {
      const skip = parseInt(currentParams.skip);
      setSkip(skip);
    } else {
      setDefaultTakeSkip();
    }
    // limpesa de filtros e tags
    if (!searchParams.has('marca')) {
      setSelectedMarca(undefined);
    }
    if (!searchParams.has('tipo')) {
      setSelectedModelo(undefined);
    }
    if (!searchParams.has('categoria')) {
      setSelectedCategoria(null);
    }
    if (!searchParams.has('buscaProdutos')) {
      setSearchedText('');
    }
    if (!searchParams.has('modelo')) {
      setSearchModelo('');
    }
    if (!searchParams.has('busca')) {
      setSelectedSearchedModelo(undefined);
    }

    if (!searchParams.has('filtros')) {
      setFilterText('');
    }

    if (!searchParams.has('tags')) {
      setTagsText('');
    }

    // filtros

    if (searchParams.has('filtros')) {
      setFilterText(currentParams.filtros);
    }

    if (searchParams.has('tags')) {
      setTagsText(currentParams.tags);
    }

    //modelo
    // fazer tratamento para buscar por apenas pela marca
    if (empresa.pesquisarSomenteMarca === false) {
      if (
        searchParams.has('busca') ||
        (searchParams.has('busca') && searchParams.has('modelo'))
      ) {
        setFilterType('promocoes');
        const busca = searchParams.get('busca');
        const modelo = searchParams.get('modelo');
        if (busca) setSearchModelo(busca);
        else setSelectedModelo(undefined);
        if (modelo) setSelectedSearchedModelo(parseInt(modelo));
        else setSelectedMarca(undefined);
        return;
      }
    }

    // marca;
    // fazer tratamento para buscar por apenas pela marca ou pela marca e modelo
    if (searchParams.has('marca') && !searchParams.has('tipo')) {
      if (empresa.pesquisarSomenteMarca === false) {
        setFilterType('marcamodelo');
        // console.log('marca');
        const ma = marcas.find((e) => e.id === parseInt(currentParams.marca));
        ma && setSelectedMarca(ma);
        setSelectedModelo(undefined);
        return;
      } else {
        setFilterType('todos');
        // console.log('marca');
        const ma = marcas.find((e) => e.id === parseInt(currentParams.marca));
        ma && setSelectedMarca(ma);
        setSelectedModelo(undefined);
        return;
      }
    }

    //tipo
    // fazer tratamento para buscar por apenas pela marca ou pela marca e modelo
    if (empresa.pesquisarSomenteMarca === false) {
      if (searchParams.has('marca') && searchParams.has('tipo')) {
        const ma = marcas.find((e) => e.id === parseInt(currentParams.marca));
        ma && setSelectedMarca(ma);

        const mo = filterModeloMarca(parseInt(currentParams.marca)).find(
          (e) => e.id === parseInt(currentParams.tipo)
        );
        mo && setSelectedModelo(mo);

        setFilterType('marcamodelo');
        return;
      }
    }

    // categoria
    if (searchParams.has('categoria')) {
      // console.log('categorias');
      setFilterType('categorias');
      if (currentParams.categoria) {
        setSelectedCategoria(parseInt(currentParams.categoria));
      } else {
        setSelectedCategoria(null);
      }
      return;
    }

    // busca por texto
    if (searchParams.has('buscaProdutos')) {
      const search = currentParams.buscaProdutos;
      // console.log('🚀 ~ file: index.tsx:160 ~ useEffect ~ search', search);

      if (search && search.length > 2) {
        setFilterType('text');
        setSearchedText(search);
        return;
      }
    }

    // pagina default de produtos
    setFilterType('todos');
    // if (empresa.exibirPesquisaVeiculos) {
    //   setFilterType('marcamodelo');
    // } else {
    //   setFilterType('todos');
    // }
  }, [
    filterModeloMarca,
    marcas,
    searchParams,
    setFilterType,
    setSearchModelo,
    setSearchedText,
    setSelectedCategoria,
    setSelectedMarca,
    setSelectedModelo,
    setSelectedSearchedModelo,
    setSkip,
    setTake,
    setFilterText,
    setTagsText,
    empresa.pesquisarSomenteMarca,
    empresa.exibirPesquisaVeiculos,
    currentParams,
    setDefaultTakeSkip,
  ]);

  switch (filterType) {
    case 'promocoes':
      return (
        <WraperProduct>
          <ProductsPromocoesFilter />
        </WraperProduct>
      );

    case 'marcamodelo':
      return (
        <WraperProduct>
          <ProductsMarcaModeloFilter />
        </WraperProduct>
      );

    case 'text':
      return (
        <WraperProduct>
          <ProductsTextFilter
            handlerPage={(page) => {
              const params = searchParams;
              params.set('skip', String(page));
              setSearchParams(params);
            }}
          />
        </WraperProduct>
      );

    case 'categorias':
      return (
        <WraperProduct>
          <ProductsCategoriasFilter />
        </WraperProduct>
      );

    default:
      return (
        // <WraperProduct>
        //   <ProductsPromocoesFilter />
        // </WraperProduct>
        <WraperProduct>
          <ProductsAll
            handlerPage={(page) => {
              const params = searchParams;
              params.set('skip', String(page));
              setSearchParams(params);
            }}
          />
          {/* <ProductsMarcaModeloFilter /> */}
        </WraperProduct>
      );
  }
}
