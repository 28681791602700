/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Categoria,
  Cliente,
  DefaultClienteLocalStorage,
  Eixos,
  Empresa,
  // ComprimentosUnicos,
  Marca,
  MarcaComTipo,
  Modelo,
  TabelaPreco,
  Usuario,
  VeiculoTipos,
} from './app.models';
import {
  getCategorias,
  getCryptoLocalStorage,
  // getComprimentosUnicos,
  getMarcas,
  getEixos,
  getMarcasComTipo,
  getModelos,
  getTabelaPrecos,
  getVeiculoTipos,
  setCryptoLocalStorage,
  getEmpresa,
} from './app.service';
import { LOCAL_STORAGE_DEFAUT_CLI, LOCAL_STORAGE_USER } from './app.settings';
import { useMedia } from 'use-media';
import { getCliente } from './pages/myProfile/service';

interface IContext {
  // loads
  init: () => void;
  empresa: Empresa;
  categorias: any[];
  categoriasRaw: any[];
  modelos: Modelo[];
  veiculoTipos: VeiculoTipos[];
  tabelaPrecos: TabelaPreco[];
  // comprimentosUnicos: ComprimentosUnicos[];
  marcas: Marca[];
  eixos: Eixos[];
  marcasComTipo: MarcaComTipo[];
  currentUser: Usuario;
  isPerfilSync: boolean;
  isPerfilAdmin: boolean;
  isPerfilManager: boolean;
  defaultCliente: DefaultClienteLocalStorage | null;
  currentClientAtivo: Cliente | null;
  updateDefaultCli: (cli: number) => void;
  updateDefaultTab: (tab: number) => void;
  tabelaUpdateCount: number;
  setTabelaUpdateCount: () => void;
  pesquisasTopBar: boolean;
  tabSearchCurrentSlider: number;
  tabSearchIndex: number;
  setTabSearchCurrentSlider: (index: number) => void;
  setTabSearchIndex: (index: number) => void;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  is2xl: boolean;
  is3xl: boolean;
  consumidorFinalAtivo: boolean;
  showPrecoConsumidorFinal: boolean;
  toggleShowPrecoConsumidorFinal: () => void;
  innerShowPrecoConsumidorFinal: boolean;
}

interface IProvider {
  children: React.ReactNode;
}

const AppContext = createContext<IContext>({} as IContext);

const AppProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  // tipo de pesquisa na topbar ou nas paginas
  const pesquisasTopBar = false;
  const [tabSearchIndex, setTabSearchIndex] = useState(0);
  const [tabSearchCurrentSlider, setTabSearchCurrentSlider] = useState(0);
  const [empresa, setEmpresa] = useState<Empresa>({} as Empresa);
  const [modelos, setModelos] = useState<Modelo[]>([]);
  // console.log('🚀 ~ file: app.hook.tsx ~ line 48 ~ modelos', modelos);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [categoriasRaw, setCategoriasRaw] = useState<Categoria[]>([]);
  const [veiculoTipos, setVeiculoTipos] = useState<VeiculoTipos[]>([]);

  const [showPrecoConsumidorFinal, setShowPrecoConsumidorFinal] =
    useState(false);
  const [innerShowPrecoConsumidorFinal, setInnerShowPrecoConsumidorFinal] =
    useState(true);

  const toggleShowPrecoConsumidorFinal = () => {
    setInnerShowPrecoConsumidorFinal(!innerShowPrecoConsumidorFinal);
  };
  // const [comprimentosUnicos, setComprimentosUnicos] = useState<
  //   ComprimentosUnicos[]
  // >([]);
  const [tabelaPrecos, setTabelaPrecos] = useState<TabelaPreco[]>([]);
  const [marcas, setMarcas] = useState<Marca[]>([]);
  const [eixos, setEixos] = useState<Eixos[]>([]);
  const [marcasComTipo, setMarcasComTipo] = useState<MarcaComTipo[]>([]);
  const currentUser = (getCryptoLocalStorage(LOCAL_STORAGE_USER) ||
    '') as unknown as Usuario;

  const [tabelaUpdateCount, _setTabelaUpdateCount] = useState<number>(0);
  const setTabelaUpdateCount = () => {
    _setTabelaUpdateCount(tabelaUpdateCount + 1);
  };

  const [defaultCliente, setDefaultCliente] =
    useState<DefaultClienteLocalStorage | null>(null);

  const isPerfilSync = currentUser.perfil === 'SYNC';
  const isPerfilAdmin = currentUser.perfil === 'ADMIN';
  const isPerfilManager = currentUser.perfil === 'MANAGER';

  const [currentClientAtivo, setCurrentClientAtivo] = useState<any>(null);

  const updateDefaultCli = (cli: number) => {
    const defaultCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);
    if (!cli) return;
    setCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI, {
      ...defaultCli,
      clienteId: cli,
    });
    new Promise((r) => setTimeout(r, 1000)).then(() => {
      getDefaultCliente();
    });
  };

  const updateDefaultTab = (tab: number) => {
    const defaultCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);
    setCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI, {
      ...defaultCli,
      tabelaId: tab,
    });
    // self.location.reload();
  };

  const getDefaultCliente = useCallback(async () => {
    const localStorageCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);

    await getCliente(localStorageCli?.clienteId as any).then((cli) => {
      setShowPrecoConsumidorFinal(cli.exibirPrecoConsumidor || false);
      setCurrentClientAtivo(cli);
    });
  }, [setCurrentClientAtivo, setShowPrecoConsumidorFinal]);

  const init = useCallback(async () => {
    const defCli = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);
    setDefaultCliente(defCli as DefaultClienteLocalStorage);
    getMarcas().then((_marcas) => {
      const marcas = _marcas
        .filter((marca) => marca.isVisible)
        .sort((a, b) => a.ordenacao - b.ordenacao);
      setMarcas(marcas);

      getModelos().then((_modelos) => {
        const modelos = _modelos
          .filter((m) => m.isVisible === true)
          .sort((a, b) => a.ordenacao - b.ordenacao)
          .map((d) => {
            const marcaRaw = marcas.filter((m) => m.id === d.marcaId)[0];
            // if (!marcaRaw) return [];
            return {
              ...d,
              marcaRaw,
              modeloDescPesquisa: (
                (marcaRaw?.marcaDesc || '') +
                '|' +
                d.modeloDesc
              )
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') // Remove acentos
                .replace(/([^a-zA-z0-9]+)/g, '') // Substitui espaço e outros caracteres por hífen
                .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
                .replace(/(^-+|-+$)/, '')
                .toLocaleLowerCase(), // Remove hífens extras do final ou do inicio da string,
            };
          })
          .filter((modelo) => modelo.isVisible);
        setModelos(modelos as unknown as Modelo[]);
      });
    });

    getEmpresa().then((empresa) => {
      // console.log({ empresa });
      setEmpresa(empresa);
    });

    getCategorias().then((_data) => {
      const data = _data
        .filter((categ) => categ.isVisible === 1)
        .sort((a, b) => a.ordenacao.localeCompare(b.ordenacao));
      setCategoriasRaw(data);
      // parse json with parentId to tree view structure
      const o: any = {};

      data.forEach(({ id, descricao, parentId, temSubCategoria }) => {
        Object.assign(
          (o[id] = o[id] || {}),
          { id },
          { descricao },
          { temSubCategoria }
        );
        o[parentId] = o[parentId] || {};
        o[parentId].values = o[parentId].values || [];
        o[parentId].values.push(o[id]);
      });

      // console.log({ o });
      const categs = o[0].values[0].values;
      // console.log(categs);
      setCategorias(categs);
    });
    getVeiculoTipos().then(setVeiculoTipos);
    // getComprimentosUnicos().then(setComprimentosUnicos);

    getMarcasComTipo().then(setMarcasComTipo);

    getTabelaPrecos().then(setTabelaPrecos);

    getEixos().then(setEixos);
  }, []);

  useEffect(() => {
    init();
    getDefaultCliente();
  }, [init, getDefaultCliente]);

  // usa valores do twindcss
  const isXs = useMedia({ maxWidth: '480px' });
  const isSm = useMedia({ minWidth: '640px' });
  const isMd = useMedia({ minWidth: '768px' });
  const isLg = useMedia({ minWidth: '1024px' });
  const isXl = useMedia({ minWidth: '1280px' });
  const is2xl = useMedia({ minWidth: '1536px' });
  const is3xl = useMedia({ minWidth: '1920px' });

  return (
    <AppContext.Provider
      value={{
        // loads
        init,
        empresa,
        categorias,
        categoriasRaw,
        modelos,
        veiculoTipos,
        tabelaPrecos,
        // comprimentosUnicos,
        marcas,
        eixos,
        marcasComTipo,
        currentUser,
        isPerfilSync,
        isPerfilAdmin,
        isPerfilManager,
        defaultCliente,
        currentClientAtivo,
        updateDefaultCli,
        updateDefaultTab,
        tabelaUpdateCount,
        setTabelaUpdateCount,
        pesquisasTopBar,
        tabSearchIndex,
        setTabSearchIndex,
        tabSearchCurrentSlider,
        setTabSearchCurrentSlider,
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        is2xl,
        is3xl,
        consumidorFinalAtivo: showPrecoConsumidorFinal,
        showPrecoConsumidorFinal:
          innerShowPrecoConsumidorFinal && showPrecoConsumidorFinal,
        toggleShowPrecoConsumidorFinal,
        innerShowPrecoConsumidorFinal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = (): IContext => {
  const context = useContext(AppContext);
  return context;
};

export { useApp, AppProvider };
