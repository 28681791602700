/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useProduct } from './product.hook';
import { Produto } from '../../app.models';
import DisplayProductCard from './displayProductCard';
import DisplayProductList from './displayProductList';
import CardType from './cardType';
import TableProduct from './tableProduct';
import { useSearchParams } from 'react-router-dom';
import { useApp } from '../../app.hook';
import { EnumOrigens } from '../../utils/origens';
import TabSearchWraper from '../../components/TabSearchWraper';
import clsx from 'clsx';
import FilterObject from '../../components/filterObject';

interface FiltersTextProps {
  handlerPage?: (page: number) => void;
}

const ProcessFilterSelected = (filtros: string | null) => {
  if (!filtros) return [];
  const selected = filtros ? JSON.parse(filtros) : {};
  return Object.entries(selected).map(([label, value]) => ({
    label,
    value,
  })) as unknown as {
    label: string;
    value: any;
  }[];
};

export default function FiltersAll(props: FiltersTextProps) {
  const { empresa } = useApp();
  const { setOrigem } = useProduct();
  const [searchParams, setSearchParams] = useSearchParams();
  const { marcas, modelos, categoriasRaw: categorias, eixos } = useApp();
  const { listProdutos, loading, cardType, orderType } = useProduct();
  const [list, setList] = useState<Produto[]>([]);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [tags, setTags] = useState<Record<string, any>>({});
  const [selectedFilters, setSelectedFilters] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  const [selectedTags, setSelectedTags] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  // const [filters, setFilters] = useState<Record<string, any>>({});
  // const [tags, setTags] = useState<Record<string, any>>({});

  const getTagsLabel = useCallback(
    (id: number | string, table: string): string | undefined => {
      const _id = Number(id);
      switch (table) {
        case 'marcas':
          return marcas.find((m) => m.id === _id)?.marcaDesc;
        case 'modelos':
          const modelo = modelos.find((m) => Number(m.id) === _id);
          if (!modelo) return undefined; //String(_id);
          return `${modelo.modeloDesc} <span class="text-primary1/50 px-1">ano: ${modelo.anoI}-${modelo.anoF}</span>`;
        case 'categorias':
          return categorias.find((m) => m.id === _id)?.descricao;
        case 'eixos':
          return eixos.find((m) => m.id === _id)?.eixoDesc;
        default:
          return undefined;
      }
    },
    [categorias, eixos, marcas, modelos]
  );

  const ref = useRef<HTMLDivElement>(null);
  const toTop = () => {
    ref.current && ref.current.scroll(0, 0);
  };

  const clearFiltros = () => {
    const allParams = searchParams;
    allParams.set('filtros', '');
    setSearchParams(allParams);
  };

  const clearTags = useCallback(() => {
    const allParams = searchParams;
    allParams.set('tags', '');
    setSearchParams(allParams);
  }, [searchParams, setSearchParams]);

  // listagem
  useEffect(() => {
    if (listProdutos?.produtos && listProdutos.produtos.length > 0) {
      setList(
        listProdutos.produtos.sort((a, b) =>
          orderType === 'alfa'
            ? a.descricao.localeCompare(b.descricao)
            : parseInt(a.codigoSgi) - parseInt(b.codigoSgi)
        )
      );
      if (listProdutos.filtros) setFilters(listProdutos.filtros);
      if (listProdutos.tags) {
        const {
          marcas: _marcas,
          modelos: _modelos,
          eixos: _eixos,
          categorias: _categorias,
        } = listProdutos.tags;

        setTags({
          marcas: [],
          modelos: [],
          eixos: [],
          categorias: [],
        });

        const formatedMarcas = _marcas
          ? _marcas.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'marcas'),
              value: Number(el),
            }))
          : [];
        const formatedModelos = _modelos
          ? _modelos.map((el: unknown) => {
              const lb = getTagsLabel(Number(el), 'modelos');
              return {
                label: lb,
                value: Number(el),
              };
            })
          : [];
        const formatedEixos = _eixos
          ? _eixos.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'eixos'),
              value: Number(el),
            }))
          : [];
        const formatedCategorias = _categorias
          ? _categorias.map((el: unknown) => ({
              label: getTagsLabel(Number(el), 'categorias'),
              value: Number(el),
            }))
          : [];

        if (formatedMarcas.length > 0) {
          setTags((prev) => ({ ...prev, marcas: formatedMarcas }));
        }

        if (formatedModelos.length > 0) {
          setTags((prev) => ({ ...prev, modelos: formatedModelos }));
        }

        if (formatedEixos.length > 0) {
          setTags((prev) => ({ ...prev, eixos: formatedEixos }));
        }

        if (formatedCategorias.length > 0) {
          setTags((prev) => ({ ...prev, categorias: formatedCategorias }));
        }
      }
    } else {
      setList([]);
      setTags({
        marcas: [],
        modelos: [],
        eixos: [],
        categorias: [],
      });
    }
  }, [clearTags, getTagsLabel, listProdutos, orderType]);

  useEffect(() => {
    const selectedsFilter = ProcessFilterSelected(searchParams.get('filtros'));
    // console.log('🚀 ~ useEffect ~ selectedsFilter:', selectedsFilter);
    setSelectedFilters(selectedsFilter);
    const selectedsTags = ProcessFilterSelected(searchParams.get('tags'));
    // console.log('🚀 ~ useEffect ~ selectedsTags:', selectedsTags);
    setSelectedTags(selectedsTags);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    toTop();
  }, [selectedFilters, selectedTags]);

  if (loading) {
    return (
      <div className="flex flex-col md:flex-row h-full">
        <div className="w-full md:w-5/6 flex flex-col justify-center items-center h-full animate-pulse">
          <span className="mx-auto"></span>Aguarde ...
        </div>
      </div>
    );
  }

  if (!list) return <></>;

  // caso não encontre produtos
  if (list && list.length === 0) {
    return (
      <div className="flex flex-col md:flex-row md:h-full">
        <TabSearchWraper className="flex flex-col w-full h-full">
          <div className="flex flex-col justify-center items-center h-[60vh]">
            {/* <span className="mx-auto">Produto(s) não encontrado(s).</span> */}
            <span className="mx-auto">Pesquise um produto.</span>
          </div>
        </TabSearchWraper>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row md:h-full">
      <div
        ref={ref}
        className="w-full md:w-1/6 md:shadow-lg md:h-full md:overflow-hidden md:overflow-y-auto p-2"
      >
        <div>
          {(Object.keys(selectedTags).length > 0 ||
            Object.keys(selectedFilters).length > 0) && (
            <div className="px-2 py-2 flex justify-center">
              <button
                className="text-xs text-slate-400 hover:underline"
                onClick={() => {
                  setOrigem(EnumOrigens.FILTRO + 'Limpar_Filtros');
                  const allParams = searchParams;
                  allParams.set('filtros', '');
                  allParams.set('tags', '');
                  setSearchParams(allParams);
                  toTop();
                }}
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
        <div>
          {/* marcas ********************************************* */}
          <FilterObject
            title={'Marcas'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items={tags.marcas?.sort((a: { label: any }, b: { label: any }) =>
              String(a.label).localeCompare(String(b.label))
            )}
            onChange={(d) => {
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              const obj = { ['marcas']: d };
              Object.assign(params, obj);
              allParams.set('tags', JSON.stringify(params));
              allParams.set('skip', '0');
              setSearchParams(allParams);
              const selecteds = ProcessFilterSelected(searchParams.get('tags'));
              setSelectedTags(selecteds);
            }}
            onUncheck={(del) => {
              const _key = del.toLocaleLowerCase();
              const newSelected = selectedTags.filter(
                (el) => el.label !== _key
              );
              setSelectedTags(newSelected);
              if (newSelected.length === 0) {
                clearTags();
                return;
              }
              const allParams = searchParams;
              const tags = allParams.get('tags');
              const params = tags ? JSON.parse(tags) : {};
              delete params[_key];

              allParams.set('tags', JSON.stringify(params));
              allParams.set('filtros', '');
              setSearchParams(allParams);
            }}
            selecteds={selectedTags.reduce((acc: string[], cur) => {
              acc.push(cur.label);
              return acc;
            }, [])}
            highlight={true}
          />

          {/* modelos ********************************************* */}
          {empresa.pesquisarSomenteMarca === false && (
            <FilterObject
              title={'Modelos'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              items={tags.modelos?.sort(
                (a: { label: any }, b: { label: any }) =>
                  String(a.label).localeCompare(String(b.label))
              )}
              onChange={(d) => {
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                const obj = { ['modelos']: d };
                Object.assign(params, obj);
                allParams.set('tags', JSON.stringify(params));
                allParams.set('skip', '0');
                setSearchParams(allParams);
                const selecteds = ProcessFilterSelected(
                  searchParams.get('tags')
                );
                setSelectedTags(selecteds);
              }}
              onUncheck={(del) => {
                const _key = del.toLocaleLowerCase();
                const newSelected = selectedTags.filter(
                  (el) => el.label !== _key
                );
                setSelectedTags(newSelected);
                if (newSelected.length === 0) {
                  clearTags();
                  return;
                }
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                delete params[_key];

                allParams.set('tags', JSON.stringify(params));
                allParams.set('filtros', '');
                setSearchParams(allParams);
              }}
              selecteds={selectedTags.reduce((acc: string[], cur) => {
                acc.push(cur.label);
                return acc;
              }, [])}
              highlight={true}
            />
          )}

          {/* eixos ********************************************* */}
          {empresa.pesquisarSomenteMarca === false && (
            <FilterObject
              title={'Eixos'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              items={tags.eixos?.sort((a: { label: any }, b: { label: any }) =>
                String(a.label).localeCompare(String(b.label))
              )}
              onChange={(d) => {
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                const obj = { ['eixos']: d };
                Object.assign(params, obj);
                allParams.set('tags', JSON.stringify(params));
                allParams.set('skip', '0');
                setSearchParams(allParams);
                const selecteds = ProcessFilterSelected(
                  searchParams.get('tags')
                );
                setSelectedTags(selecteds);
              }}
              onUncheck={(del) => {
                const _key = del.toLocaleLowerCase();
                const newSelected = selectedTags.filter(
                  (el) => el.label !== _key
                );
                setSelectedTags(newSelected);
                if (newSelected.length === 0) {
                  clearTags();
                  return;
                }
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                delete params[_key];

                allParams.set('tags', JSON.stringify(params));
                allParams.set('filtros', '');
                setSearchParams(allParams);
              }}
              selecteds={selectedTags.reduce((acc: string[], cur) => {
                acc.push(cur.label);
                return acc;
              }, [])}
              highlight={true}
            />
          )}

          {/* categorias ********************************************* */}
          {tags.categorias && tags.categorias.length > 0 && (
            <FilterObject
              title={'Categorias'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              items={tags.categorias?.sort(
                (a: { label: any }, b: { label: any }) =>
                  String(a.label).localeCompare(String(b.label))
              )}
              onChange={(d) => {
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                const obj = { ['categorias']: d };
                Object.assign(params, obj);
                allParams.set('tags', JSON.stringify(params));
                allParams.set('skip', '0');
                setSearchParams(allParams);
                const selecteds = ProcessFilterSelected(
                  searchParams.get('tags')
                );
                setSelectedTags(selecteds);
              }}
              onUncheck={(del) => {
                const _key = del.toLocaleLowerCase();
                const newSelected = selectedTags.filter(
                  (el) => el.label !== _key
                );
                setSelectedTags(newSelected);
                if (newSelected.length === 0) {
                  clearTags();
                  return;
                }
                const allParams = searchParams;
                const tags = allParams.get('tags');
                const params = tags ? JSON.parse(tags) : {};
                delete params[_key];

                allParams.set('tags', JSON.stringify(params));
                allParams.set('filtros', '');
                setSearchParams(allParams);
              }}
              selecteds={selectedTags.reduce((acc: string[], cur) => {
                acc.push(cur.label);
                return acc;
              }, [])}
              highlight={true}
            />
          )}

          {/* filtros ********************************************** */}
          {empresa.pesquisarSomenteMarca === false &&
            Object.keys(selectedTags).length > 0 && (
              <>
                {Object.entries(filters).map(([key, value], idx) => (
                  <FilterObject
                    key={idx}
                    title={key}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    items={value.map(
                      (val: any) =>
                        val && {
                          label: val,
                          value: val,
                        }
                    )}
                    onChange={(d) => {
                      const allParams = searchParams;
                      const filtros = allParams.get('filtros');
                      const params = filtros ? JSON.parse(filtros) : {};
                      const obj = { [key]: d };
                      Object.assign(params, obj);
                      allParams.set('filtros', JSON.stringify(params));
                      allParams.set('skip', '0');
                      setSearchParams(allParams);
                      const selecteds = ProcessFilterSelected(
                        searchParams.get('filtros')
                      );
                      setSelectedFilters(selecteds);
                    }}
                    onUncheck={(del) => {
                      const _key = del.toLocaleLowerCase();
                      const newSelected = selectedFilters.filter(
                        (el) => el.label !== _key
                      );
                      setSelectedFilters(newSelected);
                      if (newSelected.length === 0) {
                        clearFiltros();
                        return;
                      }
                      const allParams = searchParams;
                      const filters = allParams.get('filters');
                      const params = filters ? JSON.parse(filters) : {};
                      delete params[_key];

                      allParams.set('filters', JSON.stringify(params));
                      setSearchParams(allParams);
                    }}
                    selecteds={selectedFilters.reduce((acc: string[], cur) => {
                      acc.push(cur.label);
                      return acc;
                    }, [])}
                  />
                ))}
              </>
            )}
        </div>
      </div>
      <div className="flex flex-col w-full md:w-5/6 h-full">
        {listProdutos.info && (
          <CardType
            // cardType={cardType}
            // setCardType={setCardType}
            qtdeReg={listProdutos.info.qtdeTotal}
            handlerPage={props.handlerPage}
            curPage={parseInt(listProdutos.info.pagina)}
            perPage={parseInt(listProdutos.info.qtdePorPagina)}
          />
        )}

        {cardType === 'card' && (
          <TabSearchWraper
            className="p-4 overflow-hidden overflow-y-auto"
            classNameWraper={clsx(
              'grid gap-x-4 gap-y-6 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
              'mx-auto lg:max-w-7xl'
            )}
          >
            {list.map((it) => (
              <DisplayProductCard key={it.id} product={it} />
            ))}
          </TabSearchWraper>
        )}
        {cardType === 'list' && (
          <TabSearchWraper
            className="p-4 overflow-hidden overflow-y-auto"
            classNameWraper={clsx(
              'flex flex-col space-y-2',
              'mx-auto lg:max-w-7xl'
            )}
          >
            {list.map((it) => (
              <DisplayProductList key={it.id} product={it} />
            ))}
          </TabSearchWraper>
        )}
        {cardType === 'table' && (
          <TabSearchWraper
            className="overflow-hidden overflow-y-auto "
            classNameWraper={clsx(
              'flex flex-col space-y-2'
              // 'mx-auto lg:max-w-7xl'
            )}
          >
            <TableProduct items={list} />
          </TabSearchWraper>
        )}
      </div>
    </div>
  );
}
