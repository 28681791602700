import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { getEmpresaSemToken, login } from '../app.service';
import EmpresaImagemLogin from '../components/EmpresaImagemLogin';
import { Empresa } from '../app.models';

const Initial: React.FC = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);

  const [empresa, setEmpresa] = useState<Empresa>();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    setDisabled(true);
    login(user, password)
      .then(() => {
        self.location.reload();
      })
      .catch((e) => {
        // console.log('Data Error => ', e);
        setError(e.message);
      })
      .finally(() => setDisabled(false));
    e.preventDefault();
  };

  const loadFromEmpresa = useCallback(async () => {
    const empresa = await getEmpresaSemToken();
    setEmpresa(empresa);
    document.title = empresa.nomeFantasia;
  }, []);

  useEffect(() => {
    loadFromEmpresa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(error);

  return (
    <div className="grid grid-cols-[4fr_1.5fr] font-display h-screen overflow-hidden">
      <div>
        <div className="flex flex-col h-screen items-center justify-center bg-primary1 h-screen w-full">
          <EmpresaImagemLogin
            wrapperClassName="w-full max-w-xl h-auto flex justify-center items-center"
            className="w-full h-auto"
            empresa={empresa!}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-full bg-black/10">
        <h1 className="mt-10 text-center font-extrabold text-4xl mb-4">
          Acesso ao Sistema
        </h1>
        <div className="w-full max-w-xs mx-auto my-auto">
          <form
            className="px-8 pt-6 pb-8 mb-4"
            onSubmit={(e) => {
              submit(e);
            }}
          >
            <div className="mb-4">
              <label
                className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
              >
                Usuário
                <input
                  type="text"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  placeholder="Digite seu usuário"
                  className={`
                  p-2
                  rounded
                  bg-white
                `}
                />
              </label>
            </div>

            <div className="mb-4">
              <label
                className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
              >
                Senha
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Digite sua senha"
                  className={`
                  p-2
                  rounded
                  bg-white
                `}
                />
              </label>
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                color="DEFAULT"
                className={`
                w-full
                ${disabled ? 'bg-gray-400' : 'bg-primary'}
                py-1 px-3
                my-3 mx-auto
                rounded-full
                text-white
                bg-primary1
                hover:bg-primary2
              `}
                disabled={disabled}
              >
                {disabled ? 'Aguarde...' : 'Entrar'}
              </button>
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Initial;
