import { IDestaque, Produto } from '../app.models';
import { classNames as setClassNames } from '../utils/functions';
import {
  CarouselProvider,
  Slide,
  Slider,
  // DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { parseCurrency } from '../utils/numberHandler';
// import Rating from 'react-rating-stars-component';
import Button from './layout/button';
import { getCart } from '../pages/cart/cart.service';
import { useCart } from '../pages/cart/cart.hook';
import { useRef, useState } from 'react';
import ModalProduct from '../pages/product/modalProduct';
import ModalSelectCard from '../pages/myCarts/modalSelectCard';
import { ROUTES_MAP } from '../app.map.routes';
import useMedia from 'use-media';
import { useProduct } from '../pages/product/product.hook';
import { EnumOrigens } from '../utils/origens';
import ExibirPrecoConsumidorFinal from './ExibirPrecoConsumidorFinal';

interface Props {
  classNames: string[];
  destaque: IDestaque;
}

export function Destaque({ classNames, destaque }: Props) {
  const { currentCart, newCart } = useCart();
  const { setOrigem } = useProduct();
  const isExtraSmall = useMedia({ maxWidth: 320 });
  const isSmall = useMedia({ minWidth: 321, maxWidth: 480 });
  const isMedium = useMedia({ minWidth: 481, maxWidth: 768 });
  const isBig = useMedia({ minWidth: 769, maxWidth: 1440 });
  const isExtraBig = useMedia({ minWidth: 1441, maxWidth: 1920 });
  const isExtraExtraBig = useMedia({ minWidth: 1921 });

  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const produto = useRef<Produto | undefined>(undefined);

  const buy = async (_produto: Produto) => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      produto.current = _produto;
      setOpenModal(true);
    } else {
      newCart();
      produto.current = _produto;
      setOpenModal(true);
      // setOpenModalSelectCart(true);
    }
  };

  const getVisibleSlides = () => {
    if (isExtraSmall) {
      return 1;
    }

    if (isSmall) {
      return 1;
    }

    if (isMedium) {
      return 2;
    }

    if (isBig) {
      return 3;
    }

    if (isExtraBig) {
      return 4;
    }

    if (isExtraExtraBig) {
      return 5;
    }

    return 5;
  };

  const getNaturalProp = () => {
    if (isExtraSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 2.2,
      };
    }

    if (isSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 2,
      };
    }

    if (isMedium) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.2,
      };
    }

    if (isBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.2,
      };
    }

    if (isExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.1,
      };
    }

    if (isExtraExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.1,
      };
    }

    return {
      naturalSlideWidth: 1,
      naturalSlideHeight: 1.1,
    };
  };

  const isPlaying = () => {
    return destaque.produtos.length > getVisibleSlides();
  };

  return (
    <div className="p-6">
      <CarouselProvider
        naturalSlideWidth={getNaturalProp().naturalSlideWidth}
        naturalSlideHeight={getNaturalProp().naturalSlideHeight}
        totalSlides={destaque.produtos.length}
        step={getVisibleSlides()}
        infinite={true}
        orientation="horizontal"
        interval={6000}
        isPlaying={isPlaying()}
        visibleSlides={getVisibleSlides()}
        className={setClassNames(classNames)}
      >
        <div className="flex flex-col bg-white py-4 px-6 rounded-lg w-full 2xl:w-[70%] mx-auto">
          <div className="text-lg font-semibold grid grid-cols-12">
            <div className="col-span-12 flex flex-col">
              <div className="flex justify-between text-2xl w-full">
                <Link
                  to={ROUTES_MAP.ofertaId(destaque.id)}
                  className="flex justify-between text-primary1 w-full hover:underline"
                >
                  <strong>{destaque.destaqueDesc}</strong>
                </Link>
                <Link
                  to={ROUTES_MAP.ofertaId(destaque.id)}
                  className="text-sm opacity-70 hover:underline min-w-max inline-flex"
                >
                  Ver todas! <ChevronRightIcon className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-rol">
            {isPlaying() && (
              <div className="flex flex-col group hover:bg-slate-200">
                <ButtonBack className="h-full">
                  <ChevronLeftIcon className="w-8 h-8 stroke-slate-600 group-hover:stroke-slate-800" />
                </ButtonBack>
              </div>
            )}
            <Slider className="w-full">
              {destaque.produtos.map((produto: Produto, index) => {
                return (
                  <Slide index={index} key={index}>
                    <div className="flex flex-col justify-center items-center h-full">
                      <div className="p-4 transition-shadow hover:shadow-md rounded-md flex flex-col items-center">
                        <img
                          src={produto.images[0].small}
                          className="relative py-2 h-[150px]"
                        />
                        <div className="relative h-10 w-full text-left text-sm font-semibold opacity-70 line-clamp-2 overflow-hidden">
                          {produto.descricaoSite}
                        </div>
                        <div className=" relative w-full flex justify-between">
                          <div className="w-full">
                            {/* <Rating
                            edit={false}
                            count={5}
                            size={20}
                            activeColor="#ffd700"
                            value={Math.floor(Math.random() * 5 + 2)}
                          /> */}
                          </div>
                          {produto.isPromocao ? (
                            <div className="flex flex-col my-1">
                              <div className="flex flex-col items-end">
                                <span className="flex space-x-2 md:text-sm lg:text-sm line-through text-gray-400">
                                  {/* <span>{parseCurrency(produto.preco)}</span> */}
                                  <ExibirPrecoConsumidorFinal
                                    item={produto}
                                    field="preco"
                                    fieldConsumidorFinal="precoConsumidorFinal"
                                  />
                                </span>
                                <span className="flex space-x-2 text-md md:text-md lg:text-lg text-amber-500 font-bold">
                                  {/* <span>
                                    {parseCurrency(produto.precoPromo)}
                                  </span> */}
                                  <ExibirPrecoConsumidorFinal
                                    item={produto}
                                    field="precoPromo"
                                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                                  />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-col justify-center my-0 py-1 h-[3.5em]">
                              <div className="flex my-2 justify-end">
                                <span className="flex space-x-2 font-bold text-lg">
                                  {/* <span>
                                    {parseCurrency(produto.precoPromo)}
                                  </span> */}
                                  <ExibirPrecoConsumidorFinal
                                    item={produto}
                                    field="precoPromo"
                                    fieldConsumidorFinal="precoPromoConsumidorFinal"
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="my-2 w-full flex justify-between">
                          <Link
                            onClick={() => setOrigem(EnumOrigens.DESTAQUE)}
                            to={ROUTES_MAP.productById(produto.id)}
                            className="text-xs opacity-70 hover:underline"
                          >
                            Mais detalhes
                          </Link>
                          <Button
                            title="Comprar"
                            onClick={() => buy(produto)}
                          />
                        </div>
                      </div>
                    </div>
                  </Slide>
                );
              })}
            </Slider>
            {isPlaying() && (
              <div className="flex flex-col group hover:bg-slate-200">
                <ButtonNext className="h-full">
                  <ChevronRightIcon className="w-8 h-8 stroke-slate-600 group-hover:stroke-slate-800" />
                </ButtonNext>
              </div>
            )}
          </div>
          {/* <DotGroup dotNumbers className="space-x-1" /> */}
        </div>
      </CarouselProvider>
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={produto.current}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </div>
  );
}
