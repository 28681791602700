import { Produto } from '../../app.models';
import ModalProduct from './modalProduct';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MAP } from '../../app.map.routes';
import { useCart } from '../cart/cart.hook';
import { getCart } from '../cart/cart.service';
import ModalSelectCard from '../myCarts/modalSelectCard';
import { useProduct } from './product.hook';
import { EnumOrigens } from '../../utils/origens';
import CardProduct from './cardProduct';
interface DisplayProductCardProps {
  product: Produto;
  origem?: string;
}

const DisplayProductCard: React.FC<DisplayProductCardProps> = ({
  product,
  origem,
}: DisplayProductCardProps) => {
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const { setOrigem } = useProduct();

  const { currentCart, newCart } = useCart();
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const buy = async () => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      setOpenModal(true);
    } else {
      // setOpenModalSelectCart(true);
      newCart();
      setOpenModal(true);
    }
  };

  const navigate = useNavigate();
  const detail = (productId: number) => {
    console.log('origem', origem);
    setOrigem(origem ? origem : EnumOrigens.PRODUTO);
    navigate(ROUTES_MAP.productById(productId));
  };

  return (
    <div>
      <CardProduct produto={product} buy={buy} detail={detail} />
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={product}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </div>
  );
};

export default DisplayProductCard;
