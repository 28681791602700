import { Produto } from '../app.models';
import { classNames as setClassNames } from '../utils/functions';
import {
  CarouselProvider,
  Slide,
  Slider,
  // DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { ROUTES_MAP } from '../app.map.routes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { productSearchPromotions } from '../pages/promotions/promotions.service';
import ModalProduct from '../pages/product/modalProduct';
import ModalSelectCard from '../pages/myCarts/modalSelectCard';
import { getCart } from '../pages/cart/cart.service';
import { useCart } from '../pages/cart/cart.hook';
import useMedia from 'use-media';
import { EnumOrigens } from '../utils/origens';
import { useProduct } from '../pages/product/product.hook';
import ExibirPrecoConsumidorFinal from './ExibirPrecoConsumidorFinal';
import ButtonComprar from './buttonComprar';

interface Props {
  classNames: string | string[];
  qtdeSlides?: number;
}

export function Ofertas({ classNames, qtdeSlides }: Props) {
  const { currentCart, newCart } = useCart();
  const { setOrigem } = useProduct();
  const isExtraSmall = useMedia({ maxWidth: 320 });
  const isSmall = useMedia({ minWidth: 321, maxWidth: 480 });
  const isMedium = useMedia({ minWidth: 481, maxWidth: 768 });
  const isBig = useMedia({ minWidth: 769, maxWidth: 1440 });
  const isExtraBig = useMedia({ minWidth: 1441, maxWidth: 1920 });
  const isExtraExtraBig = useMedia({ minWidth: 1921 });
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [openModalSelectCart, setOpenModalSelectCart] =
    useState<boolean>(false);

  const getVisibleSlides = () => {
    if (isExtraSmall) {
      return 1;
    }

    if (isSmall) {
      return 1;
    }

    if (isMedium) {
      return 2;
    }

    if (isBig) {
      return 3;
    }

    if (isExtraBig) {
      return 4;
    }

    if (isExtraExtraBig) {
      return 6;
    }

    return 6;
  };
  const qtde = getVisibleSlides();
  const produto = useRef<Produto | undefined>(undefined);

  function shuffleArray<T>(arr: T[]): T[] {
    for (let i = arr.length - 1; i > 0; i--) {
      // Escolhendo elemento aleatório
      const j = Math.floor(Math.random() * (i + 1));
      // Reposicionando elemento
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    // Retornando array com aleatoriedade
    return arr;
  }

  const init = useCallback(
    async (_produtos: Produto[]) => {
      const shuffle = shuffleArray<Produto>(_produtos ?? []);
      const shuffle_produtos = shuffle.slice(0, qtde);
      setProdutos(shuffle_produtos);
    },
    [qtde]
  );

  useEffect(() => {
    productSearchPromotions().then((res) => {
      init(res.produtos);
    });
  }, [init]);

  const buy = async (_produto: Produto) => {
    const verificaSituacao = (await getCart(currentCart.id)).situacaoCarrinho;

    if (verificaSituacao === 0) {
      produto.current = _produto;
      setOpenModal(true);
    } else {
      // setOpenModalSelectCart(true);
      newCart();
      produto.current = _produto;
      setOpenModal(true);
    }
  };

  const getNaturalProp = () => {
    if (isExtraSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1,
      };
    }

    if (isSmall) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.2,
      };
    }

    if (isMedium) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 0.9,
      };
    }

    if (isBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 0.8,
      };
    }

    if (isExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 0.8,
      };
    }

    if (isExtraExtraBig) {
      return {
        naturalSlideWidth: 1,
        naturalSlideHeight: 1.1,
      };
    }

    return {
      naturalSlideWidth: 1,
      naturalSlideHeight: 1.1,
    };
  };

  return (
    <div className="p-6 w-full 2xl:w-[70%]">
      <CarouselProvider
        naturalSlideWidth={getNaturalProp().naturalSlideWidth}
        naturalSlideHeight={getNaturalProp().naturalSlideHeight}
        totalSlides={produtos.length}
        step={getVisibleSlides()}
        infinite={true}
        orientation="horizontal"
        interval={6000}
        isPlaying={true}
        visibleSlides={getVisibleSlides()}
        className={setClassNames(classNames)}
      >
        <div className="flex flex-col">
          <div className="text-lg font-semibold grid grid-cols-12">
            <div className="col-span-12 flex justify-between">
              <div className="flex justify-between text-primary1">
                <strong>Ofertas</strong>
              </div>
              <Link
                to={ROUTES_MAP.ofertas}
                className="text-sm opacity-70 hover:underline min-w-max inline-flex"
              >
                Ver todas! <ChevronRightIcon className="w-5 h-5" />
              </Link>
            </div>
            {qtde > getVisibleSlides() && (
              <div className="col-span-10 flex justify-end">
                <ButtonBack className="">
                  <ChevronLeftIcon className="w-8 h-8 stroke-slate-600 hover:stroke-slate-800" />
                </ButtonBack>
                <ButtonNext className="">
                  <ChevronRightIcon className="w-8 h-8 stroke-slate-600 hover:stroke-slate-800" />
                </ButtonNext>
              </div>
            )}
          </div>
          <Slider className="relative">
            {produtos.map((produto: Produto, index) => {
              return (
                <Slide index={index} key={index}>
                  <div className="flex flex-col justify-center items-center h-full">
                    <div className="p-4 transition-shadow hover:shadow-md rounded-md">
                      <div className="flex items-center">
                        <img
                          src={produto.images[0].small}
                          className="relative py-2 h-24 p-2 min-w-[120px]"
                        />
                        <div>
                          <div className="relative h-10 w-full text-left text-sm font-semibold opacity-70 line-clamp-2 overflow-hidden">
                            {produto.descricaoSite}
                          </div>
                          <div className=" relative w-full flex flex-col">
                            <div className="relative w-full text-sm line-through text-left">
                              <strong>
                                {/* {parseCurrencyBRL(produto.preco)} */}
                                <ExibirPrecoConsumidorFinal
                                  item={produto}
                                  field="preco"
                                  fieldConsumidorFinal="precoConsumidorFinal"
                                />
                              </strong>
                            </div>
                            <div className="relative w-full text-lg text-right bg-amber-500 text-white rounded-sm px-1">
                              <strong>
                                {/* {parseCurrencyBRL(produto.precoPromo)} */}
                                <ExibirPrecoConsumidorFinal
                                  item={produto}
                                  field="precoPromo"
                                  fieldConsumidorFinal="precoPromoConsumidorFinal"
                                />
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-2 w-full flex justify-between">
                        <Link
                          onClick={() => setOrigem(EnumOrigens.OFERTA)}
                          to={ROUTES_MAP.productById(produto.id)}
                          className="text-xs opacity-70 hover:underline"
                        >
                          Mais detalhes
                        </Link>
                        {/* <Button title="Comprar" onClick={() => buy(produto)} /> */}
                        <ButtonComprar
                          produto={produto}
                          action={() => buy(produto)}
                        />
                      </div>
                      {/* <div className="my-2 flex justify-center">
                      <Button title="Comprar" onClick={() => buy(produto)} />
                    </div> */}
                    </div>
                  </div>
                </Slide>
              );
            })}
          </Slider>
          {/* <DotGroup dotNumbers className="space-x-1" /> */}
        </div>
      </CarouselProvider>
      <ModalProduct
        isOpen={modalOpen}
        setIsOpen={setOpenModal}
        product={produto.current}
      />
      <ModalSelectCard
        isOpen={openModalSelectCart}
        setIsOpen={setOpenModalSelectCart}
      />
    </div>
  );
}
