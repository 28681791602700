import { Dialog, Transition } from '@headlessui/react';
import { DotsCircleHorizontalIcon, EyeIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import useMedia from 'use-media';
import { Cart } from '../../app.models';
import Button from '../../components/layout/button';
import { getFormatedDateTimeSemTimeZone } from '../../utils/datetimeHandler';
import { classNames } from '../../utils/functions';
import { parseCurrencyBRL } from '../../utils/numberHandler';
import { useCart } from '../cart/cart.hook';
import { getCarts } from './carts.service';

interface ModalSelectCardProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

interface CartStatusProps {
  situacaoCarrinho: number;
}

const CartStatus = ({ situacaoCarrinho }: CartStatusProps) => {
  const { cartStatus } = useCart();
  const status = cartStatus.find((c) => c.id === situacaoCarrinho);
  const class_name = cartStatus.find(
    (c) => c.id === situacaoCarrinho
  )?.className;
  if (status)
    return (
      <div className={classNames('flex carts-center', class_name)}>
        <span>{status.icone}</span>
        <span>{status.situacao}</span>
      </div>
    );
  else
    return (
      <div className="flex carts-center">
        <span>
          <DotsCircleHorizontalIcon className="w-5 h-5" />
        </span>
        <span>Não Definida</span>
      </div>
    );
};

export default function ModalSelectCard(props: ModalSelectCardProps) {
  const isSmall = useMedia({ maxWidth: 480 });
  function closeModal() {
    props.setIsOpen(false);
  }

  const { setCurrentCart, loadCart, newCart } = useCart();
  const [carts, setCarts] = useState<Cart[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      setLoading(true);
      getCarts({ situacao: '0' }).then((ret) => {
        if (ret) setCarts(ret.carts);
        setLoading(false);
      });
    }
  }, [props.isOpen]);

  const setCart = (cartId: number) => {
    setCurrentCart(cartId);
    setTimeout(() => {
      // loadCart();
      closeModal();
    }, 500);
  };

  return (
    <div>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-[1px] border-solid border-gray-300">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold leading-6 text-gray-900 font-display line-clamp-2"
                >
                  Selecione um carrinho
                </Dialog.Title>
                {loading ? (
                  <div className="w-5/6 flex flex-col justify-center items-center h-full animate-pulse">
                    <span className="mx-auto"></span>Aguarde ...
                  </div>
                ) : (
                  <div className="mt-2 flex flex-col font-display">
                    <div className="py-2 flex justify-end">
                      <Button
                        title="Novo Carrinho"
                        color="bg-default-white text-primary2 mr-2 hover:bg-gray-100 hover:text-gray-700"
                        onClick={() => {
                          newCart();
                          loadCart();
                          closeModal();
                        }}
                      />
                    </div>
                    {isSmall ? (
                      <div className="flex flex-col space-y-2">
                        {carts.map((cart) => {
                          return (
                            <div
                              className="my-2 p-2 odd:bg-gray-200 even:bg-gray-50"
                              key={cart.id}
                            >
                              <div className="px-1 py-0 flex justify-between">
                                <div className="text-sm text-gray-900 text-left font-bold">
                                  Carrinho #{cart.id}
                                </div>
                                <div className="text-sm text-gray-900 text-left">
                                  {getFormatedDateTimeSemTimeZone(
                                    cart.created_at.toString()
                                  )}
                                </div>
                              </div>
                              <div className="px-1 py-0 flex justify-between">
                                <span className="text-sm text-gray-900 text-left font-bold">
                                  Situação:
                                </span>
                                <CartStatus
                                  situacaoCarrinho={cart.situacaoCarrinho}
                                />
                              </div>

                              <div className="px-1 py-0 flex justify-between">
                                <span className="text-sm text-gray-900 text-left  font-bold">
                                  Vendedor:
                                </span>
                                <div className="text-sm text-gray-900 text-left">
                                  {cart.usuario.nome.toLocaleUpperCase()}
                                </div>
                              </div>
                              <div className="px-1 py-0 flex flex-col">
                                <span className="text-sm text-gray-900 text-left font-bold">
                                  Comprador:
                                </span>
                                <div className="text-sm text-gray-900 text-left">
                                  {cart.comprador?.nome} -{' '}
                                  {cart.comprador?.foneNumero}
                                </div>
                              </div>

                              <div className="text-sm text-gray-900 flex justify-between px-1 font-bold">
                                <span>Qtde: {cart.qtdeTotal}</span>
                                <span>{parseCurrencyBRL(cart.total)}</span>
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  title="Visualizar"
                                  className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                                  onClick={() => setCart(cart.id)}
                                >
                                  <EyeIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <table className="divide-y divide-gray-200 text-xs w-full">
                        <thead className="bg-gray-100 sticky top-0">
                          <tr className="">
                            <th
                              scope="col"
                              className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Situação
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Data
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Vendedor
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Comprador
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Qtde
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                            >
                              Valor
                            </th>
                            <th
                              scope="col"
                              className="py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                            >
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-400">
                          {carts.map((cart) => (
                            <tr
                              key={cart.id}
                              className="even:bg-gray-50 py-[1] hover:bg-gray-300"
                            >
                              <td className="font-bold text-md align-middle">
                                {cart.id}
                              </td>
                              <td className="whitespace-nowrap align-middle">
                                <CartStatus
                                  situacaoCarrinho={cart.situacaoCarrinho}
                                />
                              </td>
                              <td className="whitespace-nowrap align-middle">
                                {getFormatedDateTimeSemTimeZone(
                                  cart.created_at.toString()
                                )}
                              </td>
                              <td className="whitespace-nowrap align-middle">
                                {cart.usuario.nome.toLocaleUpperCase()}
                              </td>
                              <td className="whitespace-nowrap align-middle">
                                {cart.comprador?.nome} -{' '}
                                {cart.comprador?.foneNumero}
                              </td>
                              <td className="whitespace-nowrap text-center align-middle">
                                {cart.qtdeTotal}
                              </td>
                              <td className="whitespace-nowrap text-right align-middle">
                                {parseCurrencyBRL(cart.total)}
                              </td>

                              <td className="text-center">
                                <button
                                  type="button"
                                  title="Visualizar"
                                  className="bg-blue-500 p-1 m-1 text-default-white rounded-full"
                                  onClick={() => setCart(cart.id)}
                                >
                                  <EyeIcon className="h-4 w-4" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
