import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import PrintOrcamentoDD from './printOrcamentoDD';
import { Cart, Empresa, Pedido } from '../../app.models';
import PrintOrderDD from './printOrderDD';
import PrintCartDD from './printCartDD';
import PrintCartImageDD from './printCartImageDD';
import printCartClientImageDD from './printCartClientImageDD';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export const PrintOrcamento = (
  cart: Cart,
  cliente?: string,
  cnpj?: string,
  cel?: string
) => {
  const dd = PrintOrcamentoDD({
    cart,
    cliente,
    cnpj,
    cel,
  });
  const pdf = pdfMake.createPdf(dd);

  // pdf.download();
  pdf.getBase64((data) => {
    console.log(data);
  });
  pdf.open();
};

export const PrintOrderPDF = (
  pedido: Pedido,
  logo?: string,
  empresa?: Empresa
) => {
  PrintOrderDD({
    pedido,
    logo,
    empresa,
  }).then((dd) => {
    const pdf = pdfMake.createPdf(dd);

    pdf.open();
  });
};

export const PrintOrderBase64 = async (
  pedido: Pedido,
  logo?: string,
  empresa?: Empresa
) => {
  PrintOrderDD({
    pedido,
    logo: undefined,
    empresa,
  }).then((dd) => {
    const pdf = pdfMake.createPdf(dd);

    return new Promise((resolve) => {
      pdf.getBase64((data) => {
        resolve(data);
      });
    });
  });
};

export const PrintCartPDF = (cart: Cart) => {
  const dd = PrintCartDD({
    cart,
  });
  const pdf = pdfMake.createPdf(dd);

  pdf.open();
};

export const PrintCartBase64 = async (cart: Cart): Promise<string> => {
  const dd = PrintCartDD({
    cart,
  });
  const pdf = pdfMake.createPdf(dd);

  return await new Promise((resolve) => {
    pdf.getBase64((data) => {
      resolve(data);
    });
  });
};

export const PrintCartImagePDF = async (cart: Cart) => {
  const dd = await PrintCartImageDD({
    cart,
  });
  const pdf = pdfMake.createPdf(dd);

  pdf.open();
};

export const PrintCartImageBase64 = async (cart: Cart): Promise<string> => {
  const dd = await PrintCartImageDD({
    cart,
  });
  const pdf = pdfMake.createPdf(dd);

  return await new Promise((resolve) => {
    pdf.getBase64((data) => {
      resolve(data);
    });
  });
};

export const PrintCartClientImageBase64 = async (
  cart: Cart
): Promise<string> => {
  const dd = await printCartClientImageDD({
    cart,
  });
  const pdf = pdfMake.createPdf(dd);

  return await new Promise((resolve) => {
    pdf.getBase64((data) => {
      resolve(data);
    });
  });
};
