import { useApp } from '../app.hook';
interface Props {
  className?: string;
}

export default function LogoNameBd({ className }: Props) {
  const { empresa } = useApp();
  if (!empresa.empresaLogoSvg) return <></>;
  const image = empresa.empresaLogoSvg;

  return (
    <div>
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
        alt="logo"
        className={className}
      />
    </div>
  );
}
