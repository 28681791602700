/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { CardView, CardViewValue } from './components/CardView';
import Chart from 'react-apexcharts';

import {
  IResponseCotacoesPorCliente,
  cotacoesPedidosResumo,
  pedidosPorCliente as getPedidosPorCliente,
  pedidosPorProduto as getPedidosPorProduto,
  painelCotacoes,
  painelPesquisas,
  IResponseCotacoesPorProduto,
  getProductById,
  pedidosPorProdutoClientes,
  myCarts,
  myOrders,
} from './service';
import {
  getMonthNameAbv,
  getMonthNumberByShortName2,
  truncateString,
} from '../../utils/functions';
import moment from 'moment';
import { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
import {
  MenuIcon,
  SearchIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  TruckIcon,
  EyeIcon,
} from '@heroicons/react/solid';
import LoadingComponent from '../../components/loadingComponent';
import {
  BookOpenIcon,
  ChartBarIcon,
  ClipboardListIcon,
  FilterIcon,
  PencilAltIcon,
  ReceiptRefundIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import ModalFilter from './components/ModalFilter';
import { Cart, Cliente, Pedido, Produto } from '../../app.models';
import ModalFindCliente from '../../components/modalFindCliente';
import InnerGridClienteRanking from './components/InnerGridClienteRanking';
import InnerGridProdutoHanking from './components/InnerGridProdutoHanking';
import { getClienteById } from '../cart/cart.service';
import { useApp } from '../../app.hook';
import { Tab } from '@headlessui/react';
import InnerGridMyCarts from './components/innerGridMyCarts';
import { getCryptoLocalStorage } from '../../app.service';
import { LOCAL_STORAGE_DEFAUT_CLI } from '../../app.settings';
import InnerGridMyOrders from './components/innerGridMyOrders';
import { getPedido } from '../myOrders/pedido.service';
import { PrintOrderPDF } from '../../components/pdf';
import ModalConfirmationNumberWhatsapp from '../../components/modalConfirmationNumberWhatsapp';
import { formatarTelefone } from '../../utils/masks';

type ILoading =
  | 'grids-cotacoes-clientes'
  | 'grids-pedidos-clientes'
  | 'grids-cotacoes-produtos'
  | 'grids-pedidos-produtos'
  | 'pie-produtos'
  | 'pie-pesquisas';

export type IParamsType = 'competencia' | 'periodo';
export interface IParams {
  type: IParamsType;
  clienteId?: string;
  produtoId?: string;
  mes?: string;
  ano?: string;
  dataI?: string;
  dataF?: string;
}

interface IDataPieChart {
  name: string;
  value: number;
  icon?: JSX.Element;
  color?: string;
}

export default function Metrics() {
  const { isPerfilManager, currentUser, empresa } = useApp();
  const [loading, setLoading] = useState<ILoading[]>([]);
  const windowRef = useRef<any>();
  const gridRef = useRef<any>();

  const [verMais, setVerMais] = useState<
    'c' | 'p' | 'car' | 'ped' | undefined
  >();
  function addLoading(name: ILoading) {
    setLoading((prev) => [...prev, name]);
  }

  function removeLoading(name: ILoading) {
    setLoading((prev) => prev.filter((item) => item !== name));
  }

  const [modalOpenSelectCliente, setOpenModalSelectCliente] = useState(false);

  const [laodingCliente, setLoadingCliente] = useState(false);
  const [cliente, setCliente] = useState<Cliente | undefined>();

  const [loadingProduto, setLoadingProduto] = useState(false);
  const [produto, setProduto] = useState<Partial<Produto> | undefined>();

  const [modalFilter, setModalFilter] = useState(false);
  function closeModal() {
    setModalFilter(false);
  }

  const [params, setParams] = useState<IParams>({
    type: 'competencia',
    clienteId: undefined,
    produtoId: undefined,
    mes: moment().format('MM'),
    ano: moment().format('YYYY'),
    dataI: undefined,
    dataF: undefined,
  });

  const [movimentacaoChart, setMovimentacaoChart] = useState<{
    options: ApexOptions;
    series: ApexAxisChartSeries;
  }>({
    options: {},
    series: [],
  });

  const [pesquisasChart, setPesquisasChart] = useState<{
    options: ApexOptions;
    series: ApexNonAxisChartSeries;
    data: IDataPieChart[];
  }>({
    options: {},
    series: [],
    data: [],
  });

  const [cotacoesChart, setCotacoesChart] = useState<{
    options: ApexOptions;
    series: ApexNonAxisChartSeries;
    data: IDataPieChart[];
  }>({
    options: {},
    series: [],
    data: [],
  });

  const [pedidosPorCliente, setPedidosPorCliente] = useState<
    IResponseCotacoesPorCliente[]
  >([]);

  const [pedidosPorProduto, setPedidosPorProduto] = useState<
    IResponseCotacoesPorProduto[]
  >([]);

  const vendasTotal = pedidosPorCliente.reduce(
    (acc, item) => acc + item.total,
    0
  );

  function clearFilter() {
    setParams((prev) => ({
      ...prev,
      clienteId: isPerfilManager ? undefined : prev.clienteId,
      produtoId: undefined,
    }));
    isPerfilManager && setCliente(undefined);
    setProduto(undefined);
    setVerMais(undefined);
  }

  // dados
  useEffect(() => {
    (async () => {
      const colors = ['#008ffb', '#ff8c00', '#327739'];

      // pesquisas
      addLoading('pie-pesquisas');

      let _params: Record<string, any> = {};
      // competencia ou periodo
      if (params.type === 'competencia') {
        _params = {
          mes: params.mes,
          ano: params.ano,
        };
      } else {
        _params = {
          dataI: params.dataI,
          dataF: params.dataF,
        };
      }

      //cliente
      if (params.clienteId) {
        _params = {
          ..._params,
          clienteId: params.clienteId,
        };
      }

      //produto
      if (params.produtoId) {
        _params = {
          ..._params,
          produtoId: params.produtoId,
        };
      }

      const dataPesquisas = await painelPesquisas(_params);
      const reducePesquisas = Object.entries(dataPesquisas).reduce(
        (acc: IDataPieChart[], [key, value]: any) => {
          switch (key) {
            case 'searchFor':
              acc.push({
                name: 'Texto',
                value: value ?? 0,
                icon: (
                  <SearchIcon className="h-6 w-6 text-black" fill={colors[0]} />
                ),
                color: colors[0],
              });
              break;

            case 'categoria':
              acc.push({
                name: 'Categoria',
                value: value ?? 0,
                icon: (
                  <MenuIcon className="h-6 w-6 text-black" fill={colors[1]} />
                ),
                color: colors[1],
              });
              break;

            case 'veiculo':
              acc.push({
                name: 'Veículo',
                value: value ?? 0,
                icon: (
                  <TruckIcon className="h-6 w-7 text-black" fill={colors[2]} />
                ),
                color: colors[2],
              });
              break;

            default:
              break;
          }
          return acc;
        },
        []
      );
      const optionsPesquisas: ApexOptions = {
        chart: {
          type: 'donut',
          height: 125,
          width: 125,
        },
        title: {
          offsetY: -20,
          text:
            reducePesquisas
              .reduce((acc, cur) => {
                return acc + cur.value;
              }, 0)
              .toString() + ' pesquisas',
          style: {
            fontSize: '30pts',
            fontWeight: 400,
            color: '#263238',
          },
        },
        colors: colors,
        labels: reducePesquisas.map((item) => item.name),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };
      const seriesPesquisas = reducePesquisas.map((item) => item.value);
      setPesquisasChart({
        options: optionsPesquisas,
        series: seriesPesquisas,
        data: reducePesquisas,
      });

      // cotacoes

      const dataCotacoes = await painelCotacoes(_params);

      const reduceCotacoes = Object.entries(dataCotacoes).reduce(
        (acc: IDataPieChart[], [key, value]: any) => {
          switch (key) {
            case 'visualizados':
              acc.push({
                name: 'Visualizados',
                value: value ?? 0,
                icon: <EyeIcon className="h-6 w-6" stroke={colors[0]} />,
                color: colors[0],
              });
              break;

            case 'cotados':
              acc.push({
                name: 'Cotados',
                value: value?.qtde ?? 0,
                icon: <ShoppingCartIcon className="h-6 w-6" fill={colors[1]} />,
                color: colors[1],
              });
              break;

            case 'vendidos':
              acc.push({
                name: 'Vendidos',
                value: value?.qtde ?? 0,
                icon: <ShoppingBagIcon className="h-6 w-6" fill={colors[2]} />,
                color: colors[2],
              });
              break;
          }
          return acc;
        },
        []
      );

      const optionsCotacoes: ApexOptions = {
        chart: {
          type: 'donut',
          height: 125,
          width: 125,
        },
        title: {
          offsetY: -20,
          text:
            reduceCotacoes
              .reduce((acc, cur) => {
                return acc + cur.value;
              }, 0)
              .toString() + ' produtos',
          style: {
            fontSize: '30pts',
            fontWeight: 400,
            color: '#263238',
          },
        },
        colors: colors,
        labels: reduceCotacoes.map((item) => item.name),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };
      const seriesCotacoes = reduceCotacoes.map((item) => item.value);
      setCotacoesChart({
        options: optionsCotacoes,
        series: seriesCotacoes,
        data: reduceCotacoes,
      });
      removeLoading('pie-pesquisas');
    })();
  }, [
    params.ano,
    params.clienteId,
    params.dataF,
    params.dataI,
    params.mes,
    params.produtoId,
    params.type,
  ]);

  // dados grids
  useEffect(() => {
    (async () => {
      let _params: Record<string, any> = {};
      if (params.type === 'competencia') {
        _params = {
          mes: params.mes,
          ano: params.ano,
        };
      } else {
        _params = {
          dataI: params.dataI,
          dataF: params.dataF,
        };
      }

      if (params.clienteId) {
        _params = {
          ..._params,
          clienteId: params.clienteId,
        };
      }

      if (params.produtoId) {
        _params = {
          ..._params,
          produtoId: params.produtoId,
        };
      }

      //cliente
      // if (params.clienteId) {
      //   addLoading('grids-pedidos-produtos');
      //   await pedidosPorProdutoClientes(_params).then(setPedidosPorProduto);
      //   removeLoading('grids-pedidos-produtos');
      //   return;
      // }

      //produto
      // if (params.produtoId) {
      //   addLoading('grids-pedidos-clientes');
      //   await pedidosPorClienteProdutos(_params).then(setPedidosPorProduto);
      //   removeLoading('grids-pedidos-clientes');
      //   return;
      // }

      addLoading('grids-pedidos-clientes');
      if (params.produtoId) {
        await pedidosPorProdutoClientes(_params).then((res) => {
          const map: IResponseCotacoesPorCliente[] = res.map((item: any) => ({
            clienteId: item.clienteId,
            nome: item.nome,
            apelido: item.apelido,
            cpf_cnpj: item.cpf_cnpj,
            total: item.totalItem,
            qtde: item?.qtde || 0,
          }));
          setPedidosPorCliente(map);
        });
      } else {
        await getPedidosPorCliente(_params).then((res) => {
          setPedidosPorCliente(res);
        });
      }
      removeLoading('grids-pedidos-clientes');

      addLoading('grids-pedidos-produtos');
      if (params.produtoId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setPedidosPorProduto([produto! as IResponseCotacoesPorProduto]);
      } else {
        await getPedidosPorProduto(_params).then(setPedidosPorProduto);
      }
      removeLoading('grids-pedidos-produtos');
    })();
  }, [
    params.ano,
    params.clienteId,
    params.dataF,
    params.dataI,
    params.mes,
    params.type,
    params.produtoId,
    produto,
  ]);

  // graficos - resumo
  useEffect(() => {
    let _params: Record<string, any> = {};
    if (params.type === 'competencia') {
      _params = {
        mes: params.mes,
        ano: params.ano,
      };
    } else {
      _params = {
        dataI: params.dataI,
        dataF: params.dataF,
      };
    }

    if (params.clienteId) {
      _params = {
        ..._params,
        clienteId: params.clienteId,
      };
    }

    cotacoesPedidosResumo(_params)
      .then((res) => {
        const categories = res.map(
          (item) => getMonthNameAbv(Number(item.mes) - 1) + '/' + item.ano
          // (item) => getMonthNameAbv(Number(item.mes) - 1)
        );

        const series: ApexAxisChartSeries = [
          // {
          //   name: 'Cotados',
          //   type: 'column',
          //   data: res.map((item) => item.qtdeCotacao),
          // },
          // {
          //   name: 'Vendidos',
          //   type: 'column',
          //   data: res.map((item) => item.qtdePedido),
          // },
          // {
          //   name: 'Total Cotado',
          //   type: 'column',
          //   data: res.map((item) => item.totalCotacao),
          // },
          {
            name: 'Total Vendido',
            color: 'rgb(99, 102, 241)',
            type: 'column',
            data: res.map((item) => item.totalPedido),
          },
        ];

        const options: ApexOptions = {
          chart: {
            height: '100%',
            events: {
              dataPointSelection: (e, chart, options) => {
                const data =
                  chart.w.config.xaxis.categories[options.dataPointIndex];
                const [mes, ano] = data.split('/');
                setParams((prev) => ({
                  ...prev,
                  type: 'competencia',
                  mes: getMonthNumberByShortName2(mes),
                  ano,
                  dataI: undefined,
                  dataF: undefined,
                }));
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: '20px',
              dataLabels: {
                hideOverflowingLabels: true,
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return Number(val).toLocaleString('pt-BR');
            },
            offsetY: -15,
            style: {
              fontSize: '8px',
              colors: ['#304758'],
            },
          },
          title: {
            text: 'Últimos 12 meses',
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return Number(val).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                });
              },
            },
          },
          xaxis: {
            categories,
            position: 'bottom',
            labels: {
              offsetY: -5,
              style: {
                fontSize: '10px',
                fontWeight: 900,
              },
              formatter: function (val) {
                return val.split('/')[0];
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return (
                  Number(Math.round(val / 1000)).toLocaleString('pt-BR', {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  }) + 'k'
                );
              },
            },
          },
        };

        setMovimentacaoChart({
          options,
          series,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // loading
      });
  }, [
    params.ano,
    params.clienteId,
    params.dataF,
    params.dataI,
    params.mes,
    params.type,
    isPerfilManager,
  ]);

  // injeta o cliente se existir um clienteId e o produto se existir um produtoId
  useEffect(() => {
    setLoadingCliente(true);
    if (params.clienteId) {
      (async () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const cliente = await getClienteById(params.clienteId!).finally(() => {
          setLoadingCliente(false);
        });
        setCliente(cliente);
      })();
    } else {
      setCliente(undefined);
      setLoadingCliente(false);
    }

    if (params.produtoId) {
      setLoadingProduto(true);
      (async () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const produto = await getProductById(params.produtoId!).finally(() => {
          setLoadingProduto(false);
        });
        setProduto(produto.produto);
      })();
    }
  }, [params.clienteId, params.produtoId]);

  function gotToBottom() {
    new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
      // const windowHeight = windowRef.current.offsetHeight;
      // const gridHeight = gridRef.current.offsetHeight;
      // const scroll = windowHeight + gridHeight;

      windowRef.current.scrollTo({
        top: 1000000,
        behavior: 'smooth',
      });
    });
  }

  function clienteRowClick(cliente: IResponseCotacoesPorCliente) {
    setParams((prev) => ({
      ...prev,
      produtoId: undefined,
      clienteId: cliente.clienteId.toString(),
    }));
    setProduto(undefined);
    setVerMais(undefined);
  }

  function produtoRowClick(produto: IResponseCotacoesPorProduto) {
    setParams((prev) => ({
      ...prev,
      clienteId: undefined,
      produtoId: produto.produtoId.toString(),
    }));
    setCliente(undefined);
    setVerMais(undefined);
  }
  function produtoRowClickNaoManager(produto: IResponseCotacoesPorProduto) {
    setParams((prev) => ({
      ...prev,
      produtoId: produto.produtoId.toString(),
    }));
    setCliente(undefined);
    setVerMais(undefined);
  }

  // quando não for perfil manager
  const tabClass = (selected: boolean) =>
    clsx(
      'w-full rounded-lg text-sm font-medium leading-5',
      'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
      selected
        ? 'bg-primary1 shadow text-white'
        : 'text-primary1 hover:bg-white/[0.12] hover:text-primary2'
    );

  const tabPanelClass = () =>
    clsx(
      'rounded-xl bg-white h-[18rem]',
      'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
      'shadow-md',
      'overflow-y-auto overflow-hidden'
    );

  function filterCartOrders(item: Cart | Pedido) {
    return (
      new Date(item.created_at).getMonth() + 1 === Number(params.mes) &&
      new Date(item.created_at).getFullYear() === Number(params.ano) &&
      String(item.cliente.id) === params.clienteId
    );
  }

  const [carts, setCarts] = useState<Cart[]>([]);
  const cartsFiltereds = carts?.filter(filterCartOrders);

  const [orders, setOrders] = useState<Pedido[]>([]);
  const ordersFiltereds = orders?.filter(filterCartOrders);
  // data meus carrinhos e meus pedidos
  useEffect(() => {
    if (!isPerfilManager) {
      const cliente = getCryptoLocalStorage(LOCAL_STORAGE_DEFAUT_CLI);
      const currentClienteId = cliente?.clienteId.toString() ?? '';
      const period: {
        mes?: string;
        ano?: string;
        dataI?: string;
        dataF?: string;
      } =
        params.type === 'competencia'
          ? {
              mes: params.mes,
              ano: params.ano,
            }
          : {
              dataI: params.dataI,
              dataF: params.dataF,
            };

      myCarts({
        ...period,
        take: 100000,
        skip: 0,
        defaultCli: currentClienteId,
      } as any).then((data) => {
        setCarts(data.carts);
        setParams((prev) => ({
          ...prev,
          clienteId: currentClienteId,
          produtoId: undefined,
        }));
        setProduto(undefined);
        setVerMais(undefined);
      });

      // myOrders
      myOrders({
        ...period,
        skip: 0,
        defaultCli: currentClienteId,
      } as any).then((data) => {
        setOrders(data.pedidos);
        setParams((prev) => ({
          ...prev,
          clienteId: currentClienteId,
          produtoId: undefined,
        }));
        setProduto(undefined);
        setVerMais(undefined);
      });
    }
  }, [
    isPerfilManager,
    params.ano,
    params.dataF,
    params.dataI,
    params.mes,
    params.type,
  ]);

  const [modalIsOpenWhatsapp, setModalIsOpenWhatsapp] =
    useState<boolean>(false);
  const openPanel = async (pedidoId: number) => {
    const pedido = await getPedido(pedidoId);
    // openPanelData.current = pedido;
    // setIsOpenPanel(true);
    PrintOrderPDF(pedido, empresa?.empresaLogoCompleto);
  };

  const openPanelData = useRef<Pedido>();
  async function openModalWhatsapp(pedidoId: number) {
    const pedido = await getPedido(pedidoId);
    openPanelData.current = pedido;
    setModalIsOpenWhatsapp(true);
  }

  function handleWhatsapp(id: number, number: string): void {
    throw new Error('Function not implemented.');
  }

  return isPerfilManager ? (
    <div
      className="px-4 overflow-hidden overflow-y-auto h-[86vh]"
      ref={windowRef}
    >
      {/* linha 0 */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
        {/* pedidos */}
        <CardView
          title="Pedidos"
          color="text-green-500"
          icon={<ReceiptRefundIcon />}
        >
          <CardViewValue
            size={'3'}
            value={
              (vendasTotal / 1000).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 1,
              }) + 'k'
            }
          />
        </CardView>

        {/* clientes */}
        <CardView title="Clientes" color="text-green-500" icon={<UsersIcon />}>
          <CardViewValue
            size={'3'}
            value={pedidosPorCliente.length.toLocaleString('pt-br', {
              style: 'decimal',
              maximumFractionDigits: 0,
            })}
          />
        </CardView>

        {/* produtos */}
        <CardView
          title="Produtos"
          color="text-green-500"
          icon={<BookOpenIcon />}
        >
          <CardViewValue
            size={'3'}
            value={pedidosPorProduto.length.toLocaleString('pt-br', {
              style: 'decimal',
              maximumFractionDigits: 0,
            })}
          />
        </CardView>

        {/* filtros */}
        <CardView title="Filtros" color="text-red-500" icon={<FilterIcon />}>
          <div className="space-y-1">
            <button
              onClick={() => {
                setModalFilter(true);
              }}
              className="mt-4 p-1 w-full rounded-md hover:bg-slate-200 text-slate-900"
            >
              {params.type === 'competencia' && (
                <div className="flex gap-1 justify-between">
                  <div className="flex gap-1 items-center">
                    <PencilAltIcon className="w-4 h-4" />
                    <span className="text-xs text-slate-500">Competência:</span>
                  </div>
                  <div className="flex gap-1">
                    <span className="text-xs text-slate-900">
                      {getMonthNameAbv(parseInt(params.mes ?? '0') - 1)}
                    </span>
                    <span className="text-xs text-slate-900">/</span>
                    <span className="text-xs text-slate-900">{params.ano}</span>
                  </div>
                </div>
              )}
              {params.type === 'periodo' && (
                <div className="flex justify-between gap-1">
                  <span className="text-xs text-slate-500">Período:</span>
                  <div className="flex gap-1">
                    <span className="text-xs text-slate-500">
                      {moment(params.dataI).format('DD/MM/YYYY')}
                    </span>
                    <span className="text-xs text-slate-500">-</span>
                    <span className="text-xs text-slate-500">
                      {moment(params.dataF).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              )}
            </button>
            <button
              onClick={() => {
                setOpenModalSelectCliente(true);
              }}
              className="flex gap-1 p-1 w-full rounded-md hover:bg-slate-200 text-slate-900"
            >
              <PencilAltIcon className="w-4 h-4" />
              <div className="w-full">
                {cliente ? (
                  <div className="flex justify-between w-full">
                    <span className="text-xs">Cliente:</span>
                    <span className="text-xs">
                      {truncateString(String(cliente?.nome ?? ''), 25)}
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-between w-full text-xs">
                    Selecionar cliente
                  </div>
                )}
              </div>
            </button>
          </div>
        </CardView>
      </div>

      {/* linha 1 */}
      <div className="grid grid-cols-[3fr_1fr_1fr] lg:grid-cols-[5fr_1fr_1fr] xl:grid-cols-[10fr_1fr_1fr] gap-1 lg:gap-4 my-6">
        {/* Vendas - Últimos 12 meses */}
        <div className="flex-1">
          <CardView title="">
            <Chart
              options={movimentacaoChart.options}
              series={movimentacaoChart.series}
              type="bar"
              width="99%"
              height={193}
            />
          </CardView>
        </div>
        {/* Pesquisas  */}
        <div className="w-[150px] lg:w-[180px] xl:w-[250px]">
          <CardView title="">
            {loading.includes('pie-pesquisas') ? (
              <div className="h-[13em] w-full">
                <LoadingComponent />
              </div>
            ) : (
              <div className="gap-2">
                <div className="flex justify-center">
                  <Chart
                    options={pesquisasChart.options}
                    series={pesquisasChart.series}
                    width={'99%'}
                    height={145}
                    type="donut"
                  />
                </div>
                <div className="grid grid-cols-3 px-1">
                  {pesquisasChart.data.map((item) => (
                    <div
                      style={{ color: item.color }}
                      className={clsx('flex flex-col gap-2 items-center')}
                    >
                      {item.icon}
                      <span className="text-[0.6em]">{item.name}</span>
                      <span className="font-bold text-[0.8em]">
                        {item.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardView>
        </div>
        {/* Produtos */}
        <div className="w-[150px] lg:w-[180px] xl:w-[250px]">
          <CardView title="">
            {loading.includes('pie-pesquisas') ? (
              <div className="h-[13em] w-full">
                <LoadingComponent />
              </div>
            ) : (
              <div className="gap-2">
                <div className="flex justify-center">
                  <Chart
                    options={cotacoesChart.options}
                    series={cotacoesChart.series}
                    width={'99%'}
                    height={145}
                    type="donut"
                  />
                </div>
                <div className="grid grid-cols-3 px-1">
                  {cotacoesChart.data.map((item) => (
                    <div
                      style={{ color: item.color }}
                      className={clsx('flex flex-col gap-2 items-center')}
                    >
                      {item.icon}
                      <span className="text-[0.6em]">{item.name}</span>
                      <span className="font-bold text-[0.8em]">
                        {item.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardView>
        </div>
      </div>

      {/* linha 2 */}
      <div className="grid grid-cols-1 xl:grid-cols-[1fr_1fr] gap-4 my-6">
        {/* clientes */}
        {loading.includes('grids-pedidos-clientes') ? (
          <div className="h-[20em] overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid">
            <LoadingComponent />
          </div>
        ) : (
          <div>
            {params.clienteId ? (
              <div>
                {laodingCliente ? (
                  <div className="h-72">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="space-y-2 h-[20rem] border border-slate-300 border-solid rounded-lg shadow-md overflow-hidden">
                    <h1 className="text-xl flex justify-between items-center px-2 py-2 bg-gray-200 text-primary1 rounded-t-lg">
                      <span>Cliente</span>
                      <button>
                        <XIcon
                          onClick={clearFilter}
                          className="w-6 h-6 p-[2px] bg-primary1 text-red-100 rounded-full hover:bg-red-700 hover:text-red-200"
                        />
                      </button>
                    </h1>
                    <div className="px-2">
                      <h1 className="text-2xl font-bold">{cliente?.nome}</h1>
                      <h3 className="text-sm text-slate-500 flex items-center justify-between">
                        <span>{cliente?.apelido}</span>
                        <span>
                          {' '}
                          {`${cliente?.tipoPessoa === 'J' ? 'CNPJ' : 'CPF'}: ${
                            cliente?.cpf_cnpj
                          }`}
                        </span>
                      </h3>
                      {cliente?.foneNumero && (
                        <div className="flex justify-between items-center text-xs">
                          <span className="">Telefone</span>
                          <span>{formatarTelefone(cliente.foneNumero)}</span>
                        </div>
                      )}
                    </div>
                    {/* <div className="flex justify-between items-center">
                  <span>Endereços</span>
                  <div>
                    {cliente?.enderecos?.map((endereco) => (
                      <span>{JSON.stringify(endereco)}</span>
                    ))}
                  </div>
                </div> */}
                  </div>
                )}
              </div>
            ) : (
              <InnerGridClienteRanking
                items={pedidosPorCliente}
                limit={6}
                showQtde={params?.produtoId ? true : false}
                onRowClick={clienteRowClick}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                filtered={!!params.clienteId}
                heightClass="h-[15rem] lg:min-h-full"
                clearFilter={clearFilter}
                verTudo={() => {
                  setVerMais('c');
                  // scroll to bottom
                  gotToBottom();
                }}
              />
            )}
          </div>
        )}

        {/* produtos */}
        {loading.includes('grids-pedidos-clientes') ? (
          <div className="h-[20em] overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid">
            <LoadingComponent />
          </div>
        ) : (
          <div>
            {params.produtoId ? (
              <div>
                {loadingProduto ? (
                  <div className="h-72">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="space-y-2 h-[20rem] border border-slate-300 border-solid rounded-lg shadow-md overflow-hidden">
                    <h1 className="text-xl flex justify-between items-center px-2 py-2 bg-gray-200 text-primary1 rounded-t-lg">
                      <span>Produto</span>
                      <button>
                        <XIcon
                          onClick={clearFilter}
                          className="w-6 h-6 p-[2px] bg-primary1 text-red-100 rounded-full hover:bg-red-700 hover:text-red-200"
                        />
                      </button>
                    </h1>
                    <div className="flex gap-2 px-2">
                      <div className="flex-shrink-0">
                        <img
                          className="h-32 w-32 rounded-md object-cover"
                          src={produto?.images?.[0].small ?? '/no-image.png'}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col justify-center w-full">
                        <h1 className="text-2xl font-bold w-full">
                          {produto?.descricaoSite}
                        </h1>
                        <span>
                          {produto?.isPromocao ? (
                            <div>Em promoção</div>
                          ) : (
                            <div>Sem Promoção</div>
                          )}
                        </span>
                        <hr className="my-1" />
                        <h3 className="text-sm text-slate-500 flex flex-col">
                          <div className="flex gap-2">
                            <span>Código SGI:</span>
                            <span className="font-bold">
                              {produto?.codigoSgi}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <span>Categoria:</span>
                            <span className="font-bold">
                              {produto?.categoria?.descricao}
                            </span>
                          </div>
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <InnerGridProdutoHanking
                items={pedidosPorProduto}
                limit={6}
                onRowClick={produtoRowClick}
                // filtered={!!params.clienteId ?? false}
                clearFilter={clearFilter}
                heightClass="h-[15rem] lg:min-h-full"
                verTudo={() => {
                  setVerMais('p');
                  // scroll to bottom
                  gotToBottom();
                }}
              />
            )}
          </div>
        )}
      </div>

      {/* linha 3 */}
      {verMais === 'c' && !params.clienteId && (
        <div ref={gridRef}>
          {/* clientes */}
          <InnerGridClienteRanking
            items={pedidosPorCliente}
            onRowClick={clienteRowClick}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            filtered={!!params.clienteId}
            clearFilter={clearFilter}
            close={() => setVerMais(undefined)}
            heightClass="h-[75vh]"
            paginated={true}
          />
        </div>
      )}
      {verMais === 'p' && (
        <div ref={gridRef}>
          {/* produtos */}
          <InnerGridProdutoHanking
            items={pedidosPorProduto}
            onRowClick={produtoRowClick}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            filtered={!!params.clienteId}
            clearFilter={clearFilter}
            close={() => setVerMais(undefined)}
            heightClass="h-[75vh]"
            paginated={true}
          />
        </div>
      )}

      {/* modal */}
      <ModalFilter
        params={params}
        open={modalFilter}
        closeModal={closeModal}
        onClear={() => {
          setParams((prev) => ({
            ...prev,
            type: 'competencia',
            mes: moment().format('MM'),
            ano: moment().format('YYYY'),
            dataI: undefined,
            dataF: undefined,
          }));
        }}
        onApply={(_params) => {
          if (_params.type === 'competencia') {
            setParams(
              (prev) =>
                ({
                  ...prev,
                  type: _params.type,
                  mes: _params.mes,
                  ano: _params.ano,
                  dataI: undefined,
                  dataF: undefined,
                } as IParams)
            );
          }
          if (_params.type === 'periodo') {
            setParams(
              (prev) =>
                ({
                  ...prev,
                  type: _params.type,
                  mes: undefined,
                  ano: undefined,
                  dataI: _params.dataI,
                  dataF: _params.dataF,
                } as IParams)
            );
          }
        }}
      />

      <ModalFindCliente
        isOpen={modalOpenSelectCliente}
        setIsOpen={setOpenModalSelectCliente}
        onSelect={(cliente) => {
          // setCliente(cliente);
          setParams({
            ...params,
            clienteId: cliente.id.toString(),
          });
        }}
        cliente={cliente}
        onClear={clearFilter}
      />
    </div>
  ) : (
    <div
      className="px-4 overflow-hidden overflow-y-auto h-[86vh]"
      ref={windowRef}
    >
      {/* linha 0 */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
        {/* clientes */}
        <CardView title="Cliente" color="text-green-500" icon={<UsersIcon />}>
          {cliente && (
            <div className="p-1">
              <div className="text-md">{cliente?.nome}</div>
              {cliente?.apelido && (
                <div className="text-xs font-bold">{cliente.apelido}</div>
              )}
              <div className="text-xs">
                {`${cliente?.tipoPessoa === 'J' ? 'CNPJ' : 'CPF'}: ${
                  cliente?.cpf_cnpj
                }`}
              </div>
            </div>
          )}
        </CardView>

        {/* pedidos */}
        <CardView
          title="Pedidos"
          color="text-green-500"
          icon={<ReceiptRefundIcon />}
        >
          <CardViewValue
            size={'3'}
            value={
              (vendasTotal / 1000).toLocaleString('pt-br', {
                style: 'decimal',
                maximumFractionDigits: 1,
              }) + 'k'
            }
          />
        </CardView>

        {/* produtos */}
        <CardView
          title="Produtos"
          color="text-green-500"
          icon={<BookOpenIcon />}
        >
          <CardViewValue
            size={'3'}
            value={pedidosPorProduto.length.toLocaleString('pt-br', {
              style: 'decimal',
              maximumFractionDigits: 0,
            })}
          />
        </CardView>

        {/* filtros */}
        <CardView title="Filtros" color="text-red-500" icon={<FilterIcon />}>
          <div className="space-y-1">
            <button
              onClick={() => {
                setModalFilter(true);
              }}
              className="mt-4 p-1 w-full rounded-md hover:bg-slate-200 text-slate-900"
            >
              {params.type === 'competencia' && (
                <div className="flex gap-1 justify-between">
                  <div className="flex gap-1 items-center">
                    <PencilAltIcon className="w-4 h-4" />
                    <span className="text-xs text-slate-500">Competência:</span>
                  </div>
                  <div className="flex gap-1">
                    <span className="text-xs text-slate-900">
                      {getMonthNameAbv(parseInt(params.mes ?? '0') - 1)}
                    </span>
                    <span className="text-xs text-slate-900">/</span>
                    <span className="text-xs text-slate-900">{params.ano}</span>
                  </div>
                </div>
              )}
              {params.type === 'periodo' && (
                <div className="flex justify-between gap-1">
                  <span className="text-xs text-slate-500">Período:</span>
                  <div className="flex gap-1">
                    <span className="text-xs text-slate-500">
                      {moment(params.dataI).format('DD/MM/YYYY')}
                    </span>
                    <span className="text-xs text-slate-500">-</span>
                    <span className="text-xs text-slate-500">
                      {moment(params.dataF).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              )}
            </button>
          </div>
        </CardView>
      </div>

      {/* linha 1 */}
      <div className="grid grid-cols-[3fr_1fr_1fr] lg:grid-cols-[5fr_1fr_1fr] xl:grid-cols-[10fr_1fr_1fr] gap-1 lg:gap-4 my-6">
        {/* Vendas - Últimos 12 meses */}
        <div className="flex-1 w-full">
          <CardView title="">
            <Chart
              options={movimentacaoChart.options}
              series={movimentacaoChart.series}
              type="bar"
              width="99%"
              height={193}
            />
          </CardView>
        </div>
        {/* Pesquisas  */}
        <div className="w-[150px] lg:w-[180px] xl:w-[250px]">
          <CardView title="">
            {loading.includes('pie-pesquisas') ? (
              <div className="h-[13em] w-full">
                <LoadingComponent />
              </div>
            ) : (
              <div className="gap-2">
                <div className="flex justify-center">
                  <Chart
                    options={pesquisasChart.options}
                    series={pesquisasChart.series}
                    width={'99%'}
                    height={145}
                    type="donut"
                  />
                </div>
                <div className="grid grid-cols-3 px-1">
                  {pesquisasChart.data.map((item) => (
                    <div
                      style={{ color: item.color }}
                      className={clsx('flex flex-col gap-2 items-center')}
                    >
                      {item.icon}
                      <span className="text-[0.6em]">{item.name}</span>
                      <span className="font-bold text-[0.8em]">
                        {item.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardView>
        </div>
        {/* Produtos */}
        <div className="w-[160px] lg:w-[180px] xl:w-[250px]">
          <CardView title="">
            {loading.includes('pie-pesquisas') ? (
              <div className="h-[13em] w-full">
                <LoadingComponent />
              </div>
            ) : (
              <div className="gap-2">
                <div className="flex justify-center">
                  <Chart
                    options={cotacoesChart.options}
                    series={cotacoesChart.series}
                    width={'99%'}
                    height={145}
                    type="donut"
                  />
                </div>
                <div className="grid grid-cols-3 px-1">
                  {cotacoesChart.data.map((item) => (
                    <div
                      style={{ color: item.color }}
                      className={clsx('flex flex-col gap-2 items-center')}
                    >
                      {item.icon}
                      <span className="text-[0.6em]">{item.name}</span>
                      <span className="font-bold text-[0.8em]">
                        {item.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </CardView>
        </div>
      </div>

      {/* linha 2 */}
      <div className="grid my-6">
        {/* meus carrinhos e meus pedidos */}
        {loading.includes('grids-pedidos-clientes') ? (
          <div className="h-[20em] overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid">
            <LoadingComponent />
          </div>
        ) : (
          <div>
            <Tab.Group onChange={() => setVerMais(undefined)}>
              <Tab.List className="flex space-x-1 rounded-xl bg-gray-100 p-1">
                <Tab className={({ selected }) => tabClass(selected)}>
                  <div className="flex gap-1 items-center px-4">
                    <span className="flex items-center justify-center rounded-full p-2 bg-white border border-primary1 border-solid">
                      <ShoppingCartIcon className={`w-6 h-6 text-primary1 `} />
                    </span>
                    <span>Meus Carrinhos</span>
                    <span className="flex-1" />
                    <span className="text-xs text-slate-100">
                      {cartsFiltereds.length}
                    </span>
                  </div>
                </Tab>
                <Tab className={({ selected }) => tabClass(selected)}>
                  <div className="flex gap-1 items-center px-4">
                    <span className="flex items-center justify-center rounded-full p-2 bg-white border border-primary1 border-solid">
                      <ClipboardListIcon className={`w-6 h-6 text-primary1 `} />
                    </span>
                    <span>Meus Pedidos</span>
                    <span className="flex-1" />
                    <span className="text-xs text-slate-100">
                      {ordersFiltereds?.length}
                    </span>
                  </div>
                </Tab>
                <Tab className={({ selected }) => tabClass(selected)}>
                  <div className="flex gap-1 items-center px-4">
                    <span className="flex items-center justify-center rounded-full p-2 bg-white border border-primary1 border-solid">
                      <ChartBarIcon className={`w-6 h-6 text-primary1 `} />
                    </span>
                    <span>Hanking Produtos</span>
                    <span className="flex-1" />
                    <span className="text-xs text-slate-100">
                      {pedidosPorProduto.length}
                    </span>
                  </div>
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className={() => tabPanelClass()}>
                  <InnerGridMyCarts
                    carts={cartsFiltereds}
                    limit={6}
                    afterAction={() => null}
                    verTudo={() => {
                      setVerMais('car');
                      // scroll to bottom
                      gotToBottom();
                    }}
                  />
                </Tab.Panel>
                <Tab.Panel className={() => tabPanelClass()}>
                  <InnerGridMyOrders
                    pedidos={ordersFiltereds}
                    limit={6}
                    verTudo={() => {
                      setVerMais('ped');
                      // scroll to bottom
                      gotToBottom();
                    }}
                    rowClickAction={(pedido: Pedido) => {
                      openPanel(pedido.id);
                    }}
                    rowClickWhatsapp={(pedido: Pedido) => {
                      openModalWhatsapp(pedido.id);
                    }}
                  />
                </Tab.Panel>
                <Tab.Panel className={() => tabPanelClass()}>
                  <InnerGridProdutoHanking
                    items={pedidosPorProduto}
                    limit={6}
                    // onRowClick={produtoRowClickNaoManager}
                    filtered={!!params.clienteId}
                    clearFilter={clearFilter}
                    verTudo={() => {
                      setVerMais('p');
                      // scroll to bottom
                      gotToBottom();
                    }}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}

        {/* produtos */}
        {/* {loading.includes('grids-pedidos-clientes') ? (
          <div className="h-[20em] overflow-hidden rounded-lg bg-white shadow-md border border-slate-200 border-solid">
            <LoadingComponent />
          </div>
        ) : (
          <div>
            {params.produtoId ? (
              <div>
                {loadingProduto ? (
                  <div className="h-72">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="space-y-2">
                    <h1 className="text-xl flex justify-between items-center px-2 py-2 bg-gray-200 text-primary1 rounded-lg">
                      <span>Produto</span>
                      <button>
                        <XIcon
                          onClick={clearFilter}
                          className="w-6 h-6 p-[2px] bg-primary1 text-red-100 rounded-full hover:bg-red-700 hover:text-red-200"
                        />
                      </button>
                    </h1>
                    <div className="flex gap-2">
                      <div className="flex-shrink-0">
                        <img
                          className="h-20 w-20 rounded-md object-cover"
                          src={produto?.images?.[0].small ?? '/no-image.png'}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col justify-center">
                        <h1 className="text-2xl font-bold">
                          {produto?.descricaoSite}
                        </h1>
                        <span>
                          {produto?.isPromocao ? (
                            <div>Em promoção</div>
                          ) : (
                            <div>Sem Promoção</div>
                          )}
                        </span>
                        <hr className="my-1" />
                        <h3 className="text-sm text-slate-500 flex flex-col">
                          <div className="flex gap-2">
                            <span>Código SGI:</span>
                            <span className="font-bold">
                              {produto?.codigoSgi}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <span>Categoria:</span>
                            <span className="font-bold">
                              {produto?.categoria?.descricao}
                            </span>
                          </div>
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <InnerGridProdutoHanking
                items={pedidosPorProduto}
                limit={6}
                heightClass="h-[13rem] lg:min-h-full"
                // onRowClick={produtoRowClick}
                // filtered={!!params.clienteId ?? false}
                clearFilter={clearFilter}
                verTudo={() => {
                  setVerMais('p');
                  // scroll to bottom
                  gotToBottom();
                }}
              />
            )}
          </div>
        )} */}
      </div>

      {/* linha 3 */}
      {verMais === 'p' && (
        <div ref={gridRef}>
          {/* produtos */}
          <InnerGridProdutoHanking
            items={pedidosPorProduto}
            onRowClick={produtoRowClick}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            filtered={!!params.clienteId}
            clearFilter={clearFilter}
            close={() => setVerMais(undefined)}
            heightClass="h-[72vh]"
            paginated={true}
          />
        </div>
      )}
      {verMais === 'car' && (
        <div
          className={clsx(
            'bg-white rounded-md shadow-lg',
            'border border-gray-300 border-solid',
            'overflow-hidden'
          )}
          ref={gridRef}
        >
          <InnerGridMyCarts
            carts={carts}
            afterAction={() => null}
            close={() => setVerMais(undefined)}
            expandido
          />
        </div>
      )}

      {verMais === 'ped' && (
        <div
          className={clsx(
            'bg-white rounded-md shadow-lg',
            'border border-gray-300 border-solid',
            'overflow-hidden'
          )}
          ref={gridRef}
        >
          <InnerGridMyOrders
            pedidos={orders}
            close={() => setVerMais(undefined)}
            expandido
          />
        </div>
      )}

      {/* modal */}
      <ModalFilter
        params={params}
        open={modalFilter}
        closeModal={closeModal}
        onClear={() => {
          setParams((prev) => ({
            ...prev,
            type: 'competencia',
            mes: moment().format('MM'),
            ano: moment().format('YYYY'),
            dataI: undefined,
            dataF: undefined,
          }));
        }}
        onApply={(_params) => {
          if (_params.type === 'competencia') {
            setParams(
              (prev) =>
                ({
                  ...prev,
                  type: _params.type,
                  mes: _params.mes,
                  ano: _params.ano,
                  dataI: undefined,
                  dataF: undefined,
                } as IParams)
            );
          }
          if (_params.type === 'periodo') {
            setParams(
              (prev) =>
                ({
                  ...prev,
                  type: _params.type,
                  mes: undefined,
                  ano: undefined,
                  dataI: _params.dataI,
                  dataF: _params.dataF,
                } as IParams)
            );
          }
        }}
      />
      <ModalConfirmationNumberWhatsapp
        isOpen={modalIsOpenWhatsapp}
        setIsOpen={setModalIsOpenWhatsapp}
        action={(number) =>
          openPanelData.current?.id &&
          handleWhatsapp(openPanelData.current?.id, number)
        }
        currentUser={currentUser}
      />
    </div>
  );
}
